import { useState, useCallback, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { memo } from 'react';
import { BrowserRouter as Router, Link, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { Paper, RadioGroup, Radio, FormControlLabel, Table, TableHead, TableContainer, TableBody, TableCell, TableRow, Typography, Grid, Button, Checkbox, FormLabel } from '@mui/material';
import { Autocomplete, SelectField, TextField, MultilineField } from '../../style-guide';
import { sendRequest, REQUEST_ACTIONS } from '../../utils/Communicator';
import { useDispatch, useSelector } from 'react-redux';
import { snackbarToggle } from '../../reducers/snackbarSlicer';
import { Dialog, Snackbar } from '../../style-guide';
import { LoadingSpinner } from '../LoadingSpinner';
import air_imp_2 from './air_imp_2.svg'
import air_imp from './air_imp.svg'
import air_imp_7 from './air_imp_7.svg'
import air_imp_8 from './air_imp_8.svg'
import air_imp_5 from './air_imp_5.svg'
import air_imp_6 from './air_imp_6.svg'
import { FaAngleLeft, FaCartPlus, FaInfoCircle } from "react-icons/fa";
import imgShow from './show.png'
import { fclCostFrieghtChargesExport, currencies, URL, LANGUAGES, parcelSortBy, parcelStatusList, paginationArr, listOfCostWeight } from '../../constants/global-constants';
import { addDesc, getUserInfoByLockerId, getDiscriptionsList, createShippingRequest, getParcelsList } from '../Admins/AdminHandler'
import { ShipmentDetails } from '../Shipment/ShipmentDetails';
import Charges from '../Charges/Charges';
import MailTemplate from './MailTemplate';
import AirQuote from './AirQuote';
import LclQuote from './LclQuote';
import FclQuote from './FclQuote';
import AddAirCost from '../CostList/Air/AddAirCost';
import AddLclCost from '../CostList/Lcl/AddLclCost';
import AddFclCost from '../CostList/Fcl/AddFclCost';
import AirLclQuoteHeader from './AirLclQuoteHeader';
import { updateCrm, getCRMListByIds, getCrmById_Updated, getCRMList1, sendMail, getCRMsForMail, getProductChargesByProdShipCharge, getLocalChargesByShipProd, addQs, updateQS, getCRMList, getAirExportAFs, getFclExportImportAFs, getLclExportImportAFs, getKgFactorsforSave } from '../../reducers/requestHandler';
import { emptyProductForeignChargesItemNew, setProductForeignChargesItemNew } from '../../reducers/parameter/parameterSlice';
import { setCurrentPage } from '../../reducers/session/sessionSlice';
const AddQS = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { adminList } = useSelector(state => state.admin);
    const { crmList } = useSelector(state => state.crm);
    const { productForeignChargesItemNew, listOfFclExportImportAFs, listOfProductCategories, listOfExchangeRates, listOfShipmentTypes, listOfMeasurementUnits, listOfcurrency, listOfKgFactor, listOfAirLine, listOfLclExportImportAFs, listOfAirExportAFs, countryList, qsStatusList, listOfServiceType, listOfAirPort, listOfPort, listOfIncoterms, listOfFLCTypes40, listOfFLCTypes20, listOfFclImportExport } = useSelector(state => state.parameter);
    const { admin, currentPage } = useSelector(state => state.session);
    const { snackbar } = useSelector(state => state.snackbar);
    var listOfUpdatedCrm = [];
    const [listOfFclQuotations, setListOfFclQuotations] = useState([]);
    const [listOfQuoatationCost, setListOfQuoatationCost] = useState([]);
    const [selectedForMail, setSelectedForMail] = useState([]);
    const [listOfLclQuoatationCost, setListOfLclQuoatationCost] = useState([]);
    const [listOfKgFactor1, setListOfKgFactor1] = useState([]);
    const [userData, setUserData] = useState(null);
    const [bLoadingForeign, setbLoadingForeign] = useState(false);
    const [bLoadingCustom, setbLoadingCustom] = useState(false);
    const [bLoadingFrieght, setbLoadingFrieght] = useState(false);
    const [bLoadingLocal, setbLoadingLocal] = useState(false || props?.data?.id);
    const [bNextPage, setNextPage] = useState(false);
    const [selectAllLclAgentMail, setSelectAllLclAgentMail] = useState(false);
    const [newEmptyProductError, setNewEmptyProductError] = useState(false);
    const [newEmptyProductMsg, setNewEmptyProductMsg] = useState("");
    const [selectAllMail, setAelectAllMail] = useState(false);
    const [refreshView, setRefreshView] = useState(0);
    const [lcIndex, setLcIndex] = useState(0);
    const [ccIndex, setCcIndex] = useState(0);
    const [fcIndex, setFcIndex] = useState(0);
    const [frcIndex, setFrcIndex] = useState(0);
    const [saveUserChanges, setSaveUserChanges] = useState(false);
    const onSnackbarHandleClose = () => dispatch(snackbarToggle(false));
    const [openOrderModal, setOpenOrderModal] = useState(props.bOpen);
    const [crmsForMail, setcrmsForMail] = useState([]);
    const isMailSelected = () => {
        var tMails = [];
        if (value.shipmentType != 3) {
            var tMails = JSON.parse(JSON.stringify(listOfQuoatationCost));
        } else {
            tMails = JSON.parse(JSON.stringify(listOfFclQuotations));
        }


        for (var i = 0; i < tMails.length; i++) {
            if (tMails[i]?.selected) {
                return true
            }
        }
        return isAgentMailSelected();
    }
    const onChangeRate40 = (data) => {
        setValue(prev => ({ ...prev, flc40: data }));
        recalclulateOriginalValues2040(value.flc20, data);
        /*window.setTimeout(function () {
            recalclulateOriginalValues();
        }, 100);*/
    }
    const onChangeRate20 = (data) => {
        setValue(prev => ({ ...prev, flc20: data }));
        recalclulateOriginalValues2040(data, value.flc40);
        /*window.setTimeout(function () {
            recalclulateOriginalValues();
        }, 100);*/
    }
    const isAgentMailSelected = () => {
        var tMails = JSON.parse(JSON.stringify(listOfLclQuoatationCost));
        for (var i = 0; i < tMails.length; i++) {
            if (tMails[i]?.selected) {
                return true
            }
        }
        return false;
    }
    const addRemoveSelectedMail = (quoate, isSelected) => {
        var tMails = [];
        if (value.shipmentType == 3) {

            tMails = JSON.parse(JSON.stringify(listOfFclQuotations));
        } else {
            tMails = JSON.parse(JSON.stringify(listOfQuoatationCost));
        }
        for (var i = 0; i < tMails.length; i++) {
            if (tMails[i]?.id == quoate.id) {
                tMails[i].selected = isSelected;
            }
        }
        if (value.shipmentType == 3) {
            setListOfFclQuotations(tMails)
        } else {
            setListOfQuoatationCost(tMails);
        }
    }
    const addRemoveSelectedLclAgentMail = (quoate, isSelected) => {
        var tMails = JSON.parse(JSON.stringify(listOfLclQuoatationCost));
        for (var i = 0; i < tMails.length; i++) {
            if (tMails[i]?.id == quoate.id) {
                tMails[i].selected = isSelected;
            }
        }
        setListOfLclQuoatationCost(tMails);
    }
    const addRemoveSelectedMailAll = (quoate, isSelected) => {
        setAelectAllMail(isSelected)
        var tMails = JSON.parse(JSON.stringify(listOfQuoatationCost));
        for (var i = 0; i < tMails?.length; i++) {
            tMails[i].selected = isSelected;
        }
        setListOfQuoatationCost(tMails);
       
        addRemoveSelectedMailLclAgentAll(props.detail, isSelected);
    }
    const addRemoveSelectedMailLclAgentAll = (quoate, isSelected) => {
        setSelectAllLclAgentMail(isSelected)
        var tMails = JSON.parse(JSON.stringify(listOfLclQuoatationCost));
        for (var i = 0; i < tMails?.length; i++) {
            tMails[i].selected = isSelected;
        }
        setListOfLclQuoatationCost(tMails);
       
    }
    const removeSelectedMail = (quoate) => {
        var tMails = [...selectedForMail];
        tMails.push(quoate);
        setSelectedForMail(tMails)
    }
    const onHandleOpenModal = () => {
        setOpenOrderModal(true);
    };
    const onHandleCancelModal = () => setOpenOrderModal(false);


    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);
    const [originalPrice, setoriginalPrice] = useState(props.data?.params?.originalPrice ? props.data?.params?.originalPrice : 0);
    const [value, setValue] = useState(
        props.data
            ? {
                id: props.data.id,
                agentText: props.data.params.agentText ? props.data.params.agentText:"/",
                quoteType: props.data.quoteType,
                productType: props.data.productType,
                shipmentType: props.data.shipmentType,
                cutomerCrmId: props.data.cutomerCrmId,
                countryId: props.data.countryId,
                from: props.data.from,
                to: props.data.to,
                incoId: props.data.incoId,
                status: props.data.status,
                userId: props.data.userId,
                serviceType: props.data.serviceType,
                flc20: props.data.flc20,
                flc40: props.data.flc40,
                flcType20: props.data.flcType20,
                flcType40: props.data.flctype40,
                contactPerson: props.data.contactPerson,
                pickupLocation: props.data.pickupLocation,
                remarks: props.data.remarks,
                zip: props.data.zip,
                finalDestination: props.data.finalDestination,
                finalDest: props.data.params?.finalDest,
                finalCountryId: props.data.params?.finalCountry?.id,
                statusDesc: props.data.statusDesc,
                total20: props.data.total20,
                total40: props.data.total40,
                shipmentDetails: props.data.shipmentDetails,
                manualWeight: props.data.params?.manualWeight,
                manualVolume: props.data.params?.manualVolume,
                customChargesItem: props.data.customChargesItem,
                foreignChargesItem: props.data.foreignChargesItem,
                freightChargesItem: props.data.freightChargesItem,
                localChargesItem: props.data.localChargesItem,
                params: props.data.params,
                cdi: props.data.params?.countryDest?.id,
                cdi1: props.data.params?.countryAirDest?.id,
                countryAirZip: props.data.params?.countryAirZip,
                countryAirZipId: props.data.params?.countryAirZipId,
            }
            : {
                id: null,
                agentText: null,
                quoteType: null,
                productType: null,
                shipmentType: null,
                cutomerCrmId: null,
                countryId: null,
                from: null,
                to: null,
                incoId: null,
                status: null,
                userId: null,
                serviceType: null,
                flc20: null,
                flc40: null,
                flcType20: null,
                flctype40: null,
                contactPerson: null,
                finalDest: null,
                pickupLocation: null,
                finalDestination: null,
                finalCountryId: null,
                remarks: null,
                zip: null,
                statusDesc: null,
                total20: null,
                total40: null,
                shipmentDetails: null,
                manualWeight: null,
                foreignChargesItem: null,
                freightChargesItem: null,
                localChargesItem: null,
                customChargesItem: null,
                message: null,
                params: {
                    finalDest: "",
                    finalCountry: "",
                    countryDest: "",
                },
            },
    );

    const recalclulateOriginalValues2040 = (fcl20, fcl40) => {
        var orgValue = 0;
        var orgValue20 = 0;
        var orgValue40 = 0;
        var orgCustomValue = 0;
        var orgCustomValue20 = 0;
        var orgCustomValue40 = 0;
        var orgLocalValue = 0;
        var orgLocalValue20 = 0;
        var orgLocalValue40 = 0;
        var orgForeignValue = 0;
        var orgForeignValue20 = 0;
        var orgForeignValue40 = 0;
        var orgFreightValue = 0;
        var orgFreightValue20 = 0;
        var orgFreightValue40 = 0;
        var curValue = 0;
        var curValue20 = 0;
        var curValue40 = 0;
        var curCustomValue = 0;
        var curCustomValue20 = 0;
        var curCustomValue40 = 0;
        var curLocalValue = 0;
        var curLocalValue20 = 0;
        var curLocalValue40 = 0;
        var curForeignValue = 0;
        var curForeignValue20 = 0;
        var curForeignValue40 = 0;
        var curFreightValue = 0;
        var curFreightValue20 = 0;
        var curFreightValue40 = 0;
        var index = 0;
        for (var i = 0; i < productForeignChargesItem.length; i++) {
            //var obj = removeCircularKey(productForeignChargesItem[i]);
            var obj = productForeignChargesItem[i];
            if (!obj.index)
                obj.index = index;
            index++;
            if (!obj.selectedUnit || !obj.selectedCurrency)
                continue;
            if (obj.selectedItem.name == "Select")
                continue;
            if (obj.units && obj.units.length) {
                if (!obj.selectedUnit || !obj.selectedUnit.id) {
                    obj.selectedUnit = obj.units[0]
                }
            }
            if (obj.currencies && obj.currencies.length) {
                if (!obj.selectedCurrency || !obj.selectedCurrency.id) {
                    obj.selectedCurrency = obj.currencies[0]
                }
            }
            var exch = listOfExchangeRates.find(u => u.id === obj.selectedCurrency.id)
            if (fcl20) {
                curForeignValue20 += getCalculations2040(Number(obj.rate20 ? obj.rate20 : obj.selectedItem.rate20),
                    Number(obj.min),
                    obj.selectedUnit.id, true, fcl20) / exch.oneUsd;
                orgForeignValue20 += getCalculations2040(Number(obj.orgValue20 ?
                    obj.orgValue20 : obj.rate20),
                    Number(0),
                    obj.selectedUnit.id, true, fcl20) / exch.oneUsd;
            }
            if (fcl40) {
                curForeignValue40 += getCalculations2040(Number(obj.rate40 ? obj.rate40 : obj.selectedItem.rate40),
                    Number(obj.min),
                    obj.selectedUnit.id, false, fcl40) / exch.oneUsd;
                orgForeignValue40 += getCalculations2040(Number(obj.orgValue40 ?
                    obj.orgValue40 : obj.rate40),
                    Number(0),
                    obj.selectedUnit.id, false, fcl40) / exch.oneUsd;
            }
            if (!fcl40 && !fcl20) {
                curForeignValue += getCalculations(Number(obj.rate ? obj.rate : obj.selectedItem.rate),
                    Number(obj.min),
                    obj.selectedUnit.id) / exch.oneUsd;
                orgForeignValue += getCalculations(Number(obj.orgValue ?
                    obj.orgValue : obj.rate),
                    Number(0),
                    obj.selectedUnit.id) / exch.oneUsd;
            }
        }
        index = 0;
        for (var i = 0; i < productFreightChargesItem1.length; i++) {
            //var obj = removeCircularKey(productFreightChargesItem1[i]);
            var obj = productFreightChargesItem1[i];
            if (!obj.index)
                obj.index = index;
            index++;
            if (!obj.selectedUnit || !obj.selectedCurrency)
                continue;
            if (obj.selectedItem.name == "Select")
                continue;
            if (obj.units && obj.units.length) {
                if (!obj.selectedUnit || !obj.selectedUnit.id) {
                    obj.selectedUnit = obj.units[0]
                }
            }
            if (obj.currencies && obj.currencies.length) {
                if (!obj.selectedCurrency || !obj.selectedCurrency.id) {
                    obj.selectedCurrency = obj.currencies[0]
                }
            }
            var exch = listOfExchangeRates.find(u => u.id === obj.selectedCurrency.id)
            if (fcl20) {
                curFreightValue20 += getCalculations2040(Number(obj.rate20 ? obj.rate20 : obj.selectedItem.rate20),
                    Number(obj.min),
                    obj.selectedUnit.id, true, fcl20) / exch.oneUsd;
                orgFreightValue20 += getCalculations2040(Number(obj.orgValue20 ?
                    obj.orgValue20 : obj.rate20),
                    Number(0),
                    obj.selectedUnit.id, true, fcl20) / exch.oneUsd;
            }
            if (fcl40) {
                curFreightValue40 += getCalculations2040(Number(obj.rate40 ? obj.rate40 : obj.selectedItem.rate40),
                    Number(obj.min),
                    obj.selectedUnit.id, false, fcl40) / exch.oneUsd;
                orgFreightValue40 += getCalculations2040(Number(obj.orgValue40 ?
                    obj.orgValue40 : obj.rate40),
                    Number(0),
                    obj.selectedUnit.id, false, fcl40) / exch.oneUsd;
            }
            if (!fcl40 && !fcl20) {
                curFreightValue += getCalculations(Number(obj.rate ? obj.rate : obj.selectedItem.rate),
                    Number(obj.min),
                    obj.selectedUnit.id) / exch.oneUsd;
                orgFreightValue += getCalculations(Number(obj.orgValue ?
                    obj.orgValue : obj.rate),
                    Number(0),
                    obj.selectedUnit.id) / exch.oneUsd;
            }
        }
        index = 0;
        for (var i = 0; i < productLocalChargesItem1.length; i++) {
            //var obj = removeCircularKey(productLocalChargesItem1[i]);
            var obj = productLocalChargesItem1[i];
            if (!obj.index)
                obj.index = index;
            index++;
            if (!obj.selectedUnit || !obj.selectedCurrency)
                continue;
            if (obj.selectedItem.name == "Select")
                continue;
            if (obj.units && obj.units.length) {
                if (!obj.selectedUnit || !obj.selectedUnit.id) {
                    obj.selectedUnit = obj.units[0]
                }
            }
            if (obj.currencies && obj.currencies.length) {
                if (!obj.selectedCurrency || !obj.selectedCurrency.id) {
                    obj.selectedCurrency = obj.currencies[0]
                }
            }
            var exch = listOfExchangeRates.find(u => u.id === obj.selectedCurrency.id)
            if (fcl20) {
                curLocalValue20 += getCalculations2040(Number(obj.rate20 ? obj.rate20 : obj.selectedItem.rate20),
                    Number(obj.min),
                    obj.selectedUnit.id, true, fcl20) / exch.oneUsd;
                orgLocalValue20 += getCalculations2040(Number(obj.orgValue20 ?
                    obj.orgValue20 : obj.rate20),
                    Number(0),
                    obj.selectedUnit.id, true, fcl20) / exch.oneUsd;
            }
            if (fcl40) {
                curLocalValue40 += getCalculations2040(Number(obj.rate40 ? obj.rate40 : obj.selectedItem.rate40),
                    Number(obj.min),
                    obj.selectedUnit.id, false, fcl40) / exch.oneUsd;
                orgLocalValue40 += getCalculations2040(Number(obj.orgValue40 ?
                    obj.orgValue40 : obj.rate40),
                    Number(0),
                    obj.selectedUnit.id, false, fcl40) / exch.oneUsd;
            }
            if (!fcl40 && !fcl20) {
                curLocalValue += getCalculations(Number(obj.rate ? obj.rate : obj.selectedItem.rate),
                    Number(obj.min),
                    obj.selectedUnit.id) / exch.oneUsd;
                orgLocalValue += getCalculations(Number(obj.orgValue ?
                    obj.orgValue : obj.rate),
                    Number(0),
                    obj.selectedUnit.id) / exch.oneUsd;
            }
        }
        index = 0;
        for (var i = 0; i < productCustomChargesItem1.length; i++) {
            //var obj = removeCircularKey(productCustomChargesItem1[i]);
            var obj = productCustomChargesItem1[i];
            if (!obj.index)
                obj.index = index;
            index++;
            if (!obj.selectedUnit || !obj.selectedCurrency)
                continue;
            if (obj.selectedItem.name == "Select")
                continue;
            if (obj.units && obj.units.length) {
                if (!obj.selectedUnit || !obj.selectedUnit.id) {
                    obj.selectedUnit = obj.units[0]
                }
            }
            if (obj.currencies && obj.currencies.length) {
                if (!obj.selectedCurrency || !obj.selectedCurrency.id) {
                    obj.selectedCurrency = obj.currencies[0]
                }
            }
            var exch = listOfExchangeRates.find(u => u.id === obj.selectedCurrency.id)
            if (fcl20) {
                curCustomValue20 += getCalculations2040(Number(obj.rate20 ? obj.rate20 : obj.selectedItem.rate20),
                    Number(obj.min),
                    obj.selectedUnit.id, true, fcl20) / exch.oneUsd;
                orgCustomValue20 += getCalculations2040(Number(obj.orgValue20 ?
                    obj.orgValue20 : obj.rate20),
                    Number(0),
                    obj.selectedUnit.id, true, fcl20) / exch.oneUsd;
            }
            if (fcl40) {
                curCustomValue40 += getCalculations2040(Number(obj.rate40 ? obj.rate40 : obj.selectedItem.rate40),
                    Number(obj.min),
                    obj.selectedUnit.id, false, fcl40) / exch.oneUsd;
                orgCustomValue40 += getCalculations2040(Number(obj.orgValue40 ?
                    obj.orgValue40 : obj.rate40),
                    Number(0),
                    obj.selectedUnit.id, false, fcl40) / exch.oneUsd;
            }
            if (!fcl40 && !fcl20) {
                curCustomValue += getCalculations(Number(obj.rate ? obj.rate : obj.selectedItem.rate),
                    Number(obj.min),
                    obj.selectedUnit.id) / exch.oneUsd;
                orgCustomValue += getCalculations(Number(obj.orgValue ?
                    obj.orgValue : obj.rate),
                    Number(0),
                    obj.selectedUnit.id) / exch.oneUsd;
            }
        }
        var params1 = { ...value.params }
        params1.orgForeignValue = Number(orgForeignValue).toFixed(2);
        params1.orgFreightValue = Number(orgFreightValue).toFixed(2);
        params1.orgCustomValue = Number(orgCustomValue).toFixed(2);
        params1.orgLocalValue = Number(orgLocalValue).toFixed(2);
        //params1.originalPrice = Number(orgForeignValue + orgFreightValue + orgCustomValue + orgLocalValue).toFixed(2);
        params1.originalPrice = Number(orgForeignValue + orgFreightValue + orgLocalValue).toFixed(2);
        params1.orgForeignValue20 = Number(orgForeignValue20).toFixed(2);
        params1.orgFreightValue20 = Number(orgFreightValue20).toFixed(2);
        params1.orgCustomValue20 = Number(orgCustomValue20).toFixed(2);
        params1.orgLocalValue20 = Number(orgLocalValue20).toFixed(2);
        //params1.originalPrice20 = Number(orgForeignValue20 + orgFreightValue20 + orgCustomValue20 + orgLocalValue20).toFixed(2);
        params1.originalPrice20 = Number(orgForeignValue20 + orgFreightValue20 + orgLocalValue20).toFixed(2);
        params1.orgForeignValue40 = Number(orgForeignValue40).toFixed(2);
        params1.orgFreightValue40 = Number(orgFreightValue40).toFixed(2);
        params1.orgCustomValue40 = Number(orgCustomValue40).toFixed(2);
        params1.orgLocalValue40 = Number(orgLocalValue40).toFixed(2);
        //params1.originalPrice40 = Number(orgForeignValue40 + orgFreightValue40 + orgCustomValue40 + orgLocalValue40).toFixed(2);
        params1.originalPrice40 = Number(orgForeignValue40 + orgFreightValue40 + orgLocalValue40).toFixed(2);

        params1.curForeignValue = Number(curForeignValue).toFixed(2);
        params1.curFreightValue = Number(curFreightValue).toFixed(2);
        params1.curCustomValue = Number(curCustomValue).toFixed(2);
        params1.curLocalValue = Number(curLocalValue).toFixed(2);
        //params1.curPrice = Number(curForeignValue + curFreightValue + curCustomValue + curLocalValue).toFixed(2);
        params1.curPrice = Number(curForeignValue + curFreightValue + curLocalValue).toFixed(2);
        params1.curForeignValue20 = Number(curForeignValue20).toFixed(2);
        params1.curFreightValue20 = Number(curFreightValue20).toFixed(2);
        params1.curCustomValue20 = Number(curCustomValue20).toFixed(2);
        params1.curLocalValue20 = Number(curLocalValue20).toFixed(2);
        //params1.curPrice20 = Number(curForeignValue20 + curFreightValue20 + curCustomValue20 + curLocalValue20).toFixed(2);
        params1.curPrice20 = Number(curForeignValue20 + curFreightValue20 + curLocalValue20).toFixed(2);
        params1.curForeignValue40 = Number(curForeignValue40).toFixed(2);
        params1.curFreightValue40 = Number(curFreightValue40).toFixed(2);
        params1.curCustomValue40 = Number(curCustomValue40).toFixed(2);
        params1.curLocalValue40 = Number(curLocalValue40).toFixed(2);
        //params1.curPrice40 = Number(curForeignValue40 + curFreightValue40 + curCustomValue40 + curLocalValue40).toFixed(2);
        params1.curPrice40 = Number(curForeignValue40 + curFreightValue40 + curLocalValue40).toFixed(2);

        params1.margin = Number(params1.curPrice - params1.originalPrice).toFixed(2);
        params1.margin20 = Number(params1.curPrice20 - params1.originalPrice20).toFixed(2);
        params1.margin40 = Number(params1.curPrice40 - params1.originalPrice40).toFixed(2);

        setValue(prev => ({ ...prev, params: params1 }))
    };


    const recalclulateOriginalValues = () => {
        var orgValue = 0;
        var orgValue20 = 0;
        var orgValue40 = 0;
        var orgCustomValue = 0;
        var orgCustomValue20 = 0;
        var orgCustomValue40 = 0;
        var orgLocalValue = 0;
        var orgLocalValue20 = 0;
        var orgLocalValue40 = 0;
        var orgForeignValue = 0;
        var orgForeignValue20 = 0;
        var orgForeignValue40 = 0;
        var orgFreightValue = 0;
        var orgFreightValue20 = 0;
        var orgFreightValue40 = 0;
        var curValue = 0;
        var curValue20 = 0;
        var curValue40 = 0;
        var curCustomValue = 0;
        var curCustomValue20 = 0;
        var curCustomValue40 = 0;
        var curLocalValue = 0;
        var curLocalValue20 = 0;
        var curLocalValue40 = 0;
        var curForeignValue = 0;
        var curForeignValue20 = 0;
        var curForeignValue40 = 0;
        var curFreightValue = 0;
        var curFreightValue20 = 0;
        var curFreightValue40 = 0;
        var index = 0;
        for (var i = 0; i < productForeignChargesItem.length; i++) {
            //var obj = removeCircularKey(productForeignChargesItem[i]);
            var obj = productForeignChargesItem[i];
            if (!obj.index)
                obj.index = index;
            index++;
            if (!obj.selectedUnit || !obj.selectedCurrency)
                continue;
            if (obj.selectedItem.name == "Select")
                continue;
            if (obj.units && obj.units.length) {
                if (!obj.selectedUnit || !obj.selectedUnit.id) {
                    obj.selectedUnit = obj.units[0]
                }
            }
            if (obj.currencies && obj.currencies.length) {
                if (!obj.selectedCurrency || !obj.selectedCurrency.id) {
                    obj.selectedCurrency = obj.currencies[0]
                }
            }
            var exch = listOfExchangeRates.find(u => u.id === obj.selectedCurrency.id)
            if (value.flc20) {
                curForeignValue20 += getCalculations(Number(obj.rate20 ? obj.rate20 : obj.selectedItem.rate20),
                    Number(obj.min),
                    obj.selectedUnit.id, true) / exch.oneUsd;
                orgForeignValue20 += getCalculations(Number(obj.orgValue20 ?
                    obj.orgValue20 : obj.rate20),
                    Number(0),
                    obj.selectedUnit.id, true) / exch.oneUsd;
            }
            if (value.flc40) {
                curForeignValue40 += getCalculations(Number(obj.rate40 ? obj.rate40 : obj.selectedItem.rate40),
                    Number(obj.min),
                    obj.selectedUnit.id, false) / exch.oneUsd;
                orgForeignValue40 += getCalculations(Number(obj.orgValue40 ?
                    obj.orgValue40 : obj.rate40),
                    Number(0),
                    obj.selectedUnit.id, false) / exch.oneUsd;
            }
            if (!value.flc40 && !value.flc20) {
                curForeignValue += getCalculations(Number(obj.rate ? obj.rate : obj.selectedItem.rate),
                    Number(obj.min),
                    obj.selectedUnit.id) / exch.oneUsd;
                orgForeignValue += getCalculations(Number(obj.orgValue ?
                    obj.orgValue : obj.rate),
                    Number(0),
                    obj.selectedUnit.id) / exch.oneUsd;
            }
        }
        index = 0;
        for (var i = 0; i < productFreightChargesItem1.length; i++) {
            //var obj = removeCircularKey(productFreightChargesItem1[i]);
            var obj = productFreightChargesItem1[i];
            if (!obj.index)
                obj.index = index;
            index++;
            if (!obj.selectedUnit || !obj.selectedCurrency)
                continue;
            if (obj.selectedItem.name == "Select")
                continue;
            if (obj.units && obj.units.length) {
                if (!obj.selectedUnit || !obj.selectedUnit.id) {
                    obj.selectedUnit = obj.units[0]
                }
            }
            if (obj.currencies && obj.currencies.length) {
                if (!obj.selectedCurrency || !obj.selectedCurrency.id) {
                    obj.selectedCurrency = obj.currencies[0]
                }
            }
            var exch = listOfExchangeRates.find(u => u.id === obj.selectedCurrency.id)
            if (value.flc20) {
                curFreightValue20 += getCalculations(Number(obj.rate20 ? obj.rate20 : obj.selectedItem.rate20),
                    Number(obj.min),
                    obj.selectedUnit.id, true) / exch.oneUsd;
                orgFreightValue20 += getCalculations(Number(obj.orgValue20 ?
                    obj.orgValue20 : obj.rate20),
                    Number(0),
                    obj.selectedUnit.id, true) / exch.oneUsd;
            }
            if (value.flc40) {
                curFreightValue40 += getCalculations(Number(obj.rate40 ? obj.rate40 : obj.selectedItem.rate40),
                    Number(obj.min),
                    obj.selectedUnit.id, false) / exch.oneUsd;
                orgFreightValue40 += getCalculations(Number(obj.orgValue40 ?
                    obj.orgValue40 : obj.rate40),
                    Number(0),
                    obj.selectedUnit.id, false) / exch.oneUsd;
            }
            if (!value.flc40 && !value.flc20) {
                curFreightValue += getCalculations(Number(obj.rate ? obj.rate : obj.selectedItem.rate),
                    Number(obj.min),
                    obj.selectedUnit.id) / exch.oneUsd;
                orgFreightValue += getCalculations(Number(obj.orgValue ?
                    obj.orgValue : obj.rate),
                    Number(0),
                    obj.selectedUnit.id) / exch.oneUsd;
            }
        }
        index = 0;
        for (var i = 0; i < productLocalChargesItem1.length; i++) {
            //var obj = removeCircularKey(productLocalChargesItem1[i]);
            var obj = productLocalChargesItem1[i];
            if (!obj.index)
                obj.index = index;
            index++;
            if (!obj.selectedUnit || !obj.selectedCurrency)
                continue;
            if (obj.selectedItem.name == "Select")
                continue;
            if (obj.units && obj.units.length) {
                if (!obj.selectedUnit || !obj.selectedUnit.id) {
                    obj.selectedUnit = obj.units[0]
                }
            }
            if (obj.currencies && obj.currencies.length) {
                if (!obj.selectedCurrency || !obj.selectedCurrency.id) {
                    obj.selectedCurrency = obj.currencies[0]
                }
            }
            var exch = listOfExchangeRates.find(u => u.id === obj.selectedCurrency.id)
            if (value.flc20) {
                curLocalValue20 += getCalculations(Number(obj.rate20 ? obj.rate20 : obj.selectedItem.rate20),
                    Number(obj.min),
                    obj.selectedUnit.id, true) / exch.oneUsd;
                orgLocalValue20 += getCalculations(Number(obj.orgValue20 ?
                    obj.orgValue20 : obj.rate20),
                    Number(0),
                    obj.selectedUnit.id, true) / exch.oneUsd;
            }
            if (value.flc40) {
                curLocalValue40 += getCalculations(Number(obj.rate40 ? obj.rate40 : obj.selectedItem.rate40),
                    Number(obj.min),
                    obj.selectedUnit.id, false) / exch.oneUsd;
                orgLocalValue40 += getCalculations(Number(obj.orgValue40 ?
                    obj.orgValue40 : obj.rate40),
                    Number(0),
                    obj.selectedUnit.id, false) / exch.oneUsd;
            }
            if (!value.flc40 && !value.flc20) {
                curLocalValue += getCalculations(Number(obj.rate ? obj.rate : obj.selectedItem.rate),
                    Number(obj.min),
                    obj.selectedUnit.id) / exch.oneUsd;
                orgLocalValue += getCalculations(Number(obj.orgValue ?
                    obj.orgValue : obj.rate),
                    Number(0),
                    obj.selectedUnit.id) / exch.oneUsd;
            }
        }
        index = 0;
        for (var i = 0; i < productCustomChargesItem1.length; i++) {
            //var obj = removeCircularKey(productCustomChargesItem1[i]);
            var obj = productCustomChargesItem1[i];
            if (!obj.index)
                obj.index = index;
            index++;
            if (!obj.selectedUnit || !obj.selectedCurrency)
                continue;
            if (obj.selectedItem.name == "Select")
                continue;
            if (obj.units && obj.units.length) {
                if (!obj.selectedUnit || !obj.selectedUnit.id) {
                    obj.selectedUnit = obj.units[0]
                }
            }
            if (obj.currencies && obj.currencies.length) {
                if (!obj.selectedCurrency || !obj.selectedCurrency.id) {
                    obj.selectedCurrency = obj.currencies[0]
                }
            }
            var exch = listOfExchangeRates.find(u => u.id === obj.selectedCurrency.id)
            if (value.flc20) {
                curCustomValue20 += getCalculations(Number(obj.rate20 ? obj.rate20 : obj.selectedItem.rate20),
                    Number(obj.min),
                    obj.selectedUnit.id, true) / exch.oneUsd;
                orgCustomValue20 += getCalculations(Number(obj.orgValue20 ?
                    obj.orgValue20 : obj.rate20),
                    Number(0),
                    obj.selectedUnit.id, true) / exch.oneUsd;
            }
            if (value.flc40) {
                curCustomValue40 += getCalculations(Number(obj.rate40 ? obj.rate40 : obj.selectedItem.rate40),
                    Number(obj.min),
                    obj.selectedUnit.id, false) / exch.oneUsd;
                orgCustomValue40 += getCalculations(Number(obj.orgValue40 ?
                    obj.orgValue40 : obj.rate40),
                    Number(0),
                    obj.selectedUnit.id, false) / exch.oneUsd;
            }
            if (!value.flc40 && !value.flc20) {
                curCustomValue += getCalculations(Number(obj.rate ? obj.rate : obj.selectedItem.rate),
                    Number(obj.min),
                    obj.selectedUnit.id) / exch.oneUsd;
                orgCustomValue += getCalculations(Number(obj.orgValue ?
                    obj.orgValue : obj.rate),
                    Number(0),
                    obj.selectedUnit.id) / exch.oneUsd;
            }
        }
        var params1 = { ...value.params }
        params1.orgForeignValue = Number(orgForeignValue).toFixed(2);
        params1.orgFreightValue = Number(orgFreightValue).toFixed(2);
        params1.orgCustomValue = Number(orgCustomValue).toFixed(2);
        params1.orgLocalValue = Number(orgLocalValue).toFixed(2);
        //params1.originalPrice = Number(orgForeignValue + orgFreightValue + orgCustomValue + orgLocalValue).toFixed(2);
        params1.originalPrice = Number(orgForeignValue + orgFreightValue + orgLocalValue).toFixed(2);
        params1.orgForeignValue20 = Number(orgForeignValue20).toFixed(2);
        params1.orgFreightValue20 = Number(orgFreightValue20).toFixed(2);
        params1.orgCustomValue20 = Number(orgCustomValue20).toFixed(2);
        params1.orgLocalValue20 = Number(orgLocalValue20).toFixed(2);
        //params1.originalPrice20 = Number(orgForeignValue20 + orgFreightValue20 + orgCustomValue20 + orgLocalValue20).toFixed(2);
        params1.originalPrice20 = Number(orgForeignValue20 + orgFreightValue20 + orgLocalValue20).toFixed(2);
        params1.orgForeignValue40 = Number(orgForeignValue40).toFixed(2);
        params1.orgFreightValue40 = Number(orgFreightValue40).toFixed(2);
        params1.orgCustomValue40 = Number(orgCustomValue40).toFixed(2);
        params1.orgLocalValue40 = Number(orgLocalValue40).toFixed(2);
        //params1.originalPrice40 = Number(orgForeignValue40 + orgFreightValue40 + orgCustomValue40 + orgLocalValue40).toFixed(2);
        params1.originalPrice40 = Number(orgForeignValue40 + orgFreightValue40 + orgLocalValue40).toFixed(2);

        params1.curForeignValue = Number(curForeignValue).toFixed(2);
        params1.curFreightValue = Number(curFreightValue).toFixed(2);
        params1.curCustomValue = Number(curCustomValue).toFixed(2);
        params1.curLocalValue = Number(curLocalValue).toFixed(2);
        //params1.curPrice = Number(curForeignValue + curFreightValue + curCustomValue + curLocalValue).toFixed(2);
        params1.curPrice = Number(curForeignValue + curFreightValue + curLocalValue).toFixed(2);
        params1.curForeignValue20 = Number(curForeignValue20).toFixed(2);
        params1.curFreightValue20 = Number(curFreightValue20).toFixed(2);
        params1.curCustomValue20 = Number(curCustomValue20).toFixed(2);
        params1.curLocalValue20 = Number(curLocalValue20).toFixed(2);
        //params1.curPrice20 = Number(curForeignValue20 + curFreightValue20 + curCustomValue20 + curLocalValue20).toFixed(2);
        params1.curPrice20 = Number(curForeignValue20 + curFreightValue20 + curLocalValue20).toFixed(2);
        params1.curForeignValue40 = Number(curForeignValue40).toFixed(2);
        params1.curFreightValue40 = Number(curFreightValue40).toFixed(2);
        params1.curCustomValue40 = Number(curCustomValue40).toFixed(2);
        params1.curLocalValue40 = Number(curLocalValue40).toFixed(2);
        //params1.curPrice40 = Number(curForeignValue40 + curFreightValue40 + curCustomValue40 + curLocalValue40).toFixed(2);
        params1.curPrice40 = Number(curForeignValue40 + curFreightValue40 + curLocalValue40).toFixed(2);

        params1.margin = Number(params1.curPrice - params1.originalPrice).toFixed(2);
        params1.margin20 = Number(params1.curPrice20 - params1.originalPrice20).toFixed(2);
        params1.margin40 = Number(params1.curPrice40 - params1.originalPrice40).toFixed(2);

        setValue(prev => ({ ...prev, params: params1 }))
    };

    const Number1 = (val) => {
        return val ? Number(val) : 0;
    }
    const recalclulateOriginalValuesWithForeignCharges = (productfcItems) => {
        var orgForeignValue = 0;
        var orgForeignValue20 = 0;
        var orgForeignValue40 = 0;
        var curForeignValue = 0;
        var curForeignValue20 = 0;
        var curForeignValue40 = 0;
        var index = 0;
        for (var i = 0; i < productfcItems.length; i++) {
            var obj = productfcItems[i];
            if (!obj.selectedUnit || !obj.selectedCurrency)
                continue;
            if (!obj.index) {
                obj.index = index;
                index++;
            }
            index++;

            if (obj.selectedItem.name == "Select")
                continue;
            if (obj.units && obj.units.length) {
                if (!obj.selectedUnit || !obj.selectedUnit.id) {
                    obj.selectedUnit = obj.units[0]
                }
            }
            if (obj.currencies && obj.currencies.length) {
                if (!obj.selectedCurrency || !obj.selectedCurrency.id) {
                    obj.selectedCurrency = obj.currencies[0]
                }
            }
            var exch = listOfExchangeRates.find(u => u.id === obj.selectedCurrency.id)
            if (value.flc20) {
                curForeignValue20 += getCalculations(Number(obj.rate20 ? obj.rate20 : obj.selectedItem.rate20),
                    Number(obj.min),
                    obj.selectedUnit.id, true) / exch.oneUsd;
                orgForeignValue20 += getCalculations(Number(obj.orgValue20 ?
                    obj.orgValue20 : obj.rate20),
                    Number(0),
                    obj.selectedUnit.id, true) / exch.oneUsd;
            }
            if (value.flc40) {
                curForeignValue40 += getCalculations(Number(obj.rate40 ? obj.rate40 : obj.selectedItem.rate40),
                    Number(obj.min),
                    obj.selectedUnit.id, false) / exch.oneUsd;
                orgForeignValue40 += getCalculations(Number(obj.orgValue40 ?
                    obj.orgValue40 : obj.rate40),
                    Number(0),
                    obj.selectedUnit.id, false) / exch.oneUsd;
            }
            if (!value.flc40 && !value.flc20) {
                curForeignValue += getCalculations(Number(obj.rate ? obj.rate : obj.selectedItem.rate),
                    Number(obj.min),
                    obj.selectedUnit.id) / exch.oneUsd;
                orgForeignValue += getCalculations(Number(obj.orgValue ?
                    obj.orgValue : obj.rate),
                    Number(0),
                    obj.selectedUnit.id) / exch.oneUsd;
            }
        }

        var params1 = { ...value.params }
        params1.orgForeignValue = Number1(orgForeignValue).toFixed(2);
        //params1.originalPrice = Number(Number1(params1.orgForeignValue) + Number1(params1.orgFreightValue) + Number1(params1.orgCustomValue) + Number1(params1.orgLocalValue)).toFixed(2);
        params1.originalPrice = Number(Number1(params1.orgForeignValue) + Number1(params1.orgFreightValue) + Number1(params1.orgLocalValue)).toFixed(2);
        params1.orgForeignValue20 = Number(orgForeignValue20).toFixed(2);
        //params1.originalPrice20 = Number(Number1(params1.orgForeignValue20) + Number1(params1.orgFreightValue20) + Number1(params1.orgCustomValue20) + Number1(params1.orgLocalValue20)).toFixed(2);
        params1.originalPrice20 = Number(Number1(params1.orgForeignValue20) + Number1(params1.orgFreightValue20) + Number1(params1.orgLocalValue20)).toFixed(2);
        params1.orgForeignValue40 = Number(orgForeignValue40).toFixed(2);
        //params1.originalPrice40 = Number(Number1(params1.orgForeignValue40) + Number1(params1.orgFreightValue40) + Number1(params1.orgCustomValue40) + Number1(params1.orgLocalValue40)).toFixed(2);
        params1.originalPrice40 = Number(Number1(params1.orgForeignValue40) + Number1(params1.orgFreightValue40) + Number1(params1.orgLocalValue40)).toFixed(2);

        params1.curForeignValue = Number(curForeignValue).toFixed(2);
        //params1.curPrice = Number(Number1(params1.curForeignValue) + Number1(params1.curFreightValue) + Number1(params1.curCustomValue) + Number1(params1.curLocalValue)).toFixed(2);
        params1.curPrice = Number(Number1(params1.curForeignValue) + Number1(params1.curFreightValue) + Number1(params1.curLocalValue)).toFixed(2);
        params1.curForeignValue20 = Number(curForeignValue20).toFixed(2);
        //params1.curPrice20 = Number(Number1(params1.curForeignValue20) + Number1(params1.curFreightValue20) + Number1(params1.curCustomValue20) + Number1(params1.curLocalValue20)).toFixed(2);
        params1.curPrice20 = Number(Number1(params1.curForeignValue20) + Number1(params1.curFreightValue20) + Number1(params1.curLocalValue20)).toFixed(2);
        params1.curForeignValue40 = Number(curForeignValue40).toFixed(2);
        //params1.curPrice40 = Number(Number1(params1.curForeignValue40) + Number1(params1.curFreightValue40) + Number1(params1.curCustomValue40) + Number1(params1.curLocalValue40)).toFixed(2);
        params1.curPrice40 = Number(Number1(params1.curForeignValue40) + Number1(params1.curFreightValue40)+ Number1(params1.curLocalValue40)).toFixed(2);

        params1.margin = Number(params1.curPrice - params1.originalPrice).toFixed(2);
        params1.margin20 = Number(params1.curPrice20 - params1.originalPrice20).toFixed(2);
        params1.margin40 = Number(params1.curPrice40 - params1.originalPrice40).toFixed(2);

        setValue(prev => ({ ...prev, params: params1 }))
        /*window.setTimeout(function () {
            recalclulateOriginalValues();
        }, 100);*/
    };

    const recalclulateOriginalValuesWithFreightCharges = (productFCItems) => {
        var orgFreightValue = 0;
        var orgFreightValue20 = 0;
        var orgFreightValue40 = 0;
        var curFreightValue = 0;
        var curFreightValue20 = 0;
        var curFreightValue40 = 0;
        var index = 0;
        for (var i = 0; i < productFCItems.length; i++) {
            //var obj = removeCircularKey(productFreightChargesItem1[i]);
            var obj = productFCItems[i];
            if (!obj.selectedUnit || !obj.selectedCurrency)
                continue;
            if (!obj.index) {
                obj.index = index;
                index++;
            }
            index++;
            if (obj.selectedItem.name == "Select")
                continue;
            if (obj.units && obj.units.length) {
                if (!obj.selectedUnit || !obj.selectedUnit.id) {
                    obj.selectedUnit = obj.units[0]
                }
            }
            if (obj.currencies && obj.currencies.length) {
                if (!obj.selectedCurrency || !obj.selectedCurrency.id) {
                    obj.selectedCurrency = obj.currencies[0]
                }
            }
            var exch = listOfExchangeRates.find(u => u.id === obj.selectedCurrency?.id)
            if (value.flc20) {
                curFreightValue20 += getCalculations(Number(obj.rate20 ? obj.rate20 : obj.selectedItem.rate20),
                    Number(obj.min),
                    obj.selectedUnit.id, true) / exch.oneUsd;
                orgFreightValue20 += getCalculations(Number(obj.orgValue20 ?
                    obj.orgValue20 : obj.rate20),
                    Number(0),
                    obj.selectedUnit.id, true) / exch.oneUsd;
            }
            if (value.flc40) {
                curFreightValue40 += getCalculations(Number(obj.rate40 ? obj.rate40 : obj.selectedItem.rate40),
                    Number(obj.min),
                    obj.selectedUnit.id, false) / exch.oneUsd;
                orgFreightValue40 += getCalculations(Number(obj.orgValue40 ?
                    obj.orgValue40 : obj.rate40),
                    Number(0),
                    obj.selectedUnit.id, false) / exch.oneUsd;
            }
            if (!value.flc40 && !value.flc20) {
                curFreightValue += getCalculations(Number(obj.rate ? obj.rate : obj.selectedItem.rate),
                    Number(obj.min),
                    obj.selectedUnit.id) / exch.oneUsd;
                orgFreightValue += getCalculations(Number(obj.orgValue ?
                    obj.orgValue : obj.rate),
                    Number(0),
                    obj.selectedUnit.id) / exch.oneUsd;
            }
        }
        var params1 = { ...value.params }
        params1.orgFreightValue = Number(orgFreightValue).toFixed(2);
        //params1.originalPrice = Number(Number1(params1.orgForeignValue) + Number1(params1.orgFreightValue) + Number1(params1.orgCustomValue) + Number1(params1.orgLocalValue)).toFixed(2);
        params1.originalPrice = Number(Number1(params1.orgForeignValue) + Number1(params1.orgFreightValue) + Number1(params1.orgLocalValue)).toFixed(2);
        params1.orgFreightValue20 = Number(orgFreightValue20).toFixed(2);
        //params1.originalPrice20 = Number(Number1(params1.orgForeignValue20) + Number1(params1.orgFreightValue20) + Number1(params1.orgCustomValue20) + Number1(params1.orgLocalValue20)).toFixed(2);
        params1.originalPrice20 = Number(Number1(params1.orgForeignValue20) + Number1(params1.orgFreightValue20) + Number1(params1.orgLocalValue20)).toFixed(2);
        params1.orgFreightValue40 = Number(orgFreightValue40).toFixed(2);
        //params1.originalPrice40 = Number(Number1(params1.orgForeignValue40) + Number1(params1.orgFreightValue40) + Number1(params1.orgCustomValue40) + Number1(params1.orgLocalValue40)).toFixed(2);
        params1.originalPrice40 = Number(Number1(params1.orgForeignValue40) + Number1(params1.orgFreightValue40) + Number1(params1.orgLocalValue40)).toFixed(2);

        params1.curFreightValue = Number(curFreightValue).toFixed(2);
       // params1.curPrice = Number(Number1(params1.curForeignValue) + Number1(params1.curFreightValue) + Number1(params1.curCustomValue) + Number1(params1.curLocalValue)).toFixed(2);
        params1.curPrice = Number(Number1(params1.curForeignValue) + Number1(params1.curFreightValue) + Number1(params1.curLocalValue)).toFixed(2);
        params1.curFreightValue20 = Number(curFreightValue20).toFixed(2);
        //params1.curPrice20 = Number(Number1(params1.curForeignValue20) + Number1(params1.curFreightValue20) + Number1(params1.curCustomValue20) + Number1(params1.curLocalValue20)).toFixed(2);
        params1.curPrice20 = Number(Number1(params1.curForeignValue20) + Number1(params1.curFreightValue20) + Number1(params1.curLocalValue20)).toFixed(2);
        params1.curFreightValue40 = Number(curFreightValue40).toFixed(2);
        //params1.curPrice40 = Number(Number1(params1.curForeignValue40) + Number1(params1.curFreightValue40) + Number1(params1.curCustomValue40) + Number1(params1.curLocalValue40)).toFixed(2);
        params1.curPrice40 = Number(Number1(params1.curForeignValue40) + Number1(params1.curFreightValue40) + Number1(params1.curLocalValue40)).toFixed(2);

        params1.margin = Number(params1.curPrice - params1.originalPrice).toFixed(2);
        params1.margin20 = Number(params1.curPrice20 - params1.originalPrice20).toFixed(2);
        params1.margin40 = Number(params1.curPrice40 - params1.originalPrice40).toFixed(2);

        setValue(prev => ({ ...prev, params: params1 }))
        /*window.setTimeout(function () {
            recalclulateOriginalValues();
        }, 100);*/
    };
    const recalclulateOriginalValuesWithLocalCharges = (productLCItems) => {
        var orgLocalValue = 0;
        var orgLocalValue20 = 0;
        var orgLocalValue40 = 0;
        var curLocalValue = 0;
        var curLocalValue20 = 0;
        var curLocalValue40 = 0;
        var index = 0;
        for (var i = 0; i < productLCItems.length; i++) {
            //var obj = removeCircularKey(productLCItems[i]);
            var obj = productLCItems[i];
            if (!obj.index) {
                obj.index = index;
                index++;
            } else
                index++;
            if (!obj.selectedUnit || !obj.selectedCurrency)
                continue;
            if (obj.selectedItem.name == "Select")
                continue;
            if (obj.units && obj.units.length) {
                if (!obj.selectedUnit || !obj.selectedUnit.id) {
                    obj.selectedUnit = obj.units[0]
                }
            }
            if (obj.currencies && obj.currencies.length) {
                if (!obj.selectedCurrency || !obj.selectedCurrency.id) {
                    obj.selectedCurrency = obj.currencies[0]
                }
            }
            var exch = listOfExchangeRates.find(u => u.id === obj.selectedCurrency.id)
            if (value.flc20) {
                curLocalValue20 += getCalculations(Number(obj.rate20 ? obj.rate20 : obj.selectedItem.rate20),
                    Number(obj.min),
                    obj.selectedUnit.id, true) / exch.oneUsd;
                orgLocalValue20 += getCalculations(Number(obj.orgValue20 ?
                    obj.orgValue20 : obj.rate20),
                    Number(0),
                    obj.selectedUnit.id, true) / exch.oneUsd;
            }
            if (value.flc40) {
                curLocalValue40 += getCalculations(Number(obj.rate40 ? obj.rate40 : obj.selectedItem.rate40),
                    Number(obj.min),
                    obj.selectedUnit.id, false) / exch.oneUsd;
                orgLocalValue40 += getCalculations(Number(obj.orgValue40 ?
                    obj.orgValue40 : obj.rate40),
                    Number(0),
                    obj.selectedUnit.id, false) / exch.oneUsd;
            }
            if (!value.flc40 && !value.flc20) {
                curLocalValue += getCalculations(Number(obj.rate),
                    Number(obj.min),
                    obj.selectedUnit.id) / exch.oneUsd;
                orgLocalValue += getCalculations(Number(obj.orgValue ?
                    obj.orgValue : obj.rate),
                    Number(0),
                    obj.selectedUnit.id) / exch.oneUsd;
            }
        }
        var params1 = { ...value.params }

        params1.orgLocalValue = Number(orgLocalValue).toFixed(2);
        //params1.originalPrice = Number(Number1(params1.orgForeignValue) + Number1(params1.orgFreightValue) + Number1(params1.orgCustomValue) + Number1(params1.orgLocalValue)).toFixed(2);
        params1.originalPrice = Number(Number1(params1.orgForeignValue) + Number1(params1.orgFreightValue) + Number1(params1.orgLocalValue)).toFixed(2);
        params1.orgLocalValue20 = Number(orgLocalValue20).toFixed(2);
        //params1.originalPrice20 = Number(Number1(params1.orgForeignValue20) + Number1(params1.orgFreightValue20) + Number1(params1.orgCustomValue20) + Number1(params1.orgLocalValue20)).toFixed(2);
        params1.originalPrice20 = Number(Number1(params1.orgForeignValue20) + Number1(params1.orgFreightValue20) + Number1(params1.orgLocalValue20)).toFixed(2);
        params1.orgLocalValue40 = Number(orgLocalValue40).toFixed(2);
        //params1.originalPrice40 = Number(Number1(params1.orgForeignValue40) + Number1(params1.orgFreightValue40) + Number1(params1.orgCustomValue40) + Number1(params1.orgLocalValue40)).toFixed(2);
        params1.originalPrice40 = Number(Number1(params1.orgForeignValue40) + Number1(params1.orgFreightValue40) + Number1(params1.orgLocalValue40)).toFixed(2);

        params1.curLocalValue = Number(curLocalValue).toFixed(2);
        //params1.curPrice = Number(Number1(params1.curForeignValue) + Number1(params1.curFreightValue) + Number1(params1.curCustomValue) + Number1(params1.curLocalValue)).toFixed(2);
        params1.curPrice = Number(Number1(params1.curForeignValue) + Number1(params1.curFreightValue) + Number1(params1.curLocalValue)).toFixed(2);
        params1.curLocalValue20 = Number(curLocalValue20).toFixed(2);
        //params1.curPrice20 = Number(Number1(params1.curForeignValue20) + Number1(params1.curFreightValue20) + Number1(params1.curCustomValue20) + Number1(params1.curLocalValue20)).toFixed(2);
        params1.curPrice20 = Number(Number1(params1.curForeignValue20) + Number1(params1.curFreightValue20) + Number1(params1.curLocalValue20)).toFixed(2);
        params1.curLocalValue40 = Number(curLocalValue40).toFixed(2);
        //params1.curPrice40 = Number(Number1(params1.curForeignValue40) + Number1(params1.curFreightValue40) + Number1(params1.curCustomValue40) + Number1(params1.curLocalValue40)).toFixed(2);
        params1.curPrice40 = Number(Number1(params1.curForeignValue40) + Number1(params1.curFreightValue40) + Number1(params1.curLocalValue40)).toFixed(2);

        params1.margin = Number(params1.curPrice - params1.originalPrice).toFixed(2);
        params1.margin20 = Number(params1.curPrice20 - params1.originalPrice20).toFixed(2);
        params1.margin40 = Number(params1.curPrice40 - params1.originalPrice40).toFixed(2);

        setValue(prev => ({ ...prev, params: params1 }))
        /*window.setTimeout(function () {
            recalclulateOriginalValues();
        }, 100);*/
    };

    const recalclulateOriginalValuesWithCustomCharges = (productCCItems) => {
        var orgCustomValue = 0;
        var orgCustomValue20 = 0;
        var orgCustomValue40 = 0;
        var curCustomValue = 0;
        var curCustomValue20 = 0;
        var curCustomValue40 = 0;
        var index = 0;
        for (var i = 0; i < productCCItems.length; i++) {
            //var obj = removeCircularKey(productCCItems[i]);
            var obj = productCCItems[i];
            if (!obj.selectedUnit || !obj.selectedCurrency)
                continue;
            if (!obj.index) {
                obj.index = index;
                index++;
            }
            if (obj.selectedItem.name == "Select")
                continue;
            index++;
            if (obj.units && obj.units.length) {
                if (!obj.selectedUnit || !obj.selectedUnit.id) {
                    obj.selectedUnit = obj.units[0]
                }
            }
            if (obj.currencies && obj.currencies.length) {
                if (!obj.selectedCurrency || !obj.selectedCurrency.id) {
                    obj.selectedCurrency = obj.currencies[0]
                }
            }
            var exch = listOfExchangeRates.find(u => u.id === obj.selectedCurrency.id)
            if (value.flc20) {
                curCustomValue20 += getCalculations(Number(obj.rate20 ? obj.rate20 : obj.selectedItem.rate20),
                    Number(obj.min),
                    obj.selectedUnit.id, true) / exch.oneUsd;
                orgCustomValue20 += getCalculations(Number(obj.orgValue20 ?
                    obj.orgValue20 : obj.rate20),
                    Number(0),
                    obj.selectedUnit.id, true) / exch.oneUsd;
            }
            if (value.flc40) {
                curCustomValue40 += getCalculations(Number(obj.rate40 ? obj.rate40 : obj.selectedItem.rate40),
                    Number(obj.min),
                    obj.selectedUnit.id, false) / exch.oneUsd;
                orgCustomValue40 += getCalculations(Number(obj.orgValue40 ?
                    obj.orgValue40 : obj.rate40),
                    Number(0),
                    obj.selectedUnit.id, false) / exch.oneUsd;
            }
            if (!value.flc40 && !value.flc20) {
                curCustomValue += getCalculations(Number(obj.rate ? obj.rate : obj.selectedItem.rate),
                    Number(obj.min),
                    obj.selectedUnit.id) / exch.oneUsd;
                orgCustomValue += getCalculations(Number(obj.orgValue ?
                    obj.orgValue : obj.rate),
                    Number(0),
                    obj.selectedUnit.id) / exch.oneUsd;
            }
        }
        var params1 = { ...value.params }

        params1.orgCustomValue = Number(orgCustomValue).toFixed(2);
        //params1.originalPrice = Number(Number1(params1.orgForeignValue) + Number1(params1.orgFreightValue) + Number1(params1.orgCustomValue) + Number1(params1.orgLocalValue)).toFixed(2);
        params1.originalPrice = Number(Number1(params1.orgForeignValue) + Number1(params1.orgFreightValue) + Number1(params1.orgLocalValue)).toFixed(2);
        params1.orgCustomValue20 = Number(orgCustomValue20).toFixed(2);
        //params1.originalPrice20 = Number(Number1(params1.orgForeignValue20) + Number1(params1.orgFreightValue20) + Number1(params1.orgCustomValue20) + Number1(params1.orgLocalValue20)).toFixed(2);
        params1.originalPrice20 = Number(Number1(params1.orgForeignValue20) + Number1(params1.orgFreightValue20) + Number1(params1.orgLocalValue20)).toFixed(2);
        params1.orgCustomValue40 = Number(orgCustomValue40).toFixed(2);
        //params1.originalPrice40 = Number(Number1(params1.orgForeignValue40) + Number1(params1.orgFreightValue40) + Number1(params1.orgCustomValue40) + Number1(params1.orgLocalValue40)).toFixed(2);
        params1.originalPrice40 = Number(Number1(params1.orgForeignValue40) + Number1(params1.orgFreightValue40) + Number1(params1.orgLocalValue40)).toFixed(2);

        params1.curCustomValue = Number(curCustomValue).toFixed(2);
        params1.curPrice = Number(Number1(params1.curForeignValue) + Number1(params1.curFreightValue) + Number1(params1.curCustomValue) + Number1(params1.curLocalValue)).toFixed(2);
        params1.curPrice = Number(Number1(params1.curForeignValue) + Number1(params1.curFreightValue) + Number1(params1.curLocalValue)).toFixed(2);
        params1.curCustomValue20 = Number(curCustomValue20).toFixed(2);
        //params1.curPrice20 = Number(Number1(params1.curForeignValue20) + Number1(params1.curFreightValue20) + Number1(params1.curCustomValue20) + Number1(params1.curLocalValue20)).toFixed(2);
        params1.curPrice20 = Number(Number1(params1.curForeignValue20) + Number1(params1.curFreightValue20) + Number1(params1.curLocalValue20)).toFixed(2);
        params1.curCustomValue40 = Number(curCustomValue40).toFixed(2);
        //params1.curPrice40 = Number(Number1(params1.curForeignValue40) + Number1(params1.curFreightValue40) + Number1(params1.curCustomValue40) + Number1(params1.curLocalValue40)).toFixed(2);
        params1.curPrice40 = Number(Number1(params1.curForeignValue40) + Number1(params1.curFreightValue40) + Number1(params1.curLocalValue40)).toFixed(2);

        params1.margin = Number(params1.curPrice - params1.originalPrice).toFixed(2);
        params1.margin20 = Number(params1.curPrice20 - params1.originalPrice20).toFixed(2);
        params1.margin40 = Number(params1.curPrice40 - params1.originalPrice40).toFixed(2);

        setValue(prev => ({ ...prev, params: params1 }))
        /*window.setTimeout(function () {
            recalclulateOriginalValues();
        }, 100);*/
    };

    const backPage = () => {
        setNextPage(false);
        setUserData(null);
        value.token = "";
    };
    const nextPage = () => {
        setNextPage(true);
    };
    const isCreateShipmentDiasabled = () => {
        return (!value.token || !value.descId ||
            !value.serialNum || !value.weight || !value.length || !value.width ||
            !value.height || !value.packages);
    }
    const [checked, setChecked] = useState(false);
    const [bEdit, setbEdit] = useState(false);
    const [bRate, setBrate] = useState(false);
    const save = () => {

        props.onHandleCancel();

    };
    const [lockerId, setLockerId] = useState("");
    const [statusId, setStatusId] = useState(parcelStatusList[0].id);
    const [bView, setBview] = useState(false);
    const [endDate, setEndDate] = useState(null);
    const resetShippingFilter = () => {

    };
    const update = () => {
        addDesc(dispatch, snackbarToggle, value, admin);
        props.onHandleCancel();
    };
    function uniqueByKey(array, key) {
        return [...new Map(array.map((x) => [x[key], x])).values()];
    }
    function getRange(num) {
        var aa = JSON.parse(JSON.stringify(listOfCostWeight));
        var forIndex = JSON.parse(JSON.stringify(listOfCostWeight));
        if (value.shipmentType == 1) {
            aa.push(4999)
            aa.push(9999)
            forIndex.push(4999)
            forIndex.push(9999)
        }
        var bb = aa.reverse();
        var numToConsiderMin = bb[bb?.length - 1];
        var numToConsiderMAx = bb[0];
        for (var i = 0; i < bb.length; i++) {
            if (num < bb[i]) {
                numToConsiderMAx = bb[i]
            }
        }
        var index = forIndex.indexOf(numToConsiderMAx);
        if (index == 0) {
            numToConsiderMin = numToConsiderMAx;
        } else {
            numToConsiderMin = forIndex[index - 1]
        }
        if (numToConsiderMin > num) {
            numToConsiderMin = num;
        }
        return { min: numToConsiderMin, max: numToConsiderMAx }
    }
    const getQuote = (shipmentType) => {
        setbEdit(true)
        if (shipmentType.target) {
            if (!shipmentType.target.value) {
                shipmentType = value.shipmentType;
            }
        }
        var t = []
        var t3 = []
        if (shipmentType == 1) {
            for (var i = 0; i < listOfAirExportAFs.length; i++) {
                var bWeight = false;
                if (listOfAirExportAFs[i]?.weightList) {
                    var weight = Number(listOfAirExportAFs[i]?.weightList[0]);
                    var range = getRange(getTotalCostChargeable());
                    var min = range.min;
                    if (min > getTotalCostChargeable()) {
                        min = getTotalCostChargeable();
                    }
                    var max = range.max;

                    if (weight > min && weight <= max) {
                        bWeight = true;
                    }
                }
                if (listOfAirExportAFs[i].toId == value.to && listOfAirExportAFs[i].fromId == value.from && listOfAirExportAFs[i].importExportType == value.productType && bWeight) {
                    var tObj = JSON.parse(JSON.stringify(listOfAirExportAFs[i]));
                    tObj.weight = listOfAirExportAFs[i].weightList[0];
                    tObj.rate = listOfAirExportAFs[i].rateList[0];
                    tObj.quoteRate = listOfAirExportAFs[i].rateList[0];
                    var airLine = listOfAirLine.find(u => u.id === tObj.airLineId);
                    if (airLine)
                        t.push(tObj);
                }
            }
        } else if (shipmentType == 2) {
            var coLoaderCrms = getCRMsByFunctionTypes1(5)
            var isCrmColoader = function (id) {
                for (var crmInd = 0; crmInd < coLoaderCrms.length; crmInd++) {
                    if (coLoaderCrms[crmInd].id == id) {
                        return true;
                    }
                }
                return false;
            }
            for (var i = 0; i < listOfLclExportImportAFs.length; i++) {
                var cond = false;
                var c2 = isCrmColoader(listOfLclExportImportAFs[i].crmId)
                if (value.from && listOfLclExportImportAFs[i].portLid == value.from && listOfLclExportImportAFs[i].portAid == value.to && value.productType != 1) {
                    cond = true;
                }
                if (listOfLclExportImportAFs[i].portAid == value.to && value.productType == 1) {
                    cond = true;
                }
                if (cond && listOfLclExportImportAFs[i].importExportType == value.productType && c2) {
                    var tObj = JSON.parse(JSON.stringify(listOfLclExportImportAFs[i]));
                    if (tObj.weightList) {
                        tObj.weight = tObj.weightList[0];
                    } else {
                        tObj.weight = 0;
                    }
                    tObj.rate = tObj.rateList[0];
                    tObj.quoteRate = tObj.rateList[0];
                    t.push(tObj);
                }
            }
            if (value.productType != 1) {
                var t2 = [];
                var tx = getCRMsByFunctionTypeAndCountry(2, value.countryId);
                t2 = JSON.parse(JSON.stringify(tx))
                for (var k = 0; k < t2.length; k++) {
                    var crm1 = t2[k]
                    //console.log(crm1)
                    var x = 0;
                    var charges = [];
                    if (value.productType == 2) {
                        charges = crm1?.charges?.LCL_IMPORT

                    } else {
                        charges = []
                    }
                    if (charges) {
                        charges.forEach(item => {
                            if (item) {
                                var exch = listOfExchangeRates.find(u => u.id === item.currency)
                                if (exch && item.unitOfMeasure != null && item.unitOfMeasure != undefined) {
                                    x = x + getCalculations(item.rate, item.min, item.unitOfMeasure) / exch.oneUsd;
                                }
                            }
                        });
                    }
                    t2[k].fcaCalCharges = Number(x).toFixed(2);
                }
                setListOfLclQuoatationCost(t2)
            }
        }
        //listOfLclExportImportAFs
        var nowForProperTime = new Date().getTime();
        t.sort(function (a, b) {
            return new Date(b.updateTime) - new Date(a.updateTime);
        });
        var tr = [];
        for (var i = 0; i < t.length; i++) {
            var badd = false;
            var item = null;
            /*if (item) {
                for (var k = 0; k < tr.length; k++) {
                    if (item.airLineId == tr[k].airLineId) {
                        var ind = tr.indexOf(tr[k]);
                        tr.splice(ind, 1);
                    }
                }
                badd = false;
            }*/
            if (!badd) {
                var crm1 = crmList.find(u => u.id === t[i].crmId)
                //console.log(crm1)
                if (crm1?.charges?.AIR_IMPORT) {
                    var x = 0;

                    crm1?.charges?.AIR_IMPORT.forEach(item => {

                        if (item) {
                            var exch = listOfExchangeRates.find(u => u.id === item.currency)
                            if (exch) {
                                x = x + getCalculations(item.rate, item.min, item.unitOfMeasure) / exch.oneUsd;
                            }
                        }
                    });
                    t[i].fcaCalCharges = Number(x).toFixed(2);
                }
                for (var j = 0; j < tr.length; j++) {
                    if (tr[j]?.airLineId == t[i]?.airLineId && tr[j]?.crmId == t[i]?.crmId) {
                        if (t[i].validfrom <= nowForProperTime && nowForProperTime <= t[i].validDate) {
                            tr[j] = { ...t[i] };
                        }
                        item = t[i];
                        break;
                    }
                }
                if (!item)
                    tr.push(t[i]);
            }
        }
        setListOfQuoatationCost(tr)
        if (shipmentType == 3) {

            for (var i = 0; i < listOfFclExportImportAFs.length; i++) {
                var cond = false;
                var c2 = true;// isCrmColoader(listOfFclImportExport[i].crmId)
                if (value.from && listOfFclExportImportAFs[i].portLid == value.from && listOfFclExportImportAFs[i].portAid == value.to && value.productType != 1) {
                    cond = true;
                }
                if (listOfFclExportImportAFs[i].portAid == value.to && value.productType == 1) {
                    cond = true;
                }
                if (cond && listOfFclExportImportAFs[i].importExportType == value.productType && c2) {
                    var tObj = JSON.parse(JSON.stringify(listOfFclExportImportAFs[i]));
                    if (tObj.weightList) {
                        tObj.weight = tObj.weightList[0];
                    } else {
                        tObj.weight = 0;
                    }
                    if (tObj?.rateList) {
                        tObj.rate = tObj?.rateList[0];
                        tObj.quoteRate = tObj.rateList[0];
                    }
                    t.push(tObj);
                }
            }
            t.sort(function (a, b) {
                return new Date(b.updateTime) - new Date(a.updateTime);
            });
            window.setTimeout(function () {
                var tr = [];
                for (var i = 0; i < t.length; i++) {
                    var badd = false;
                    for (var j = 0; j < tr.length; j++) {
                        if (tr[j].portAid == t[i].portAid && t[i].shippingCrmId == tr[j].shippingCrmId) {
                            badd = true;
                            break;
                        }
                    }
                    if (!badd) {
                        var crm1 = crmList.find(u => u.id === (t[i].agentCrmId))
                        if (crm1?.charges?.FCL_IMPORT) {
                            var x = 0;
                            var x1 = 0;

                            crm1?.charges?.FCL_IMPORT.forEach(item => {

                                if (item) {
                                    var exch = listOfExchangeRates.find(u => u.id === item.currency)
                                    if (exch) {
                                        x = x + getCalculations(item.rate20, item.min, item.unitOfMeasure) / exch.oneUsd;
                                        x1 = x1 + getCalculations(item.rate40, item.min, item.unitOfMeasure) / exch.oneUsd;
                                    }
                                }
                            });
                            t[i].fcaCalCharges20 = Number(x).toFixed(2);
                            t[i].fcaCalCharges40 = Number(x1).toFixed(2);
                        }
                        tr.push(t[i]);
                    }
                }
                setListOfFclQuotations(tr)
            }, 10)

        }
    };
    const getTotalCostSumAir = function () {
        var tot = 0;
        for (var i = 0; i < costShipmentDetails.length; i++) {
            tot = tot + costShipmentDetails[i].weight * costShipmentDetails[i].pieces;
        }
        return Number(Number(props.valueData?.manualWeight ? props.valueData?.manualWeight : tot).toFixed(2))
        //return tot;
    }
    const getTotalCostVolumeAir = function () {
        var tot = 0;
        for (var i = 0; i < costShipmentDetails.length; i++) {
            tot = tot + (costShipmentDetails[i].pieces * costShipmentDetails[i].length * costShipmentDetails[i].width * costShipmentDetails[i].height) / 1000000;
        }
        tot = Number(Number(props.valueData?.manualVolume ? props.valueData?.manualVolume : tot).toFixed(2))
        if (props?.shipmentType == 2) {
            if (tot) {
                tot = tot < 1 ? 1 : tot;
            }
        }
        return tot;
    }
    const getTotalCostChargeableAir = function () {
        var kfF = listOfKgFactor.find(u => u.shipmentType === 'Air')?.kg

        var totCharg = getTotalCostVolumeAir() * kfF;
        if (totCharg < getTotalCostSumAir()) {
            totCharg = getTotalCostSumAir();
        }
        if (totCharg <= 99)
            return 99;
        else if (totCharg < 299)
            return 299;
        else if (totCharg < 499)
            return 499;
        else if (totCharg < 999)
            return 999;
        else if (totCharg < 1999)
            return 1999;
        else if (totCharg < 4999)
            return 4999;
        else
            return 9999;
    }
    const getCRMsByFunctionTypeAndCountry = function (functionId, countryId) {
        var temp = []
        for (var i = 0; i < crmList.length; i++) {
            if (crmList[i].functionId == functionId && crmList[i].countryId == countryId) {
                temp.push(crmList[i])
            }
        }
        return temp;
    }
    const [originalCostErrorDlg, setOriginalCostErrorDlg] = useState(false);
    const showOriginalCostError = () => {
        setOriginalCostErrorDlg(true);
    }
    const hideOriginalCostError = () => {
        setOriginalCostErrorDlg(false);
    }
    const checkForInvalidOriginalCosts = () => {
        var originalValueEmpty = true;
        productForeignChargesItem.forEach(item => {
            if (!(item.orgValue || item.orgValue20 || item.orgValue40)) {
                originalValueEmpty = false;
            }
        });
        if (originalValueEmpty) {
            productFreightChargesItem1.forEach(item => {
                if (!(item.orgValue || item.orgValue20 || item.orgValue40)) {
                    originalValueEmpty = false;
                }
            });
        }else
            return false;
        if (originalValueEmpty) {
            productLocalChargesItem1.forEach(item => {
                if (!(item.orgValue || item.orgValue20 || item.orgValue40)) {
                    originalValueEmpty = false;
                }
            });
        } else
            return false;
        if (originalValueEmpty) {
            productCustomChargesItem1.forEach(item => {
                if (!(item.orgValue || item.orgValue20 || item.orgValue40)) {
                    originalValueEmpty = false;
                }
            });
        } else
            return false;
        return originalValueEmpty;
    }
    const [selectedQuoatationCost, setSelectedQuoatationCost] = useState({});
    const [agentText, setAgentTextEx] = useState("");
    const [agentText1, setAgentText1Ex] = useState("");
    var selectedQuoatationCost1 = {}
    var originalPrice1 = 0;
    const setAgentText = function (item) {
        var at = item + "/" + agentText1
        setValue(prev => ({ ...prev, agentText: at }));
        setAgentTextEx(item);
    }
    const setAgentText1 = function (item) {
        var at = agentText + "/" + item
        setValue(prev => ({ ...prev, agentText: at }));
        setAgentText1Ex(item);
    }

    const quoteByRate = function (item, bLclCo) {
        //pppp
        //console.log("listOfQuoatationCost")
        //console.log(listOfQuoatationCost)
        //console.log("listOfFclQuotations")
        //console.log(listOfFclQuotations)
        //console.log("listOfLclQuoatationCost")
        //console.log(listOfLclQuoatationCost)
        var ids = []
        for (var i = 0; i < listOfQuoatationCost?.length; i++) {
            ids.push(listOfQuoatationCost[i].crmId)
        }
        for (var i = 0; i < listOfLclQuoatationCost?.length; i++) {
            ids.push(listOfLclQuoatationCost[i].id)
            ids.push(listOfLclQuoatationCost[i].crmId)
        }
        ids.push(value.cutomerCrmId)
        // getCRMList1(dispatch, admin, function (data) {
        getCRMListByIds(dispatch, admin, ids, function (data) {
       // getCrmById_Updated(dispatch, admin, value.cutomerCrmId, function (data) {
            listOfUpdatedCrm = [...crmList]
            var updateCrmData = function (data1) {
                var bUpdated = false;
                for (var i = 0; i < listOfUpdatedCrm.length; i++) {
                    if (data1.id === listOfUpdatedCrm[i].id) {
                        bUpdated = true;
                        listOfUpdatedCrm[i] = data1;
                        //updateCrm(dispatch, data1);
                        break;
                    }
                }
                if (!bUpdated) {
                    listOfUpdatedCrm.push(data1);
                }
            }

           
            for (var k = 0; k < data?.length; k++) {
                updateCrmData(data[k])
            }
            // listOfUpdatedCrm = data;
            setBrate(true)
            setSelectedQuoatationCost(item)
            selectedQuoatationCost1 = item

            if (value.productType == 2 && value.shipmentType == 2) {
                if (bLclCo) {
                    window.setTimeout(function () {
                        setoriginalPrice(0)
                        setProductFreightChargesItem1([])
                        setProductLocalChargesItem1([])
                        setProductCustomChargesItem1([])
                        setproductCustomChargesItemOriginal([])
                        recalclulateOriginalValues();
                    }, 0)/**/
                    setProductFreightChargesItem1([])
                    getProductChargesByProdShipFreight();
                    getProductChargesByProdShipChargeLocal();
                    getProductChargesByProdShipChargeCustome();
                    recalclulateOriginalValues();

                } else {
                    window.setTimeout(function () {
                        setoriginalPrice(0)
                        setProductForeignChargesItem([])
                        recalclulateOriginalValues();
                    }, 0)
                    getProductChargesByProdShipForeign();
                    recalclulateOriginalValues();
                }
            } else {
                window.setTimeout(function () {
                    setoriginalPrice(0)
                    setProductForeignChargesItem([])
                    setProductFreightChargesItem1([])
                    setProductLocalChargesItem1([])
                    setProductCustomChargesItem1([])
                    setproductCustomChargesItemOriginal([])
                    recalclulateOriginalValues();
                }, 0)
                getProductChargesByProdShipForeign();
                getProductChargesByProdShipFreight();
                getProductChargesByProdShipChargeLocal();
                getProductChargesByProdShipChargeCustome();
                recalclulateOriginalValues();
            }

        })
        

    }
    const [time, setTime] = useState(Date.now());

    useEffect(() => {
        getKgFactorsforSave(dispatch, admin, function (data) {
            setListOfKgFactor1(data.data);
        });
    }, []);
    useEffect(() => {
        if (costShipmentDetails == null || costShipmentDetails.length == 0) {
            setCostShipmentDetails(value.shipmentDetails)
        }
    }, [value]);
    useEffect(() => {
        recalclulateOriginalValues();
    }, [value.shipmentDetails]);
    const [zipsByCountry, setZipsByCountry] = useState([]);
    const [productLocalCharges1, setProductLocalCharges1] = useState([]);
    const [productFreightCharges1, setProductFreightCharges1] = useState([]);


    const [productFreightChargesItem1, setProductFreightChargesItem1] = useState([]);
    const [productFreightChargesItemOriginal, setproductFreightChargesItemOriginal] = useState([]);

    const [productLocalChargesItemOriginal, setproductLocalChargesItemOriginal] = useState([]);
    const [productLocalChargesItem1, setProductLocalChargesItem1] = useState([]);
    const [productCustomChargesItem1, setProductCustomChargesItem1] = useState([]);
    const [productCustomChargesItemOriginal, setproductCustomChargesItemOriginal] = useState([]);
    const [productCustomCharges2, setProductCustomCharges2] = useState([]);
    const [chargesType, setChargesType] = useState(0);
    const [quoteState, setQuoteState] = useState(1);
    const [selectedCur, setSelectedCur] = useState(1);
    var quoteState1 = 1
    const [costShipmentDetails, setCostShipmentDetails1] = useState([]);
    const setCostShipmentDetails = function (v) {
        setCostShipmentDetails1(v);
    }
    const getProductChargesByProdShipForeignOnload = function (shType, prtype) {

       // setbLoadingForeign(true)
        getProductChargesByProdShipCharge(dispatch, admin, prtype, shType, 3, function (data) {
            var items = []
            for (var i = 0; i < data.data.length; i++) {
                var t = {
                    id: data.data[i].id,
                    name: data.data[i].name,
                    min: data.data[i].min,
                    rate: data.data[i].rate,
                    remark: data.data[i].remark,
                }
                var units = [];
                for (var j = 0; j < data.data[i].units.length; j++) {
                    units.push(listOfMeasurementUnits.find(u => u.id === (data.data[i].units[j])))
                }
                t.units = units;
                var currencies = [];
                for (var j = 0; j < data.data[i].currencies.length; j++) {
                    currencies.push(listOfcurrency.find(u => u.id === (data.data[i].currencies[j])))
                }
                t.currencies = currencies;
                var selectedUnit = t.units[0]

                var sU = { unitOfMeasure :false}
                for (var k = 0; k < t.units.length; k++) {
                    if (sU.unitOfMeasure) {
                        if (t.units[k].id == sU.unitOfMeasure) {
                            selectedUnit = t.units[k];
                        }
                    } else {
                       // selectedUnit = t.units[k];
                    }
                }
                t.selectedUnit = selectedUnit;
                var selectedCurrency = t.currencies[0];
                var sU = { currency :false}
                for (var k = 0; k < t.currencies.length; k++) {
                    if (sU.currency) {
                        if (t.currencies[k].id == sU.currency) {
                            selectedCurrency = t.currencies[k];
                        }
                    } else {
                        selectedCurrency = t.currencies[0];
                        break;
                    }
                }
                t.selectedCurrency = selectedCurrency;
                items.push(t);

            }
            setProductForeignCharges(items)
            
        });
    }
    const getProductChargesByProdShipForeignEdit = function (shType, prtype) {

       // setbLoadingForeign(true)
        getProductChargesByProdShipCharge(dispatch, admin, prtype, shType, 3, function (data) {
            var items = []
            for (var i = 0; i < data.data.length; i++) {
                var t = {
                    id: data.data[i].id,
                    name: data.data[i].name,
                    min: data.data[i].min,
                    rate: data.data[i].rate,
                    remark: data.data[i].remark,
                }
                var units = [];
                for (var j = 0; j < data.data[i].units.length; j++) {
                    units.push(listOfMeasurementUnits.find(u => u.id === (data.data[i].units[j])))
                }
                t.units = units;
                var currencies = [];
                for (var j = 0; j < data.data[i].currencies.length; j++) {
                    currencies.push(listOfcurrency.find(u => u.id === (data.data[i].currencies[j])))
                }
                t.currencies = currencies;
                var selectedUnit = t.units[0]

                var sU = { unitOfMeasure :false}
                for (var k = 0; k < t.units.length; k++) {
                    if (sU.unitOfMeasure) {
                        if (t.units[k].id == sU.unitOfMeasure) {
                            selectedUnit = t.units[k];
                        }
                    } else {
                       // selectedUnit = t.units[k];
                    }
                }
                t.selectedUnit = selectedUnit;
                var selectedCurrency = t.currencies[0];
                var sU = { currency :false}
                for (var k = 0; k < t.currencies.length; k++) {
                    if (sU.currency) {
                        if (t.currencies[k].id == sU.currency) {
                            selectedCurrency = t.currencies[k];
                        }
                    } else {
                        selectedCurrency = t.currencies[0];
                        break;
                    }
                }
                t.selectedCurrency = selectedCurrency;
                items.push(t);

            }
            setProductForeignCharges(items)
            var fcItems = []
            if (value.foreignChargesItem)
                fcItems = [...value.foreignChargesItem]

            if (fcItems.length) {
                var fcCharges = items
                fcItems.forEach(item => {
                    fcCharges.forEach(item1 => {
                        if (!item.selectedItem)
                            item.selectedItem = item

                        if (item1.id == item.selectedItem.id) {
                            item1.rate = item.selectedItem.rate
                            item1.min = item.selectedItem.min
                            item1.rate20 = item.selectedItem.rate20
                            item1.rate40 = item.selectedItem.rate40
                            item.selectedItem = item1;
                            if (item.selectedUnit)
                                item.selectedUnit = item1.units.find(u => u.id === item.selectedUnit.id);
                            else
                                item.selectedUnit = item1.units[0]

                            if (item.selectedCurrency)
                                item.selectedCurrency = item1.currencies.find(u => u.id === item.selectedCurrency.id);
                            else
                                item.selectedUnit = item1.currencies[0]

                            item1.selectedItem = item;
                            if (item.selectedUnit)
                                item1.selectedUnit = item1.units.find(u => u.id === item.selectedUnit.id);
                            else
                                item1.selectedUnit = item1.units[0]

                            if (item.selectedCurrency)
                                item1.selectedCurrency = item1.currencies.find(u => u.id === item.selectedCurrency.id);
                            else
                                item1.selectedUnit = item1.currencies[0]
                        } else {
                            item1.selectedItem = item1;
                            item1.selectedUnit = item1.units[0];
                            item1.selectedCurrency = item1.currencies[0];
                        }
                    });
                    item.items = fcCharges;
                });
                setProductForeignCharges(fcCharges)

            }

            var txCircular = [];
            for (var txCirInd = 0; txCirInd < fcItems.length; txCirInd++) {
                txCircular.push(fcItems[txCirInd]);
            }
            setProductForeignChargesItem(txCircular);
            var stringiFCharges = [];
            for (var sI = 0; sI < fcItems.length; sI++) {
                stringiFCharges.push(fcItems[sI])
            }


            setproductForeignChargesItemOriginal(stringiFCharges);
        });
    }
    const getProductChargesByProdShipForeign = function () {

        setChargesType(3);

        if (value.shipmentType) {
            tempShip = value.shipmentType
        }
        setbLoadingForeign(true)
        getProductChargesByProdShipCharge(dispatch, admin, value.productType, tempShip, 3, setForeignChargesInQuotation);
    }
    const getProductChargesByProdShipForeignCharges1 = function () {
        setChargesType(3);

        if (value.shipmentType) {
            tempShip = value.shipmentType
        }
        getProductChargesByProdShipCharge(dispatch, admin, value.productType, tempShip, 3, function (data) {
            var items = []
            var crm = {}
            if (value.shipmentType == 1) {
                crm = crmList.find(u => u.id === selectedQuoatationCost1.crmId)
            } else if (value.shipmentType == 2) {
                crm = selectedQuoatationCost1;
            }
            var airImportCharges = [];
            if (crm && crm.functionId == 2) {
                if (value.shipmentType == 1) {
                    airImportCharges = crm.charges.AIR_IMPORT;
                } else if (value.shipmentType == 2) {
                    airImportCharges = crm.charges.LCL_IMPORT;
                }
            }
            var getFromCrmCharges = function (name) {
                for (var i = 0; i < airImportCharges.length; i++) {
                    if (airImportCharges[i].name == name || airImportCharges[i].product == name) {
                        return airImportCharges[i];
                    }
                }
                return false;
            }
            for (var i = 0; i < data.data.length; i++) {
                var t = {
                    id: data.data[i].id,
                    name: data.data[i].name,
                    min: data.data[i].min,
                    rate: data.data[i].rate,
                    remark: data.data[i].remark,
                }
                var units = [];
                for (var j = 0; j < data.data[i].units.length; j++) {
                    units.push(listOfMeasurementUnits.find(u => u.id === (data.data[i].units[j])))
                }
                t.units = units;
                var currencies = [];
                for (var j = 0; j < data.data[i].currencies.length; j++) {
                    currencies.push(listOfcurrency.find(u => u.id === (data.data[i].currencies[j])))
                }
                t.currencies = currencies;
                var selectedUnit = t.units[0]

                var sU = getFromCrmCharges(data.data[i].name);
                for (var k = 0; k < t.units.length; k++) {
                    if (sU.unitOfMeasure) {
                        if (t.units[k].id == sU.unitOfMeasure) {
                            selectedUnit = t.units[k];
                        }
                    } else {
                        selectedUnit = t.units[k];
                    }
                }
                t.selectedUnit = selectedUnit;
                var selectedCurrency = t.currencies[0];
                var sU = getFromCrmCharges(data.data[i].name);
                for (var k = 0; k < t.currencies.length; k++) {
                    if (sU.currency) {
                        if (t.currencies[k].id == sU.currency) {
                            selectedCurrency = t.currencies[k];
                        }
                    } else {
                        selectedCurrency = t.currencies[0];
                        break;
                    }
                }
                t.selectedCurrency = selectedCurrency;
                if (Number(t.min) != 0 && Number(t.rate) != 0) {
                    items.push(t);
                }
            }
            setProductForeignCharges(items)

            if (value.foreignChargesItem.length) {
                value.foreignChargesItem.forEach(item => {
                    item.items = items;
                });
            }
        });
    }


    const getProductChargesByProdShipChargeCustome = function () {

        if (value.shipmentType) {
            tempShip = value.shipmentType
        }
        setbLoadingCustom(true)
        getProductChargesByProdShipCharge(dispatch, admin, value.productType, tempShip, 4, function (data) {
            setbLoadingCustom(false)

            var crm = crmList.find(u => u.id === value.cutomerCrmId)
            var productCustomChargesItem = [];
            var updateCustomChargesItem = function (item) {
                item.bOrgValue = true;
                item.orgValue = item.selectedItem.rate;
                item.orgValue20 = item.selectedItem.rate20;
                item.orgValue40 = item.selectedItem.rate40;
                item.remark = item.remark ? item.remark : item.selectedItem?.remark;
                if (value.shipmentType == 2) {
                    //if (item?.selectedUnit?.id == 3) {
                    //    item.selectedItem.min = 1;
                    //}
                }
                var bUpdate = false;
                for (var i = 0; i < productCustomChargesItem.length; i++) {
                    if (item.selectedItem.name == productCustomChargesItem[i].selectedItem.name) {
                        bUpdate = true;
                        productCustomChargesItem[i] = item;
                        break;
                    }
                }
                item.index = productCustomChargesItem.length;
                if (!bUpdate) {
                    productCustomChargesItem.push(item);
                }
            }
            var airImportCharges = data.data;
            var airImportCharges1 = [];
            if (value.productType == 2 && value.shipmentType == 1) {
                airImportCharges1 = crm?.charges?.CUSTOM_AIR_IMPORT;
            }
            if (value.productType == 1 && value.shipmentType == 1) {
                //airImportCharges1 = crm?.charges?.CUSTOM_AIR_EXPORT;
                airImportCharges1 = crm?.charges?.CUSTOM_AIR_IMPORT;
            }
            if (value.productType == 2 && value.shipmentType == 2) {
                //airImportCharges1 = crm?.charges?.CUSTOM_LCL_IMPORT;
                //use AIR custs for LCL
                airImportCharges1 = crm?.charges?.CUSTOM_AIR_IMPORT;
            }
            if (value.productType == 1 && value.shipmentType == 2) {
                //use AIR custs for LCL
                //  airImportCharges1 = crm?.charges?.CUSTOM_LCL_EXPORT;
                airImportCharges1 = crm?.charges?.CUSTOM_AIR_IMPORT;
            }
            if (value.productType == 2 && value.shipmentType == 3) {
                //airImportCharges1 = crm?.charges?.CUSTOM_FCL_IMPORT;
                //use AIR custs for FCL
                airImportCharges1 = crm?.charges?.CUSTOM_AIR_IMPORT;
            }
            if (value.productType == 1 && value.shipmentType == 3) {
                //airImportCharges1 = crm?.charges?.CUSTOM_FCL_EXPORT;
                //use AIR custs for FCL
                airImportCharges1 = crm?.charges?.CUSTOM_AIR_IMPORT;
            }

            /*if (crm && crm.charges && crm.functionId == 1) {
                airImportCharges1 = crm.charges.CUSTOM_CHARGES;
            } else if (crm && crm.functionId == 2) {
                airImportCharges1 = [];
            }*/
            var getFromCustomerCharges = function (name) {
                for (var i = 0; i < airImportCharges1.length; i++) {
                    if (airImportCharges1[i].name == name || airImportCharges1[i].product == name) {
                        return airImportCharges1[i];
                    }
                }
                return false;
            }
            var temp = 0;
            var productCustomCharges = [];
            //productCustomChargesItem = [];
            for (var i = 0; i < airImportCharges.length; i++) {
                var t = {
                    id: airImportCharges[i].id,
                    name: airImportCharges[i].name,
                    min: airImportCharges[i].min,
                    rate: airImportCharges[i].rate,
                    remark: airImportCharges[i].remark,
                }
                var units = [];
                for (var j = 0; j < data.data[i].units.length; j++) {
                    units.push(listOfMeasurementUnits.find(u => u.id === (data.data[i].units[j])))
                }
                t.units = units;
                if (units.length)
                    t.selectedUnit = units[0];
                var currencies = [];
                for (var j = 0; j < data.data[i].currencies.length; j++) {
                    currencies.push(listOfcurrency.find(u => u.id === (data.data[i].currencies[j])))
                }

                t.currencies = currencies;
                if (currencies.length)
                    t.selectedCurrency = currencies[0];
                var selectedUnit = t.selectedUnit
                var sU = getFromCustomerCharges(airImportCharges[i].name);
                for (var k = 0; k < t.units.length; k++) {
                    if (sU.unitOfMeasure) {
                        if (t.units[k].id == sU.unitOfMeasure) {
                            selectedUnit = t.units[k];
                        }
                    } else {
                        selectedUnit = t.units[k];
                    }
                }
                t.selectedUnit = selectedUnit;
                var selectedCurrency = t.currencies[0]
                var sU = getFromCustomerCharges(airImportCharges[i].name);
                for (var k = 0; k < t.currencies.length; k++) {
                    if (sU.currency) {
                        if (t.currencies[k].id == sU.currency) {
                            selectedCurrency = t.currencies[k];
                        }
                    } else {
                        selectedCurrency = t.currencies[0];
                        break;
                    }
                }
                t.selectedCurrency = selectedCurrency;
                productCustomCharges.push(t);
                if (t.name == 'Trucking') {
                    t.selectedUnit = t.units[1];
                }
                // custom charges from Agent
                //if (value.productType == 2 && value.shipmentType==2) {
                /*if (value.productType == 2) {
                    *//*if (crm && crm.functionId == 2 && countryList.find(u => u.id === crm.countryId)?.name == "Israel") {*//*
        if (crm && crm.functionId == 2) {
            *//*if (t.name == 'Formalities' || t.name == 'World Gate' || t.name == 'Insurance Handling' || t.name == 'Trucking') {
                
                t.rate = 10;
                t.min = 0;
                airImportCharges1.push(t);
            }*//*
                    airImportCharges1 = crm?.charges?.AGENT_CUSTOMS_CHARGES;
                }
            }*/
            }
            if (value.productType != 1 /*&& value.shipmentType != 3*/) {
                for (var i = temp; i < productCustomCharges.length; i++) {
                    for (var j = 0; j < airImportCharges1.length; j++) {
                        /*if (!airImportCharges1[j].rate) {
                            try { airImportCharges1[j].rate = 0 } catch (e) { console.log(e) }

                        }
                        if (!airImportCharges1[j].min) {
                            try { airImportCharges1[j].min = 0 } catch (e) { console.log(e) }
                        }*/
                        if (airImportCharges1[j].name == productCustomCharges[i].name && (Number(airImportCharges1[j].rate) != 0 || Number(airImportCharges1[j].min) != 0)) {
                            productCustomCharges[i].rate = airImportCharges1[j].rate;
                            productCustomCharges[i].min = airImportCharges1[j].min;
                            if (value.shipmentType == 3) {
                                productCustomCharges[i].rate20 = airImportCharges1[j].rate;
                                productCustomCharges[i].rate40 = airImportCharges1[j].rate;
                            }
                            updateCustomChargesItem({
                                index:j,
                                items: productCustomCharges,
                                selectedItem: productCustomCharges[i],
                                selectedUnit: productCustomCharges[i].selectedUnit,
                                selectedCurrency: productCustomCharges[i].selectedCurrency,
                                remark: "",
                            })

                        }
                    }

                }

            }
            if (value.shipmentType == 3 && false) {
                for (var i = temp; i < productCustomCharges.length; i++) {
                    for (var j = 0; j < airImportCharges1.length; j++) {
                        /*if (!airImportCharges1[j].rate20) {
                            airImportCharges1[j].rate20 = 0
                        }
                        if (!airImportCharges1[j].rate40) {
                            airImportCharges1[j].rate40 = 0
                        }
                        if (!airImportCharges1[j].min) {
                            airImportCharges1[j].min = 0
                        }*/
                        var bRate = false;
                        var bRate1 = false;
                        if (value.flc20 || value.flc40) {
                            // latter 
                            if (Number(airImportCharges1[j].rate20) != 0 && !isNaN(Number(airImportCharges1[j].rate20))) {
                                bRate = true;
                            }
                            if (Number(airImportCharges1[j].rate40) != 0 && !isNaN(Number(airImportCharges1[j].rate40))) {
                                bRate1 = true;
                            }
                        } else {
                            if (Number(airImportCharges1[j].rate40) != 0 && !isNaN(Number(airImportCharges1[j].rate40))) {
                                bRate = true;
                            }
                        }
                        if (airImportCharges1[j].name == productCustomCharges[i].name && (bRate || bRate1)) {
                            productCustomCharges[i].rate20 = airImportCharges1[j].rate20;
                            productCustomCharges[i].rate40 = airImportCharges1[j].rate40;
                            productCustomCharges[i].rate = airImportCharges1[j].rate;
                            productCustomCharges[i].min = airImportCharges1[j].min;
                            updateCustomChargesItem({
                                items: productCustomCharges,
                                selectedItem: productCustomCharges[i],
                                selectedUnit: productCustomCharges[i].selectedUnit,
                                selectedCurrency: productCustomCharges[i].selectedCurrency,
                            })

                        }
                    }

                }

            }
            setProductCustomCharges2(productCustomCharges)
            setProductCustomChargesItem1(productCustomChargesItem);



            setproductCustomChargesItemOriginal(JSON.parse(JSON.stringify(productCustomChargesItem)));
            //console.log("**************")
            //console.log(productCustomChargesItem)
            //productCustomChargesItemOriginal = [...productCustomChargesItem]
            //console.log("------productCustomChargesItem" + getTotalEsitmatedAirCustomeOriginale(productCustomChargesItem))
            originalPrice1 = originalPrice1 + getTotalEsitmatedAirCustomeOriginale(productCustomChargesItem)
            setoriginalPrice(Number(originalPrice1))

            //console.log(originalPrice1)


        });
    }
    const getProductChargesByProdShipChargeCustomeChargesOnLoad = function (shType,prType) {

        getProductChargesByProdShipCharge(dispatch, admin, prType, shType, 4, function (data) {

            var airImportCharges = data.data;

            
            var productCustomCharges = [];
            for (var i = 0; i < airImportCharges.length; i++) {
                
                var t = {
                    id: airImportCharges[i].id,
                    name: airImportCharges[i].name,
                    min: airImportCharges[i].min,
                    rate: airImportCharges[i].rate,
                    remark: airImportCharges[i].remark, 
                }
                var units = [];
                for (var j = 0; j < data.data[i].units.length; j++) {
                    units.push(listOfMeasurementUnits.find(u => u.id === (data.data[i].units[j])))
                }
                t.units = units;
                if (units.length) {
                    t.selectedUnit = units[0];
                }
                var currencies = [];
                for (var j = 0; j < data.data[i].currencies.length; j++) {
                    currencies.push(listOfcurrency.find(u => u.id === (data.data[i].currencies[j])))
                }

                t.currencies = currencies;
                if (currencies.length)
                    t.selectedCurrency = currencies[0];
                var selectedUnit = t.selectedUnit
                var sU = { unitOfMeasure: false };
                for (var k = 0; k < t.units.length; k++) {
                    if (sU.unitOfMeasure) {
                        if (t.units[k].id == sU.unitOfMeasure) {
                            selectedUnit = t.units[k];
                        }
                    } else {
                        selectedUnit = t.units[k];
                    }
                }
                t.selectedUnit = selectedUnit;
                var selectedCurrency = t.currencies[0]
                var sU = { currency: false };
                for (var k = 0; k < t.currencies.length; k++) {
                    if (sU.currency) {
                        if (t.currencies[k].id == sU.currency) {
                            selectedCurrency = t.currencies[k];
                        }
                    } else {
                        selectedCurrency = t.currencies[0];
                        break;
                    }
                }
                t.selectedCurrency = selectedCurrency;
                productCustomCharges.push(t);
            }
            setProductCustomCharges2(productCustomCharges)
        });

    }
    const getProductChargesByProdShipChargeCustomeChargesEdit = function (shType,prType) {

        getProductChargesByProdShipCharge(dispatch, admin, prType, shType, 4, function (data) {

            var airImportCharges = data.data;

            
            var productCustomCharges = [];
            for (var i = 0; i < airImportCharges.length; i++) {
                
                var t = {
                    id: airImportCharges[i].id,
                    name: airImportCharges[i].name,
                    min: airImportCharges[i].min,
                    rate: airImportCharges[i].rate,
                    remark: airImportCharges[i].remark, 
                }
                var units = [];
                for (var j = 0; j < data.data[i].units.length; j++) {
                    units.push(listOfMeasurementUnits.find(u => u.id === (data.data[i].units[j])))
                }
                t.units = units;
                if (units.length) {
                    t.selectedUnit = units[0];
                }
                var currencies = [];
                for (var j = 0; j < data.data[i].currencies.length; j++) {
                    currencies.push(listOfcurrency.find(u => u.id === (data.data[i].currencies[j])))
                }

                t.currencies = currencies;
                if (currencies.length)
                    t.selectedCurrency = currencies[0];
                var selectedUnit = t.selectedUnit
                var sU = { unitOfMeasure: false };
                for (var k = 0; k < t.units.length; k++) {
                    if (sU.unitOfMeasure) {
                        if (t.units[k].id == sU.unitOfMeasure) {
                            selectedUnit = t.units[k];
                        }
                    } else {
                        selectedUnit = t.units[k];
                    }
                }
                t.selectedUnit = selectedUnit;
                var selectedCurrency = t.currencies[0]
                var sU = { currency: false };
                for (var k = 0; k < t.currencies.length; k++) {
                    if (sU.currency) {
                        if (t.currencies[k].id == sU.currency) {
                            selectedCurrency = t.currencies[k];
                        }
                    } else {
                        selectedCurrency = t.currencies[0];
                        break;
                    }
                }
                t.selectedCurrency = selectedCurrency;
                productCustomCharges.push(t);
            }
            setProductCustomCharges2(productCustomCharges)


            var fcItems = []
            if (value?.customChargesItem)
                fcItems = [...value.customChargesItem]
            if (fcItems.length) {
                var fcCharges = productCustomCharges
                fcItems.forEach(item => {
                    if (!item.selectedItem)
                        item.selectedItem = item

                    fcCharges.forEach(item1 => {
                        if (item1.name == item.selectedItem.name) {
                            item1.rate = item.selectedItem.rate
                            item1.min = item.selectedItem.min
                            item1.rate20 = item.selectedItem.rate20
                            item1.rate40 = item.selectedItem.rate40
                            item.selectedItem = item1;
                            if (item.selectedUnit)
                                item.selectedUnit = item1.units.find(u => u.id === item.selectedUnit.id);
                            else
                                item.selectedUnit = item1.units[0]

                            if (item.selectedCurrency)
                                item.selectedCurrency = item1.currencies.find(u => u.id === item.selectedCurrency.id);
                            else
                                item.selectedUnit = item1.currencies[0]

                            item1.selectedItem = item1;
                            if (item.selectedUnit)
                                item1.selectedUnit = item1.units.find(u => u.id === item.selectedUnit.id);
                            else
                                item1.selectedUnit = item1.units[0]

                            if (item.selectedCurrency)
                                item1.selectedCurrency = item1.currencies.find(u => u.id === item.selectedCurrency.id);
                            else
                                item1.selectedUnit = item1.currencies[0]
                        } else {
                            item1.selectedItem = item1;
                            item1.selectedUnit = item1.units[0];
                            item1.selectedCurrency = item1.currencies[0];
                        }
                    });
                    item.items = fcCharges;
                });
                setProductCustomCharges2(fcCharges)
                setProductCustomChargesItem1(fcItems);
                var stringiFCharges = [];
                for (var sI = 0; sI < fcItems.length; sI++) {
                    stringiFCharges.push(fcItems[sI])
                }
                setproductCustomChargesItemOriginal(stringiFCharges);
            }

        });

    }
    const getProductChargesByProdShipChargeCustomeCharges = function () {
        if (value.shipmentType) {
            tempShip = value.shipmentType
        }
        getProductChargesByProdShipCharge(dispatch, admin, value.productType, tempShip, 4, function (data) {
            var crm = crmList.find(u => u.id === value.cutomerCrmId)
            var productCustomChargesItem = [];
            var updateCustomChargesItem = function (item) {
                if (value.shipmentType == 2) {
                    //if (item?.selectedUnit?.id == 3) {
                    //    item.selectedItem.min = 1;
                    //}
                }
                var bUpdate = false;
                for (var i = 0; i < productCustomChargesItem.length; i++) {
                    if (item.selectedItem.name == productCustomChargesItem[i].selectedItem.name) {
                        bUpdate = true;
                        productCustomChargesItem[i] = item;
                        break;
                    }
                }
                if (!bUpdate) {
                    productCustomChargesItem.push(item);
                }
            }
            var airImportCharges = data.data;
            var airImportCharges1 = [];
            if (crm && crm.charges && crm.functionId == 1) {
                airImportCharges1 = crm.charges.CUSTOM_CHARGES;
            } else if (crm && crm.functionId == 2) {
                airImportCharges1 = [];
            }
            var getFromCustomerCharges = function (name) {
                for (var i = 0; i < airImportCharges1.length; i++) {
                    if (airImportCharges1[i].name == name || airImportCharges1[i].product == name) {
                        return airImportCharges1[i];
                    }
                }
                return false;
            }
            var temp = 0;
            var productCustomCharges = [];
            //productCustomChargesItem = [];
            for (var i = 0; i < airImportCharges.length; i++) {
                var t = {
                    id: airImportCharges[i].id,
                    name: airImportCharges[i].name,
                    min: airImportCharges[i].min,
                    rate: airImportCharges[i].rate,
                    remark: airImportCharges[i].remark, 
                }
                var units = [];
                for (var j = 0; j < data.data[i].units.length; j++) {
                    units.push(listOfMeasurementUnits.find(u => u.id === (data.data[i].units[j])))
                }
                t.units = units;
                if (units.length)
                    t.selectedUnit = units[0];
                var currencies = [];
                for (var j = 0; j < data.data[i].currencies.length; j++) {
                    currencies.push(listOfcurrency.find(u => u.id === (data.data[i].currencies[j])))
                }

                t.currencies = currencies;
                if (currencies.length)
                    t.selectedCurrency = currencies[0];
                var selectedUnit = t.selectedUnit
                var sU = getFromCustomerCharges(airImportCharges[i].name);
                for (var k = 0; k < t.units.length; k++) {
                    if (sU.unitOfMeasure) {
                        if (t.units[k].id == sU.unitOfMeasure) {
                            selectedUnit = t.units[k];
                        }
                    } else {
                        selectedUnit = t.units[k];
                    }
                }
                t.selectedUnit = selectedUnit;
                var selectedCurrency = t.currencies[0]
                var sU = getFromCustomerCharges(airImportCharges[i].name);
                for (var k = 0; k < t.currencies.length; k++) {
                    if (sU.currency) {
                        if (t.currencies[k].id == sU.currency) {
                            selectedCurrency = t.currencies[k];
                        }
                    } else {
                        selectedCurrency = t.currencies[0];
                        break;
                    }
                }
                t.selectedCurrency = selectedCurrency;
                productCustomCharges.push(t);
                if (t.name == 'Trucking') {
                    t.selectedUnit = t.units[1];
                }
                if (crm && crm.functionId == 2 && countryList.find(u => u.id === crm.countryId)?.name == "Israel") {
                    if (t.name == 'Formalities' || t.name == 'World Gate' || t.name == 'Insurance Handling' || t.name == 'Trucking') {

                        t.rate = 10;
                        t.min = 0;
                        airImportCharges1.push(t);
                    }
                }
            }
            if (value.productType != 1) {
                for (var i = temp; i < productCustomCharges.length; i++) {
                    for (var j = 0; j < airImportCharges1.length; j++) {
                        if (airImportCharges1[j].name == productCustomCharges[i].name) {
                            productCustomCharges[i].rate = airImportCharges1[j].rate;
                            productCustomCharges[i].min = airImportCharges1[j].min;
                            updateCustomChargesItem({
                                items: productCustomCharges,
                                selectedItem: productCustomCharges[i],
                                selectedUnit: productCustomCharges[i].selectedUnit,
                                selectedCurrency: productCustomCharges[i].selectedCurrency,
                            })

                        }
                    }

                }

            }
            setProductCustomCharges2(productCustomCharges)
            if (value.customChargesItem.length) {
                value.customChargesItem.forEach(item => {
                    item.items = productCustomCharges;
                });
            }
        });
    }
    const checkForAllZero = function (locFromAgent) {
        var isAllZero = false;
        if (locFromAgent?.length) {
            for (var i = 0; i < locFromAgent?.length; i++) {
                if (locFromAgent[i].min && locFromAgent[i].rate) {
                    if (Number(locFromAgent[i].min) == 0 && Number(locFromAgent[i].rate) == 0) {
                        isAllZero = true;
                        //break;
                    } else {
                        isAllZero = false;
                        break;
                    }
                }
            }
        }
        return isAllZero
    }
    const getProductChargesByProdShipChargeLocal = function () {

        setChargesType(3);
        if (value.shipmentType) {
            tempShip = value.shipmentType
        }

        getProductChargesByProdShipCharge(dispatch, admin, value.productType, tempShip, 2, function (data) {
            if (!data?.data?.length) {
                return
            }

            var items = []
            var productLocalCharges = [];
            for (var i = 0; i < data.data.length; i++) {
                var t = {
                    id: data.data[i].id,
                    name: data.data[i].name,
                    min: data.data[i].min,
                    rate: data.data[i].rate,
                    remark: data.data[i].remark,
                }
                var units = [];
                for (var j = 0; j < data.data[i].units.length; j++) {
                    units.push(listOfMeasurementUnits.find(u => u.id === (data.data[i].units[j])))
                }
                t.units = units;
                if (units.length)
                    t.selectedUnit = units[0];
                var currencies = [];
                for (var j = 0; j < data.data[i].currencies.length; j++) {
                    currencies.push(listOfcurrency.find(u => u.id === (data.data[i].currencies[j])))
                }

                t.currencies = currencies;
                if (currencies.length)
                    t.selectedCurrency = currencies[0];
                productLocalCharges.push(t);

            }
            var importExportType = 5
            if (value.productType == 1) {
                importExportType = 4
            }
            if (value.productType == 2) {
                importExportType = 5
            }
            if (value.shipmentType) {
                tempShip = value.shipmentType
            }
            setProductLocalCharges1(productLocalCharges)
            var data1 = {
                "importExportType": importExportType,
                "shipmentTypeId": tempShip,
            }
            setbLoadingLocal(true)
            getLocalChargesByShipProd(dispatch, admin, data1, function (data) {
                setbLoadingLocal(false)
                var items = []
                var productLocalChargesItem = [];
                var updateLocalChargesItem = function (item) {
                    item.bOrgValue = true;
                    item.orgValue = item.selectedItem.rate;
                    item.orgValue20 = item.selectedItem.rate20;
                    item.orgValue40 = item.selectedItem.rate40;
                    item.remark = item.remark ? item.remark : item.selectedItem.remark;
                    item.rate = item.selectedItem.rate;
                    item.rate20 = item.selectedItem.rate20;
                    item.rate40 = item.selectedItem.rate40;
                    if (value.shipmentType == 2) {
                        //if (item?.selectedUnit?.id == 3) {
                        //    item.selectedItem.min = 1;
                        //}
                    }
                    var bUpdate = false;
                    for (var i = 0; i < productLocalChargesItem.length; i++) {
                        if (item.selectedItem.name == productLocalChargesItem[i].selectedItem.name) {
                            bUpdate = true;
                            bUpdate = true;
                            //productLocalChargesItem[i] = item;
                          
                            break;
                        }
                    }
                    item.index = productLocalChargesItem.length;
                    if (!bUpdate) {
                        productLocalChargesItem.push(item);
                    }
                }
                var truecking = productLocalCharges.find(u => u.name === 'Trucking');
                if (truecking && data.length > 0) {
                    var truckTemp = { "id": data.length, "importExportType": 5, "shipmentId": 1, "product": "Trucking", "unitOfMeasure": 1, "currency": 1, "rate": "10.0", "min": "02", "remark": "" }
                    //data.push(truckTemp);
                }
                var locFromAgent = [];
                //if ((value.productType == 2 || value.productType == 1) && (value.shipmentType == 1 || value.shipmentType == 2)) {
                if (true) {
                    //crm = crmList.find(u => u.id === selectedQuoatationCost1.crmId)
                    //crm = crmList.find(u => u.id === value.cutomerCrmId)
                    crm = listOfUpdatedCrm.find(u => u.id === value.cutomerCrmId)
                    if (!crm) {
                        crm = crmList.find(u => u.id === value.cutomerCrmId)
                    }
                    //console.log("p: " + value.productType + " s:" + value.shipmentType);
                    //console.log(crm)
                    if (value.productType == 1 && value.shipmentType == 1) {
                        //console.log("1")
                        /* var airName = listOfAirLine.find(u => u.id === selectedQuoatationCost1.airLineId)?.name;
                         var crm1 = crmList.find(u => u.companyName === airName);
                         //var crm1 = crmList.find(u => u.id === selectedQuoatationCost1.crmId)
                         var isZeroLength = true; 
                         if (crm1?.functionId == 4) {
                             locFromAgent = crm1?.charges?.AIRLINE_LOCAL_CHARGES_IMPORT;
                             isZeroLength = checkForAllZero(locFromAgent);
                             
                         }
                         if (isZeroLength || !locFromAgent) {
                             locFromAgent = crm?.charges?.LOCAL_AIR_IMPORT;
                         }*/

                        locFromAgent = crm?.charges?.LOCAL_AIR_EXPORT;

                    }
                    //console.log("2")
                    if (value.productType == 2 && value.shipmentType == 1) {
                        if (crm?.functionId == 2) {
                            locFromAgent = null;
                        } else {
                            locFromAgent = crm?.charges?.LOCAL_AIR_IMPORT;;
                        }
                        //
                    }
                    //console.log("locFromAgent")
                    //console.log(locFromAgent)
                    if (value.productType == 2 && value.shipmentType == 2) {
                        if (crm?.functionId == 2) {
                            locFromAgent = null;
                        } else {
                            locFromAgent = crm?.charges?.LOCAL_LCL_IMPORT;;
                        }
                        //locFromAgent = crm?.charges?.LOCAL_LCL_IMPORT;
                        var allZero = true;
                        for (var i = 0; i < locFromAgent?.length; i++) {
                            if (Number(locFromAgent[i].rate) || locFromAgent[i].min) {
                                allZero = false;
                                break;
                            }
                        }

                        if (!locFromAgent?.length || allZero) {
                            locFromAgent = selectedQuoatationCost1?.params?.LOCAL_CHARGES;
                        }
                        data = [];

                    }
                    if (value.productType == 1 && value.shipmentType == 2) {

                        locFromAgent = crm?.charges?.LOCAL_LCL_EXPORT;
                        var allZero = true;
                        for (var i = 0; i < locFromAgent?.length; i++) {
                            if (Number(locFromAgent[i].rate) || locFromAgent[i].min) {
                                allZero = false;
                                break;
                            }
                        }
                        if (!locFromAgent?.length || allZero) {
                            locFromAgent = selectedQuoatationCost1?.params?.LOCAL_CHARGES;
                        }
                        //data = [];
                    }
                    if (value.shipmentType == 3) {
                        if (value.productType == 1) {
                            locFromAgent = crm?.charges?.LOCAL_FCL_EXPORT

                            //locFromAgent = crm?.charges?.LOCAL_FCL_EXPORT;
                        } else {
                            data = [];
                            if (crm?.functionId == 2) {
                                locFromAgent = null;
                            } else {
                                locFromAgent = crm?.charges?.LOCAL_FCL_IMPORT;;
                            }

                            //locFromAgent = crm?.charges?.LOCAL_FCL_IMPORT;
                        }

                        var allZero = true;
                        for (var i = 0; i < locFromAgent?.length; i++) {
                            if (Number(locFromAgent[i].rate20) || locFromAgent[i].rate40) {
                                allZero = false;
                                break;
                            }
                        }

                        if (!locFromAgent?.length || allZero) {
                            locFromAgent = selectedQuoatationCost1?.params?.LOCAL_CHARGES;
                        }
                        var allZero = true;
                        for (var i = 0; i < locFromAgent?.length; i++) {
                            if (Number(locFromAgent[i].rate20) || locFromAgent[i].rate40) {
                                allZero = false;
                                break;
                            }
                        }
                        if (!locFromAgent?.length || allZero) {
                            locFromAgent = [];
                            for (var i = 0; i < data.length; i++) {
                                var tmp = { ...data[i] }
                                tmp.rate20 = tmp.rate;
                                tmp.rate40 = tmp.min;
                                tmp.rate = 0;
                                tmp.min = 0;
                                locFromAgent.push(tmp);
                            }
                        }
                    }
                    /*var iataFee = { ...data.find(u => u.product === 'IATA Fee') }
                    if (iataFee && locFromAgent) {
                        iataFee.name = 'IATA Fee';
                        locFromAgent = []
                        locFromAgent = [...crm?.charges?.LOCAL_AIR_IMPORT];
                        locFromAgent.push(iataFee)
                    } else {
                        locFromAgent = crm?.charges?.LOCAL_AIR_IMPORT
                    }*/
                } else if (value.productType == 2 && value.shipmentType == 2) {
                    crm = selectedQuoatationCost1
                    //console.log(crm)
                    locFromAgent = crm?.charges?.LOCAL_LCL_IMPORT
                    /*if (!locFromAgent) {
                        crm = crmList.find(u => u.id === selectedQuoatationCost1.crmId)
                        locFromAgent = crm?.charges?.LOCAL_LCL_IMPORT
                    }*/

                }
                var isAllZero = false;
                if (value.shipmentType != 3) {
                     isAllZero = true;
                    if (locFromAgent?.length) {
                        for (var i = 0; i < locFromAgent?.length; i++) {
                            if (locFromAgent[i].min || locFromAgent[i].rate) {
                                if (Number(locFromAgent[i].min) != 0 || Number(locFromAgent[i].rate) != 0) {
                                    isAllZero = false;
                                    break;
                                }
                            }
                        }
                    }
                }
                if (!locFromAgent?.length || isAllZero) {
                    locFromAgent = []

                    for (var i = 0; i < data.length; i++) {
                        if (!locFromAgent.find(u => u.name === data[i].product))
                            locFromAgent.push({ ...data[i], name: data[i].product })
                    }
                }
                var getLocalChargesFromAgent = function (name) {
                    if (locFromAgent) {
                        for (var j = 0; j < locFromAgent?.length; j++) {
                            var tObjFrom = { ...locFromAgent[j] }
                            if (!tObjFrom.min) {
                                tObjFrom.min = 0;
                            }
                            if (!tObjFrom.rate) {
                                tObjFrom.rate = 0;
                            }
                            if ((tObjFrom.name == name || tObjFrom.product == name) && (Number(tObjFrom.min) != 0 || Number(tObjFrom.rate) != 0)) {
                                return locFromAgent[j];
                            }
                        }
                    }
                    return false;
                }
                var getFclLocalChargesFromAgent = function (name) {
                    if (locFromAgent) {
                        for (var j = 0; j < locFromAgent?.length; j++) {
                            var tObjFrom = { ...locFromAgent[j] }
                            /*if (!tObjFrom.min) {
                                tObjFrom.min = 0;
                            }
                            if (!tObjFrom.rate) {
                                tObjFrom.rate = 0; 
                            }*/
                            var bRate = false;
                            var bRate1 = false;
                            if (value.flc20 || value.flc40) {
                                if (Number(tObjFrom.rate20) != 0 && !isNaN(Number(tObjFrom.rate20))) {
                                    bRate = true;

                                }
                                if (Number(tObjFrom.rate40) != 0 && !isNaN(Number(tObjFrom.rate40))) {
                                    bRate1 = true;
                                }
                            } else {
                                if (Number(tObjFrom.rate40) != 0 && !isNaN(Number(tObjFrom.rate40))) {
                                    bRate = true;
                                }
                            }
                            if ((tObjFrom.name == name || tObjFrom.product == name) && (bRate || bRate1)) {
                                return locFromAgent[j];
                            }
                        }
                    }
                    return false;
                }
                var productLocalChargesDummy = [...productLocalCharges]

                for (var j = 0; j < productLocalChargesDummy.length; j++) {

                    // for (var i = 0; i < data.length; i++) {
                    var tempLocObj = {}
                    var tempLocObj1 = {}
                    if (value.shipmentType == 3) {
                        tempLocObj1 = getFclLocalChargesFromAgent(productLocalChargesDummy[j].name)
                    } else {
                        tempLocObj1 = getLocalChargesFromAgent(productLocalChargesDummy[j].name);
                    }
                    if (tempLocObj1) {
                        tempLocObj = { ...tempLocObj1 }
                        tempLocObj.name = productLocalChargesDummy[j].name
                        /*if (!tempLocObj.min) {
                            tempLocObj.min = 0
                        }
                        if (!tempLocObj.rate) {
                            tempLocObj.rate = 0
                        }*/
                        //if (productLocalChargesDummy[j].name == tempLocObj.name && (Number(tempLocObj.min) != 0 || Number(tempLocObj.rate) != 0)) {

                        if (value.shipmentType == 3) {
                            productLocalChargesDummy[j].rate20 = tempLocObj.rate20;
                            productLocalChargesDummy[j].rate40 = tempLocObj.rate40;
                        } else {
                            productLocalChargesDummy[j].rate = tempLocObj.rate;
                        }
                        productLocalChargesDummy[j].min = tempLocObj.min;
                        productLocalChargesDummy[j].remark = tempLocObj.remark;
                        // tempLocObj.name = tempLocObj.product
                        var selectedItem1 = tempLocObj;
                        var selectedItem = productLocalChargesDummy[j];

                        

                        //selectedItem.rate = tempLocObj.rate
                        var selectedUnit = selectedItem.units[0];
                        for (var k = 0; k < selectedItem.units.length; k++) {
                            if (tempLocObj.unitOfMeasure == selectedItem.units[k].id) {
                                selectedUnit = selectedItem.units[k];
                            }
                        }
                        var selectedCurrency = selectedItem.currencies[0];
                        for (var k = 0; k < selectedItem.currencies.length; k++) {
                            if (tempLocObj.currency == selectedItem.currencies[k].id) {
                                selectedCurrency = selectedItem.currencies[k];
                            }
                        }
                        if (value.shipmentType == 2) {
                            //if (selectedUnit.id == 3) {
                            //    selectedItem.min = 1;
                            //}                            
                        }

                        //if (value.shipmentType == 1) {
                        updateLocalChargesItem({
                            index: j,
                            items: productLocalChargesDummy,
                            selectedItem: selectedItem,
                            selectedUnit: selectedUnit,
                            selectedCurrency: selectedCurrency,
                            remark: selectedItem.remark,
                        }
                        )
                        //}
                        //}
                        //}
                    }
                }

                if (value.productType == 2 && value.shipmentType == 1 && false) {

                    var crm = crmList.find(u => u.id === value.cutomerCrmId)
                    var locCharges = crm?.charges?.CLIENT_LOCAL_CHARGES;
                    if (!locCharges) {
                        locCharges = crm?.charges?.AGENT_LOCAL_CHARGES
                    }
                    if (!locCharges) {
                    }
                    if (locCharges) {
                        for (var j = 0; j < productLocalCharges.length; j++) {

                            for (var i = 0; i < locCharges.length; i++) {
                                if (productLocalCharges[j].name == locCharges[i].name && (Number(locCharges[i].min) != 0 || Number(locCharges[i].rate) != 0)) {
                                    productLocalCharges[j].rate = locCharges[i].rate;
                                    productLocalCharges[j].min = locCharges[i].min;
                                    productLocalCharges[j].remark = locCharges[i].remark;
                                    var selectedItem = productLocalCharges[j];
                                    var selectedUnit = selectedItem.units[0];
                                    for (var k = 0; k < selectedItem.units.length; k++) {
                                        if (locCharges[i].unitOfMeasure == selectedItem.units[k].id) {
                                            selectedUnit = selectedItem.units[k];
                                        }
                                    }
                                    var selectedCurrency = selectedItem.currencies[0];
                                    for (var k = 0; k < selectedItem.currencies.length; k++) {
                                        if (locCharges[i].currency == selectedItem.currencies[k].id) {
                                            selectedCurrency = selectedItem.currencies[k];
                                        }
                                    }
                                    if (value.shipmentType == 2) {
                                        //if (selectedUnit.id == 3) {
                                        //    selectedItem.min = 1;
                                        //}
                                    }

                                    if (value.shipmentType == 1) {
                                        updateLocalChargesItem({
                                            items: productLocalCharges,
                                            selectedItem: selectedItem,
                                            selectedUnit: selectedUnit,
                                            selectedCurrency: selectedCurrency,
                                        }
                                        )
                                    }
                                }
                            }
                        }
                    }
                }





                if (value.productType == 1 || value.productType == 2) {
                    var tPlItem = productLocalChargesDummy.find(u => u.name === 'Trucking');
                    var tplList = [];
                    /*for (var tplInd = 0; tplInd < productLocalChargesItem.length; tplInd++) {
                        if (productLocalChargesItem[tplInd].selectedItem.name != 'Trucking') {
                            tplList.push(productLocalChargesDummy[tplInd]);
                        }
                    };*/
                    //console.log(listOfAirPort)
                    if (tPlItem) {
                        function getRateMinFromCost(cost) {
                            var rate = 0;
                            var min = 0;
                            if (cost && cost?.weightList) {
                                var ind = 0;
                                if (getTotalCostSum() <= cost.weightList[0]) {
                                    ind = 0;
                                } else if (getTotalCostSum() > cost.weightList[cost.weightList.length - 1]) {
                                    ind = cost.weightList.length - 1;

                                } else {
                                    for (var i = 0; i < cost.weightList.length - 1; i++) {
                                        if (getTotalCostSum() > cost.weightList[i] && getTotalCostSum() <= cost.weightList[i + 1]) {
                                            ind = i + 1;
                                            break;
                                        }
                                    }
                                }
                                rate = cost.rateList[ind];
                                min = cost.min;
                            }
                            return {
                                min: min,
                                rate: rate
                            }
                        }
                        var cost = false
                        var rate = 0;
                        var rate20 = 0;
                        var rate40 = 0;
                        var min = 0;
                        if (value.shipmentType == 2) {
                            cost = getLclCostByzip(value.zip, value.from, getRateMinFromCost);
                            rate = getRateMinFromCost(cost).rate;
                            min = getRateMinFromCost(cost).min;
                            tPlItem.rate = rate;
                            tPlItem.min = min;
                            tPlItem.remark = "Trucking Company: " + (cost?.params?.TRUCKER_NAME ? cost?.params?.TRUCKER_NAME : "");
                        } else if (value.shipmentType == 1) {
                            //var countryZip = zipsByCountry.find(u => u.id === value?.countryAirZipId);
                            var countryZip = value?.countryAirZipId;
                            var zip = value.zip
                            if (value.cdi == 2) {
                                zip = countryZip;
                            }
                            if (value.finalCountryId == 2) {
                                zip = value.zip
                            }
                            var airId = value.productType == 2 ? value.to : value.from
                            var finalCountryId = value.productType == 2 ? value.cdi : value.finalCountryId

                            if (value.productType == 2) {
                                zip = countryZip;
                                cost = getCostByZip(zip, value.to, value.cdi, getRateMinFromCost);
                            } else {
                                cost = getCostByZip(zip, value.from, value.finalCountryId, getRateMinFromCost);
                            }
                            
                            rate = getRateMinFromCost(cost).rate;
                            min = getRateMinFromCost(cost).min;
                            tPlItem.rate = rate;
                            tPlItem.min = min;
                            tPlItem.remark = "Trucking Company: " + (cost?.params?.TRUCKER_NAME ? cost?.params?.TRUCKER_NAME : "");
                        } else if (value.shipmentType == 3) {
                            cost = getFclCostByZip(value.zip, getRateMinFromCost);
                            if (cost) {
                                rate20 = cost?.params?.TRUCKING_CAHRGES?.length ? cost?.params?.TRUCKING_CAHRGES[0]?.rate20 : 0
                                rate40 = cost?.params?.TRUCKING_CAHRGES?.length ? cost?.params?.TRUCKING_CAHRGES[0]?.rate40 : 0
                                tPlItem.rate20 = rate20;
                                tPlItem.rate40 = rate40;
                                tPlItem.remark = "Trucking Company: " + (cost?.params?.TRUCKER_NAME ? cost?.params?.TRUCKER_NAME : "");
                            }

                        }

                        //tPlItem.items = productLocalChargesDummy;

                        // updateLocalChargesItem(tPlItem)

                        var selectedItem = tPlItem;
                        if (value.shipmentType == 1) {
                            //items[i].unitOfMeasure = 1;
                            selectedItem.selectedUnit = selectedItem.units?.find(u => u.id === 2)
                        } else if (value.shipmentType == 3) {
                            //items[i].unitOfMeasure = 4;
                            selectedItem.selectedUnit = selectedItem.units?.find(u => u.id === 4)
                        } else {
                            //items[i].unitOfMeasure = 2;
                            selectedItem.selectedUnit = selectedItem.units?.find(u => u.id === 2)
                        }
                        selectedItem.selectedCurrency = selectedItem.currencies[3];
                        if (rate || min || rate20 || rate40) {
                            updateLocalChargesItem({
                                items: productLocalChargesDummy,
                                selectedItem: selectedItem,
                                selectedUnit: tPlItem.selectedUnit,
                                selectedCurrency: selectedItem.currencies[3],
                            })
                        }
                        //tplList.push(tPlItem)                        
                    }
                    setProductLocalChargesItem1(productLocalChargesItem)
                    setproductLocalChargesItemOriginal(JSON.parse(JSON.stringify(productLocalChargesItem)))
                    if (productLocalChargesItem.length > 0) {
                        //productLocalChargesItemOriginal = [...tplList]
                        //console.log("------tplList" + getTotalEsitmatedAirLocalOriginal(productLocalChargesItem))
                        originalPrice1 = originalPrice1 + getTotalEsitmatedAirLocalOriginal(productLocalChargesItem)
                        setoriginalPrice(Number(originalPrice1));

                        //console.log(originalPrice1)
                    }
                } else {
                    setProductLocalChargesItem1(productLocalChargesItem)
                    setproductLocalChargesItemOriginal(JSON.parse(JSON.stringify(productLocalChargesItem)))
                    if (productLocalChargesItem.length > 4) {
                        // productLocalChargesItemOriginal = [...productLocalChargesItem]
                        originalPrice1 = originalPrice1 + getTotalEsitmatedAirLocalOriginal(productLocalChargesItem)
                        setoriginalPrice(Number(originalPrice1));


                    }
                }

                //}, 0);

            })
        });
    }
    const getProductChargesByProdShipChargeLocalCharges = function () {
        setChargesType(3);
        if (value.shipmentType) {
            tempShip = value.shipmentType
        }
        getProductChargesByProdShipCharge(dispatch, admin, value.productType, tempShip, 2, function (data) {
            var items = []
            var productLocalCharges = [];
            for (var i = 0; i < data.data.length; i++) {
                var t = {
                    id: data.data[i].id,
                    name: data.data[i].name,
                    min: data.data[i].min,
                    rate: data.data[i].rate,
                    remark: data.data[i].remark,
                }
                var units = [];
                for (var j = 0; j < data.data[i].units.length; j++) {
                    units.push(listOfMeasurementUnits.find(u => u.id === (data.data[i].units[j])))
                }
                t.units = units;
                if (units.length)
                    t.selectedUnit = units[0];
                var currencies = [];
                for (var j = 0; j < data.data[i].currencies.length; j++) {
                    currencies.push(listOfcurrency.find(u => u.id === (data.data[i].currencies[j])))
                }

                t.currencies = currencies;
                if (currencies.length)
                    t.selectedCurrency = currencies[0];
                productLocalCharges.push(t);

            }
            var importExportType = 5
            if (value.productType == 1) {
                importExportType = 4
            }
            if (value.productType == 2) {
                importExportType = 5
            }
            if (value.shipmentType) {
                tempShip = value.shipmentType
            }
            setProductLocalCharges1(productLocalCharges)
            if (value.localChargesItem.length) {
                value.localChargesItem.forEach(item => {
                    item.items = productLocalCharges;
                });
            }

        });
    }
    const getAirLineFrieghtChargesExportByName = function (name) {
        var t = [
            { name: 'Inland Freight', unitOfMeasure: 2, currency: 1, rate: 0, min: 0 },
            { name: 'Airfreight', unitOfMeasure: 1, currency: 2, rate: 0, min: 0 },
            { name: 'AMS', unitOfMeasure: 2, currency: 1, rate: 0, min: 0 },
            { name: 'Airline Surcharges', unitOfMeasure: 1, currency: 2, rate: 0, min: 0 },
            { name: 'IMO', unitOfMeasure: 2, currency: 2, rate: 0, min: 0 },
            { name: 'Airline DGR fee', unitOfMeasure: 2, currency: 1, rate: 0, min: 0 },
        ]

        return t.find(u => u.name === name);
    }
    
    const getExportFclCharge = function (name) {        

        return [...fclCostFrieghtChargesExport, { name: "Ocean Freight", unitOfMeasure: 4, currency: 1, weight: 0, rate40: 0, rate20: 0, min: 0, remark: "", validDate: new Date() }].find(u => u.name === name);
    }




    const getProductChargesByProdShipChargeOnLoad = function (shType, prType) {
        getProductChargesByProdShipCharge(dispatch, admin, prType, shType, 2, function (data) {
            if (!data?.data?.length) {
                return
            }

            var items = []
            var productLocalCharges = [];
            for (var i = 0; i < data.data.length; i++) {
                var t = {
                    id: data.data[i].id,
                    name: data.data[i].name,
                    min: data.data[i].min,
                    rate: data.data[i].rate,
                    remark: data.data[i].remark,
                }
                var units = [];
                for (var j = 0; j < data.data[i].units.length; j++) {
                    units.push(listOfMeasurementUnits.find(u => u.id === (data.data[i].units[j])))
                }
                t.units = units;
                if (units.length) {
                    t.selectedUnit = units[0];
                }
                
                var currencies = [];
                for (var j = 0; j < data.data[i].currencies.length; j++) {
                    currencies.push(listOfcurrency.find(u => u.id === (data.data[i].currencies[j])))
                }

                t.currencies = currencies;
                if (currencies.length)
                    t.selectedCurrency = currencies[0];
                productLocalCharges.push(t);

            }
            setProductLocalCharges1(productLocalCharges)
        });
    }


    const getProductChargesByProdShipChargeEdit = function (shType, prType) {
        getProductChargesByProdShipCharge(dispatch, admin, prType, shType, 2, function (data) {
            if (!data?.data?.length) {
                return
            }

            var items = []
            var productLocalCharges = [];
            for (var i = 0; i < data.data.length; i++) {
                var t = {
                    id: data.data[i].id,
                    name: data.data[i].name,
                    min: data.data[i].min,
                    rate: data.data[i].rate,
                    remark: data.data[i].remark,
                }
                var units = [];
                for (var j = 0; j < data.data[i].units.length; j++) {
                    units.push(listOfMeasurementUnits.find(u => u.id === (data.data[i].units[j])))
                }
                t.units = units;
                if (units.length) {
                    t.selectedUnit = units[0];
                }
                
                var currencies = [];
                for (var j = 0; j < data.data[i].currencies.length; j++) {
                    currencies.push(listOfcurrency.find(u => u.id === (data.data[i].currencies[j])))
                }

                t.currencies = currencies;
                if (currencies.length)
                    t.selectedCurrency = currencies[0];
                productLocalCharges.push(t);

            }
            setProductLocalCharges1(productLocalCharges)

            var fcItems = []
            if (value.localChargesItem)
                fcItems = [...value.localChargesItem]
            if (fcItems.length) {
                var fcCharges = value.localChargesItem[0].items ? [...value.localChargesItem[0].items] : productLocalCharges
                fcItems.forEach(item => {
                    if (!item.selectedItem) {
                        item.selectedItem = item
                    }
                    item.selectedItem.rate = Number(item.selectedItem.rate)

                    fcCharges.forEach(item1 => {
                        if (item1.id == item.selectedItem.id) {
                            item1.rate = item.selectedItem.rate
                            item1.rate20 = item.selectedItem.rate20
                            item1.rate40 = item.selectedItem.rate40
                            item1.min = item.selectedItem.min
                            item.selectedItem = item1;
                            if (item.selectedUnit)
                                item.selectedUnit = item1.units.find(u => u.id === item.selectedUnit.id);
                            else
                                item.selectedUnit = item1.units[0]

                            if (item.selectedCurrency)
                                item.selectedCurrency = item1.currencies.find(u => u.id === item.selectedCurrency.id);
                            else
                                item.selectedUnit = item1.currencies[0]

                            item1.selectedItem = item1;
                            if (item.selectedUnit)
                                item1.selectedUnit = item1.units.find(u => u.id === item.selectedUnit.id);
                            else
                                item1.selectedUnit = item1.units[0]

                            if (item.selectedCurrency)
                                item1.selectedCurrency = item1.currencies.find(u => u.id === item.selectedCurrency.id);
                            else
                                item1.selectedUnit = item1.currencies[0]
                        } else {
                            item1.selectedItem = item1;
                            item1.selectedUnit = item1.units[0];
                            item1.selectedCurrency = item1.currencies[0];
                        }
                    });
                    item.items = fcCharges;
                });
                setProductLocalCharges1(fcCharges)

            }
            setProductLocalChargesItem1(fcItems);




        });
    }

    const getProductChargesByProdShipFreightOnLoad = function (shType,prType) {

        //setbLoadingFrieght(true) ppp
        getProductChargesByProdShipCharge(dispatch, admin, prType, shType, 1, function (data) {
            //setbLoadingFrieght(false)
            var productFreightCharges = [];
            
            //setForeignChargesInQuotation(data)
            for (var i = 0; i < data.data.length; i++) {
                var t = {
                    id: data.data[i].id,
                    name: data.data[i].name,
                    min: data.data[i].min,
                    rate: data.data[i].rate,
                    remark: data.data[i].remark,
                }
                var units = [];
                for (var j = 0; j < data.data[i].units.length; j++) {
                    units.push(listOfMeasurementUnits.find(u => u.id === (data.data[i].units[j])))
                }
                t.units = units;
                if (units.length)
                    t.selectedUnit = units[0];


                if (value.productType == 1 && value.shipmentType == 3) {
                    //getExportFclCharge
                    var fclCh = getExportFclCharge(t.name);
                    if (fclCh) {
                        t.selectedUnit = listOfMeasurementUnits.find(u => u.id === (fclCh.unitOfMeasure))
                    }
                }
                var currencies = [];
                for (var j = 0; j < data.data[i].currencies.length; j++) {
                    currencies.push(listOfcurrency.find(u => u.id === (data.data[i].currencies[j])))
                }

                t.currencies = currencies;
                if (currencies.length)
                    t.selectedCurrency = currencies[0];
                if (data.data[i].chargesTypes.includes(1)) {
                    productFreightCharges.push(t);
                }
            }
            setProductFreightCharges1(productFreightCharges)
        });
    }

    
    const getProductChargesByProdShipFreightEdit = function (shType,prType) {

        //setbLoadingFrieght(true) ppp
        getProductChargesByProdShipCharge(dispatch, admin, prType, shType, 1, function (data) {
            //setbLoadingFrieght(false)
            var productFreightCharges = [];
            
            //setForeignChargesInQuotation(data)
            for (var i = 0; i < data.data.length; i++) {
                var t = {
                    id: data.data[i].id,
                    name: data.data[i].name,
                    min: data.data[i].min,
                    rate: data.data[i].rate,
                    remark: data.data[i].remark,
                }
                var units = [];
                for (var j = 0; j < data.data[i].units.length; j++) {
                    units.push(listOfMeasurementUnits.find(u => u.id === (data.data[i].units[j])))
                }
                t.units = units;
                if (units.length)
                    t.selectedUnit = units[0];


                if (value.productType == 1 && value.shipmentType == 3) {
                    //getExportFclCharge
                    var fclCh = getExportFclCharge(t.name);
                    if (fclCh) {
                        t.selectedUnit = listOfMeasurementUnits.find(u => u.id === (fclCh.unitOfMeasure))
                    }
                }
                var currencies = [];
                for (var j = 0; j < data.data[i].currencies.length; j++) {
                    currencies.push(listOfcurrency.find(u => u.id === (data.data[i].currencies[j])))
                }

                t.currencies = currencies;
                if (currencies.length)
                    t.selectedCurrency = currencies[0];
                if (data.data[i].chargesTypes.includes(1)) {
                    productFreightCharges.push(t);
                }
            }
            setProductFreightCharges1(productFreightCharges)

            var fcItems = []
            if (value.freightChargesItem)
                fcItems = [...value.freightChargesItem]
            if (fcItems.length) {
                var fcCharges = productFreightCharges
                fcItems.forEach(item => {
                    fcCharges.forEach(item1 => {
                        if (!item.selectedItem)
                            item.selectedItem = item

                        if (item1.id == item.selectedItem.id) {
                            item1.rate = item.selectedItem.rate
                            item1.min = item.selectedItem.min
                            item1.rate20 = item.selectedItem.rate20
                            item1.rate40 = item.selectedItem.rate40
                            item.selectedItem = item1;
                            if (item.selectedUnit) {
                                item.selectedUnit = item1.units.find(u => u.id === item.selectedUnit.id);
                            } else {
                                item.selectedUnit = item1.units[0]
                            }

                            if (item.selectedCurrency) {
                                item.selectedCurrency = item1.currencies.find(u => u.id === item.selectedCurrency.id);
                            } else {
                                item.selectedUnit = item1.currencies[0]
                            }
                            item1.selectedItem = item1;
                            if (item.selectedUnit) {
                                item1.selectedUnit = item1.units.find(u => u.id === item.selectedUnit.id);
                            } else {
                                item1.selectedUnit = item1.units[0]
                            }
                            if (item.selectedCurrency) {
                                item1.selectedCurrency = item1.currencies.find(u => u.id === item.selectedCurrency.id);
                            } else {
                                item1.selectedUnit = item1.currencies[0]
                            }
                        } else {
                            item1.selectedItem = item1;
                            item1.selectedUnit = item1.units[0];
                            item1.selectedCurrency = item1.currencies[0];
                        }
                    });
                    item.items = fcCharges;
                });
                setProductFreightCharges1(fcCharges)
            }
            setProductFreightChargesItem1(fcItems);
            var stringiFCharges = [];
            for (var sI = 0; sI < fcItems.length; sI++) {
                stringiFCharges.push(fcItems[sI])
            }
            setproductFreightChargesItemOriginal(stringiFCharges);

        });
    }








    const getProductChargesByProdShipFreight = function () {

        setChargesType(1);
        if (value.shipmentType) {
            tempShip = value.shipmentType
        }
        setbLoadingFrieght(true)
        getProductChargesByProdShipCharge(dispatch, admin, value.productType, tempShip, 1, function (data) {
            setbLoadingFrieght(false)
            var productFreightCharges = [];
            setProductFreightChargesItem1([])
            setproductFreightChargesItemOriginal([])
            var productFreightChargesItem = [];
            var updateFreightChargesItem = function (item) {
                item.bOrgValue = true;
                item.orgValue = item.selectedItem.rate;
                item.orgValue20 = item.selectedItem.rate20;
                item.orgValue40 = item.selectedItem.rate40;
                item.remark = item.remark ? item.remark : item.selectedItem?.remark;

                /*if (value.shipmentType == 2) {
                    if (item.selectedUnit.id == 3) {
                        item.selectedItem.min = 1;
                    }
                }*/


                var bUpdate = false;
                for (var i = 0; i < productFreightChargesItem.length; i++) {
                    if (item.selectedItem.name == productFreightChargesItem[i].selectedItem.name) {
                        bUpdate = true;
                        if (!item.selectedItem.rate) {
                            item = productFreightChargesItem[i];
                        }
                        productFreightChargesItem[i] = item;
                        break;
                    }
                }
                if (!bUpdate) {
                    if (!item.selectedItem.rate) {
                        item.selectedItem.rate = 0;
                    }
                    if (!item.selectedItem.min) {
                        item.selectedItem.min = 0;
                    }
                    item.index = productFreightChargesItem.length;
                    productFreightChargesItem.push(item);
                }
            }
            //setForeignChargesInQuotation(data)
            for (var i = 0; i < data.data.length; i++) {
                var t = {
                    id: data.data[i].id,
                    name: data.data[i].name,
                    min: data.data[i].min,
                    rate: data.data[i].rate,
                    remark: data.data[i].remark,
                }
                var units = [];
                for (var j = 0; j < data.data[i].units.length; j++) {
                    units.push(listOfMeasurementUnits.find(u => u.id === (data.data[i].units[j])))
                }
                t.units = units;
                if (units.length)
                    t.selectedUnit = units[0];
                var currencies = [];
                for (var j = 0; j < data.data[i].currencies.length; j++) {
                    currencies.push(listOfcurrency.find(u => u.id === (data.data[i].currencies[j])))
                }

                t.currencies = currencies;
                if (currencies.length)
                    t.selectedCurrency = currencies[0];
                if (data.data[i].chargesTypes.includes(1)) {
                    productFreightCharges.push(t);
                }

            }
            var tempData = selectedQuoatationCost1?.params?.FRIEGHT_CAHRGES;
            for (var i = 0; i < tempData?.length; i++) {
                var t = {
                    id: tempData[i].id,
                    name: tempData[i].name,
                    min: tempData[i].min,
                    rate: tempData[i].rate,
                    remark: tempData[i].remark,
                }
                t.units = listOfMeasurementUnits;
                if (units?.length)
                    t.selectedUnit = units[0];

                t.currencies = listOfcurrency;
                if (currencies?.length)
                    t.selectedCurrency = currencies[0];

                var exists = false;
                for (var j = 0; j < productFreightCharges.length; j++) {
                    if (t.name === productFreightCharges[j].name) {
                        exists = true;
                    }
                }
                if (!t.id) {
                    t.id = 10000 + i;
                }
                if (!exists)
                    productFreightCharges.push(t);

            }
            setProductFreightCharges1(productFreightCharges)
            var selectedItem = productFreightCharges[0];
            // if (value.productType == 2) {
            for (var i = 0; i < productFreightCharges.length; i++) {
                var crm = {}
                //if (value.shipmentType == 2) {                        
                var frightCh = []



                if (value.shipmentType == 2) {
                    crm = crmList.find(u => u.id === selectedQuoatationCost1.crmId)
                    if (value.productType == 1) {
                        frightCh = crm?.charges?.COLODER_EXPORT_FRIEGHT_CHARGES
                    } else {
                        frightCh = crm?.charges?.COLODER_FRIEGHT_CHARGES
                    }
                    // from costs 
                    frightCh = selectedQuoatationCost1?.params?.FRIEGHT_CAHRGES

                }
                if (value.shipmentType == 3) {
                    //locFromAgent = crm?.charges?.LOCAL_LCL_EXPORT;
                    frightCh = selectedQuoatationCost1?.params?.FRIEGHT_CAHRGES;
                    data = [];
                }
                if (value.shipmentType == 1) {

                    if (value.productType == 1) {
                        //var airName = listOfAirLine.find(u => u.id === selectedQuoatationCost1.airLineId)?.name;
                        // crm = crmList.find(u => u.companyName === airName);
                        //frightCh = crm?.charges?.AIRLINE_FRIGHT_CHARGES_EXPORT
                        frightCh = selectedQuoatationCost1.params?.AIR_EXP_FRIEGHT
                    } else {
                        // var airName = listOfAirLine.find(u => u.id === selectedQuoatationCost1.airLineId)?.name;
                        // crm = crmList.find(u => u.companyName === airName);
                        //frightCh = crm?.charges?.AIRLINE_FRIGHT_CHARGES_IMPORT
                        frightCh = selectedQuoatationCost1.params?.AIR_IMP_FRIEGHT
                    }
                }


                var isAllZero = false;
                if (value.shipmentType != 3) {
                    if (frightCh?.length) {
                        for (var j = 0; j < frightCh.length; j++) {
                            if (frightCh[j].min && frightCh[j].rate) {
                                if (Number(frightCh[j].min) == 0 && Number(frightCh[j].rate) == 0) {
                                    isAllZero = true;
                                    //break;
                                } else {
                                    isAllZero = false;
                                    break;
                                }
                            }
                        }
                    }
                } else {
                    if (frightCh?.length) {
                        for (var j = 0; j < frightCh.length; j++) {
                            var bRate = false;
                            var bRate1 = false;
                            var rate = 0;
                            if (value.flc20 || value.flc40) {
                                if (Number(frightCh[j].rate20) != 0 && !isNaN(Number(frightCh[j].rate20))) {
                                    bRate = true;
                                    rate = frightCh[j].rate20;
                                }
                                if (Number(frightCh[j].rate40) != 0 && !isNaN(Number(frightCh[j].rate40))) {
                                    bRate1 = true;
                                    rate = frightCh[j].rate40;
                                }
                            } else {
                                if (Number(frightCh[j].rate40) != 0 && !isNaN(Number(frightCh[j].rate40))) {
                                    bRate = true;
                                    rate = frightCh[j].rate40;
                                }
                            }
                            if ((bRate || bRate1)) {
                                if (Number(rate) == 0) {
                                    isAllZero = true;
                                    //break;
                                } else {
                                    isAllZero = false;
                                    break;
                                }
                            }
                        }
                    }


                }
                if (isAllZero || !frightCh?.length) {
                    /*if ((productFreightCharges[i].name == 'Airfrieght' || productFreightCharges[i].name == 'Inland Freight' || productFreightCharges[i].name == 'IMO' || productFreightCharges[i].name == 'Airline Surcharges') && value.shipmentType == 1 ) {
                        if (selectedQuoatationCost1.quoteRate < selectedQuoatationCost1.rate) {
                            // productFreightCharges[i].rate = selectedQuoatationCost1.rate;
                        } else {
                            //  productFreightCharges[i].rate = selectedQuoatationCost1.quoteRate;
                        }
                        // productFreightCharges[i].rate = selectedQuoatationCost1.rate;
                        //productFreightCharges[i].min = selectedQuoatationCost1.min;
                        var ch = getAirLineFrieghtChargesExportByName(productFreightCharges[i].name)
                        //console.log(productFreightCharges[i].name)
                        //console.log(productFreightCharges[i])
                        //console.log(ch)
                        selectedItem = productFreightCharges[i];
                        updateFreightChargesItem({
                            items: productFreightCharges,
                            selectedItem: selectedItem,
                            selectedUnit: ch ? selectedItem.units?.find(u => u.id === ch?.unitOfMeasure) : selectedItem.units[0],
                            selectedCurrency: ch ? selectedItem.currencies?.find(u => u.id === ch?.currency) : selectedItem.currencies[0],
                        })
                        //break;
                    }*/
                    var Airfrieght = { name: "Airfreight", unitOfMeasure: 1, currency: 1, weight: 0, rate: 0, min: 0, remark: "", validDate: new Date() }

                    if ((productFreightCharges[i].name == Airfrieght.name) && value.shipmentType == 1 && (Number(selectedQuoatationCost1.rate) || Number(selectedQuoatationCost1.min))) {
                        if (selectedQuoatationCost1.quoteRate < selectedQuoatationCost1.rate) {
                            // productFreightCharges[i].rate = selectedQuoatationCost1.rate;
                        } else {
                            //  productFreightCharges[i].rate = selectedQuoatationCost1.quoteRate;
                        }


                        productFreightCharges[i].rate = selectedQuoatationCost1.rate;
                        productFreightCharges[i].min = selectedQuoatationCost1.min;
                        selectedItem = productFreightCharges[i];
                        var selunist = selectedQuoatationCost1?.params?.rateCurrency ? selectedQuoatationCost1.params.rateCurrency : Airfrieght.currency;
                        var unit = selectedItem.units.find(u => u.id === Airfrieght.unitOfMeasure)
                        var cur = selectedItem.currencies.find(u => u.id === selunist);

                        if (value.shipmentType == 2) {
                            //if (unit.id == 3) {
                            //    selectedItem.min = 1;
                            //}
                        }

                        updateFreightChargesItem({
                            index: i,
                            items: productFreightCharges,
                            selectedItem: selectedItem,
                            selectedUnit: unit ? unit : selectedItem.units[0],
                            selectedCurrency: cur ? cur : selectedItem.currencies[0],
                            remark: selectedQuoatationCost1.remark ? selectedQuoatationCost1.remark : "",
                        })
                        //break;
                    }

                    if (productFreightCharges[i].name == 'Ocean Freight' && value.shipmentType != 1) {
                        if (value.shipmentType == 3) {
                            productFreightCharges[i].rate20 = selectedQuoatationCost1.params?.OF_CAHRGES?.length ? selectedQuoatationCost1.params?.OF_CAHRGES[0]?.rate20 : 0
                            productFreightCharges[i].rate40 = selectedQuoatationCost1.params?.OF_CAHRGES?.length ? selectedQuoatationCost1.params?.OF_CAHRGES[0]?.rate40 : 0
                            var unitOfm = selectedQuoatationCost1.params?.OF_CAHRGES?.length ? selectedQuoatationCost1.params?.OF_CAHRGES[0]?.unitOfMeasure : 0
                            var cur = selectedQuoatationCost1.params?.OF_CAHRGES?.length ? selectedQuoatationCost1.params?.OF_CAHRGES[0]?.currency : 0

                            selectedItem = productFreightCharges[i];
                            if (Number(productFreightCharges[i].rate20) || Number(productFreightCharges[i].rate40)) {
                                updateFreightChargesItem({
                                    items: productFreightCharges,
                                    selectedItem: selectedItem,
                                    selectedUnit: unitOfm ? selectedItem.units.find(u => u.id === unitOfm) : selectedItem.units[0],
                                    selectedCurrency: cur ? selectedItem.currencies.find(u => u.id === cur) : selectedItem.currencies[0],
                                    remark: productFreightCharges[i].remark ? productFreightCharges[i].remark : "",
                                })
                            }
                        } else {
                            if (Number(selectedQuoatationCost1.rate) || Number(selectedQuoatationCost1.min)) {
                                productFreightCharges[i].rate = selectedQuoatationCost1.rate;
                                productFreightCharges[i].min = selectedQuoatationCost1.min;
                                selectedItem = productFreightCharges[i];
                                updateFreightChargesItem({
                                    items: productFreightCharges,
                                    selectedItem: selectedItem,
                                    selectedUnit: selectedItem.units[0],
                                    selectedCurrency: selectedItem.currencies[0],
                                    remark: productFreightCharges[i].remark ? productFreightCharges[i].remark : "",
                                })
                            }
                        }


                        //break;
                    }
                }

                if (frightCh && !isAllZero) {
                    //(value.shipmentType == 2 && value.productType==1)
                    if (productFreightCharges[i].name == 'Ocean Freight' && (value.shipmentType == 3 || (value.shipmentType == 2))) {

                        if (value.shipmentType == 3) {
                            productFreightCharges[i].rate20 = selectedQuoatationCost1.params?.OF_CAHRGES?.length ? selectedQuoatationCost1.params?.OF_CAHRGES[0]?.rate20 : 0
                            productFreightCharges[i].rate40 = selectedQuoatationCost1.params?.OF_CAHRGES?.length ? selectedQuoatationCost1.params?.OF_CAHRGES[0]?.rate40 : 0
                            var unitOfm = selectedQuoatationCost1.params?.OF_CAHRGES?.length ? selectedQuoatationCost1.params?.OF_CAHRGES[0]?.unitOfMeasure : 0
                            var cur = selectedQuoatationCost1.params?.OF_CAHRGES?.length ? selectedQuoatationCost1.params?.OF_CAHRGES[0]?.currency : 0

                            selectedItem = productFreightCharges[i];
                            if (Number(productFreightCharges[i].rate20) || Number(productFreightCharges[i].rate40)) {
                                updateFreightChargesItem({
                                    items: productFreightCharges,
                                    selectedItem: selectedItem,
                                    selectedUnit: unitOfm ? selectedItem.units.find(u => u.id === unitOfm) : selectedItem.units[0],
                                    selectedCurrency: cur ? selectedItem.currencies.find(u => u.id === cur) : selectedItem.currencies[0],
                                    remark: productFreightCharges[i].remark ? productFreightCharges[i].remark : "",
                                })
                            }
                        } else {
                            if (Number(selectedQuoatationCost1.rate) || Number(selectedQuoatationCost1.min)) {
                                productFreightCharges[i].rate = selectedQuoatationCost1.rate;
                                productFreightCharges[i].min = selectedQuoatationCost1.min;
                                selectedItem = productFreightCharges[i];
                                updateFreightChargesItem({
                                    items: productFreightCharges,
                                    selectedItem: selectedItem,
                                    selectedUnit: selectedItem.units[0],
                                    selectedCurrency: selectedItem.currencies[0],
                                    remark: productFreightCharges[i].remark ? productFreightCharges[i].rate : "",
                                })
                            }
                        }
                    }

                    var Airfrieght = { name: "Airfreight", unitOfMeasure: 1, currency: 1, weight: 0, rate: 0, min: 0, remark: "", validDate: new Date() }

                    if ((productFreightCharges[i].name == Airfrieght.name) && value.shipmentType == 1) {
                        if (selectedQuoatationCost1.quoteRate < selectedQuoatationCost1.rate) {
                            // productFreightCharges[i].rate = selectedQuoatationCost1.rate;
                        } else {
                            //  productFreightCharges[i].rate = selectedQuoatationCost1.quoteRate;
                        }


                        productFreightCharges[i].rate = selectedQuoatationCost1.rate;
                        productFreightCharges[i].min = selectedQuoatationCost1.min;
                        selectedItem = productFreightCharges[i];
                        var selunist = selectedQuoatationCost1.params.rateCurrency ? selectedQuoatationCost1.params.rateCurrency : Airfrieght.currency;
                        var unit = selectedItem.units.find(u => u.id === Airfrieght.unitOfMeasure)
                        var cur = selectedItem.currencies.find(u => u.id === selunist);
                        updateFreightChargesItem({
                            items: productFreightCharges,
                            selectedItem: selectedItem,
                            selectedUnit: unit ? unit : selectedItem.units[0],
                            selectedCurrency: cur ? cur : selectedItem.currencies[0],
                            remark: selectedQuoatationCost1.remark ? selectedQuoatationCost1.remark : "",
                        })
                        //break;
                    }

                    /*if (productFreightCharges[i].name == 'Ocean Freight' && (value.shipmentType == 3) ) {

                        if (value.shipmentType == 3) {
                            productFreightCharges[i].rate20 = selectedQuoatationCost1.params?.OF_CAHRGES?.length ? selectedQuoatationCost1.params?.OF_CAHRGES[0]?.rate20 : 0
                            productFreightCharges[i].rate40 = selectedQuoatationCost1.params?.OF_CAHRGES?.length ? selectedQuoatationCost1.params?.OF_CAHRGES[0]?.rate40 : 0
                            var unitOfm = selectedQuoatationCost1.params?.OF_CAHRGES?.length ? selectedQuoatationCost1.params?.OF_CAHRGES[0]?.unitOfMeasure : 0
                            var cur = selectedQuoatationCost1.params?.OF_CAHRGES?.length ? selectedQuoatationCost1.params?.OF_CAHRGES[0]?.currency : 0
                            
                            selectedItem = productFreightCharges[i];
                            updateFreightChargesItem({
                                items: productFreightCharges,
                                selectedItem: selectedItem,
                                selectedUnit: unitOfm ? selectedItem.units.find(u => u.id === unitOfm) : selectedItem.units[0],
                                selectedCurrency: cur ? selectedItem.currencies.find(u => u.id === cur) :selectedItem.currencies[0],
                            })
                        } else {
                            productFreightCharges[i].rate = selectedQuoatationCost1.rate;
                            productFreightCharges[i].min = selectedQuoatationCost1.min;
                            selectedItem = productFreightCharges[i];
                            updateFreightChargesItem({
                                items: productFreightCharges,
                                selectedItem: selectedItem,
                                selectedUnit: selectedItem.units[0],
                                selectedCurrency: selectedItem.currencies[0],
                            })
                        }
                    }*/

                    if (productFreightCharges[i].name == 'Trucking' && (value.shipmentType == 2) && (value.productType == 1) && false) {

                        productFreightCharges[i].rate = selectedQuoatationCost1.rate;
                        productFreightCharges[i].min = selectedQuoatationCost1.min;
                        selectedItem = productFreightCharges[i];
                        updateFreightChargesItem({
                            items: productFreightCharges,
                            selectedItem: selectedItem,
                            selectedUnit: selectedItem.units[1],
                            selectedCurrency: selectedItem.currencies[0],
                            remark: selectedQuoatationCost1.remark ? selectedQuoatationCost1.remark : "",
                        })
                    }









                    if (value.shipmentType != 3) {
                        for (var fInd = 0; fInd < frightCh.length; fInd++) {
                            if ((productFreightCharges[i].name == frightCh[fInd].name) && (Number(frightCh[fInd].min) != 0 || Number(frightCh[fInd].rate) != 0)) {
                                var selectedItem = productFreightCharges[i];
                                selectedItem.rate = frightCh[fInd].rate
                                selectedItem.min = frightCh[fInd].min
                                var selectedUnit = selectedItem.units.find(u => u.id === frightCh[fInd].unitOfMeasure);
                                var selectedCur = selectedItem.currencies.find(u => u.id === frightCh[fInd].currency);
                                updateFreightChargesItem({
                                    items: productFreightCharges,
                                    selectedItem: selectedItem,
                                    selectedUnit: selectedUnit ? selectedUnit : selectedItem.units[0],
                                    selectedCurrency: selectedCur ? selectedCur : selectedItem.currencies[0],
                                    remark: frightCh[fInd].remark ? frightCh[fInd].remark : "",
                                    /*selectedUnit: selectedItem.units[0],
                                    selectedCurrency: selectedItem.currencies[0],*/
                                })
                            }
                        }
                    } else {

                        for (var fInd = 0; fInd < frightCh.length; fInd++) {
                            var bRate = false;
                            var bRate1 = false;
                            if (value.flc20 || value.flc40) {
                                if (Number(frightCh[fInd].rate20) != 0 && !isNaN(Number(frightCh[fInd].rate20))) {
                                    bRate = true;
                                }
                                if (Number(frightCh[fInd].rate40) != 0 && !isNaN(Number(frightCh[fInd].rate40))) {
                                    bRate1 = true;
                                }
                            } else {
                                if (Number(frightCh[fInd].rate40) != 0 && !isNaN(Number(frightCh[fInd].rate40))) {
                                    bRate = true;
                                }
                            }
                            if ((productFreightCharges[i].name == frightCh[fInd].name && (bRate || bRate1))) {
                                var selectedItem = productFreightCharges[i];
                                selectedItem.rate20 = frightCh[fInd].rate20
                                selectedItem.rate40 = frightCh[fInd].rate40
                                selectedItem.min = frightCh[fInd].min
                                updateFreightChargesItem({
                                    items: productFreightCharges,
                                    selectedItem: selectedItem,
                                    selectedUnit: selectedItem.units.find(u => u.id === frightCh[fInd].unitOfMeasure),
                                    selectedCurrency: selectedItem.currencies.find(u => u.id === frightCh[fInd].currency),
                                    remark: frightCh[fInd].remark ? frightCh[fInd].remark : "",
                                })
                            }
                        }

                    }
                }
                //}


            }
            //}
            if (value.productType == 1 && false) {
                for (var i = 0; i < productFreightCharges.length; i++) {

                    var crm = {}
                    if (value.shipmentType == 1) {
                        var airName = listOfAirLine.find(u => u.id === selectedQuoatationCost1.airLineId)?.name;

                        crm = crmList.find(u => u.companyName === airName)
                        //crm = selectedQuoatationCost1;
                    }
                    var frightCh = crm?.charges?.AIRLINE_FRIGHT_CHARGES_EXPORT
                    if (value.shipmentType == 2) {
                        crm = crmList.find(u => u.id === selectedQuoatationCost1.crmId)
                        crm = crmList.find(u => u.id === selectedQuoatationCost1.crmId)
                        if (value.productType == 1) {
                            frightCh = crm?.charges?.COLODER_EXPORT_FRIEGHT_CHARGES
                        } else {
                            frightCh = crm?.charges?.COLODER_FRIEGHT_CHARGES
                        }


                    }
                    if (frightCh) {
                        for (var fInd = 0; fInd < frightCh.length; fInd++) {
                            if (!frightCh[fInd].min) {
                                frightCh[fInd].min = 0;
                            }
                            if (!frightCh[fInd].rate) {
                                frightCh[fInd].rate = 0;
                            }
                            if ((productFreightCharges[i].name == frightCh[fInd].name && (Number(frightCh[fInd].min) != 0 || Number(frightCh[fInd].rate) != 0))) {
                                var selectedItem = productFreightCharges[i];
                                selectedItem.rate = frightCh[fInd].rate
                                selectedItem.min = frightCh[fInd].min
                                updateFreightChargesItem({
                                    items: productFreightCharges,
                                    selectedItem: selectedItem,
                                    selectedUnit: selectedItem.units[0],
                                    selectedCurrency: selectedItem.currencies[0],
                                    remark: frightCh[fInd].remark ? frightCh[fInd].remark : "",
                                })
                            }
                        }
                    }
                    if ((productFreightCharges[i].name == 'Airfreight') && value.shipmentType == 1) {
                        if (selectedQuoatationCost1.quoteRate < selectedQuoatationCost1.rate) {
                            // productFreightCharges[i].rate = selectedQuoatationCost1.rate;
                        } else {
                            //  productFreightCharges[i].rate = selectedQuoatationCost1.quoteRate;
                        }
                        // productFreightCharges[i].rate = selectedQuoatationCost1.rate;
                        //productFreightCharges[i].min = selectedQuoatationCost1.min;
                        selectedItem = productFreightCharges[i];
                        updateFreightChargesItem({
                            items: productFreightCharges,
                            selectedItem: selectedItem,
                            selectedUnit: selectedItem.units[0],
                            selectedCurrency: selectedItem.currencies[0],
                        })
                        //break;
                    }
                    if (productFreightCharges[i].name == 'Ocean Freight' && value.shipmentType == 2) {
                        productFreightCharges[i].rate = selectedQuoatationCost1.rate;
                        productFreightCharges[i].min = selectedQuoatationCost1.min;
                        selectedItem = productFreightCharges[i];
                        updateFreightChargesItem({
                            items: productFreightCharges,
                            selectedItem: selectedItem,
                            selectedUnit: selectedItem.units[0],
                            selectedCurrency: selectedItem.currencies[0],
                        })
                        // break;
                    }
                }
            }



            // if (tempShip != 3) {
            window.setTimeout(function () {
                setProductFreightChargesItem1(productFreightChargesItem)
            }, 2)

            setproductFreightChargesItemOriginal(JSON.parse(JSON.stringify(productFreightChargesItem)))
            //}


            //productFreightChargesItemOriginal = [...productFreightChargesItem]
            //console.log("****" + originalPrice)
            originalPrice1 = originalPrice1 + getTotalEsitmatedAirFreightOriginal(productFreightChargesItem)
            //console.log("****" + originalPrice1)
            setoriginalPrice(Number(originalPrice1));

            setbLoadingFrieght(false)
        });
    }
    const getProductChargesByProdShipFreightCharges = function () {

        setChargesType(1);
        if (value.shipmentType) {
            tempShip = value.shipmentType
        }
        getProductChargesByProdShipCharge(dispatch, admin, value.productType, tempShip, 1, function (data) {
            var productFreightCharges = [];

            //setForeignChargesInQuotation(data)
            for (var i = 0; i < data.data.length; i++) {
                var t = {
                    id: data.data[i].id,
                    name: data.data[i].name,
                    min: data.data[i].min,
                    rate: data.data[i].rate,
                    remark: data.data[i].remark,
                }
                var units = [];
                for (var j = 0; j < data.data[i].units.length; j++) {
                    units.push(listOfMeasurementUnits.find(u => u.id === (data.data[i].units[j])))
                }
                t.units = units;
                if (units.length)
                    t.selectedUnit = units[0];
                var currencies = [];
                for (var j = 0; j < data.data[i].currencies.length; j++) {
                    currencies.push(listOfcurrency.find(u => u.id === (data.data[i].currencies[j])))
                }

                t.currencies = currencies;
                if (currencies.length)
                    t.selectedCurrency = currencies[0];
                if (data.data[i].chargesTypes.includes(1)) {
                    productFreightCharges.push(t);
                }

            }
            setProductFreightCharges1(productFreightCharges)

            if (value.freightChargesItem.length) {
                value.freightChargesItem.forEach(item => {
                    item.items = productFreightCharges;
                });
            }
        });
    }
    const setForeignChargesInQuotation = function (data) {
        setbLoadingForeign(false)
        var items = []
        var tempFCI = []
        var tempFCI1 = []
        if (value.productType == 2 && value.shipmentType == 2) {
            // tempFCI = [...productForeignChargesItem]
            tempFCI = [...productForeignChargesItem]
            //tempFCI1 = [...productForeignChargesItem]
            /*tempFCI= tempFCI.filter(function (fciObj) {
                return (Number(fciObj.min)!=0||Number(fciObj.rate)!=0)
            });*/
        } else {
            setProductForeignChargesItem([]);
            setproductForeignChargesItemOriginal([]);
        }
        var crm = {}
        if (value.shipmentType == 1) {
            crm = listOfUpdatedCrm.find(u => u.id === selectedQuoatationCost1.crmId)
        } else if (value.shipmentType == 2) {
          //  crm = selectedQuoatationCost1;
            crm = listOfUpdatedCrm.find(u => u.id === selectedQuoatationCost1.id)
        }

        var airImportCharges = [];
        if (crm && crm.functionId == 2) {
            if (value.shipmentType == 1) {
                airImportCharges = crm.charges.AIR_IMPORT;
            } else if (value.shipmentType == 2) {
                airImportCharges = crm.charges.LCL_IMPORT;
            }
        }
        if (value.shipmentType == 2 && value.productType == 2) {
            if (crm?.charges?.LCL_IMPORT) {
                airImportCharges = crm?.charges?.LCL_IMPORT
            } else {
                crm = crmList.find(u => u.id === selectedQuoatationCost1.crmId)
                airImportCharges = crm?.charges?.FOREIGN_CHARGES
            }

        }
        if (value.shipmentType == 3) {
            crm = crmList.find(u => u.id === (selectedQuoatationCost1.agentCrmId || selectedQuoatationCost1.shippingCrmId))
            if (value.productType == 1) {
                airImportCharges = crm?.charges?.FCL_EXPORT
            } else {
                airImportCharges = crm?.charges?.FCL_IMPORT
            }

        }
        if (!airImportCharges) {
            airImportCharges = []
        }
        var getFromCrmCharges = function (name) {
            for (var i = 0; i < airImportCharges.length; i++) {
                if (airImportCharges[i].name == name || airImportCharges[i].product == name) {
                    return airImportCharges[i];
                }
            }
            return false;
        }
        for (var i = 0; i < data.data.length; i++) {
            var t = {
                id: data.data[i].id,
                name: data.data[i].name,
                min: data.data[i].min,
                rate: data.data[i].rate,
                remark: data.data[i].remark,
            }
            var units = [];
            for (var j = 0; j < data.data[i].units.length; j++) {
                units.push(listOfMeasurementUnits.find(u => u.id === (data.data[i].units[j])))
            }
            t.units = units;
            var currencies = [];
            for (var j = 0; j < data.data[i].currencies.length; j++) {
                currencies.push(listOfcurrency.find(u => u.id === (data.data[i].currencies[j])))
            }
            t.currencies = currencies;
            var selectedUnit = t.units[0]

            var sU = getFromCrmCharges(data.data[i].name);
            for (var k = 0; k < t.units.length; k++) {
                if (sU.unitOfMeasure) {
                    if (t.units[k].id == sU.unitOfMeasure) {
                        selectedUnit = t.units[k];
                    }
                } else {
                    selectedUnit = t.units[k];
                }
            }
            t.selectedUnit = selectedUnit;
            var selectedCurrency = t.currencies[0];
            var sU = getFromCrmCharges(data.data[i].name);
            for (var k = 0; k < t.currencies.length; k++) {
                if (sU.currency) {
                    if (t.currencies[k].id == sU.currency) {
                        selectedCurrency = t.currencies[k];
                    }
                } else {
                    selectedCurrency = t.currencies[0];
                    break;
                }
            }
            t.selectedCurrency = selectedCurrency;
            items.push(t);

        }
        setProductForeignCharges(items)
        function getRateMinFromCost(cost) {
            var rate = 0;
            var min = 0;
            if (cost?.weightList?.length) {
                var ind = 0;
                if (getTotalCostSum() <= cost.weightList[0]) {
                    ind = 0;
                } else if (getTotalCostSum() > cost.weightList[cost.weightList.length - 1]) {
                    ind = cost.weightList.length - 1;

                } else {
                    for (var i = 0; i < cost.weightList.length - 1; i++) {
                        if (getTotalCostSum() > cost.weightList[i] && getTotalCostSum() <= cost.weightList[i + 1]) {
                            ind = i + 1;
                            break;
                        }
                    }
                }

                /*for (var j = cost.weightList.length - 1; j >= 0; j--) {
                    if (getTotalCostSum() > cost.weightList[j]) {
                        ind = j;
                        break;
                    }
    
                }*/
                rate = cost.rateList[ind];
                min = cost.min;
            }
            return {
                min: min,
                rate: rate
            }
        }

        var cost = false
        var remarks = false
        var rate = 0;
        var rate20 = 0;
        var rate40 = 0;
        var min = 0;
        if (value.shipmentType == 2) {
            cost = getLclCostByzip(value.zip, value.from, getRateMinFromCost);
            rate = getRateMinFromCost(cost).rate;
            min = getRateMinFromCost(cost).min;
            remarks = "Trucking Company: " + (cost?.params?.TRUCKER_NAME ? cost?.params?.TRUCKER_NAME:"");
        } else if (value.shipmentType == 1) {
            var airId = value.from;
            var finalCountryId = value.finalCountryId;
            var airId = value.productType == 2 ? value.to : value.from
            var finalCountryId = value.productType == 2 ? value.cdi : value.finalCountryId
            //var countryZip = zipsByCountry.find(u => u.id === value?.countryAirZipId);
            var countryZip = value?.countryAirZipId;
            var zip = value.zip;
            if (value.productType == 1) {
                zip = countryZip;
                cost = getCostByZip(zip, value.to, value.cdi, getRateMinFromCost);
            } else {
                cost = getCostByZip(zip, value.from, value.finalCountryId, getRateMinFromCost);
            }
           
            rate = getRateMinFromCost(cost).rate;
            min = getRateMinFromCost(cost).min;
            remarks = "Trucking Company: " + (cost?.params?.TRUCKER_NAME ? cost?.params?.TRUCKER_NAME : "");
        } else if (value.shipmentType == 3) {
            cost = getFclCostByZip(value.zip, getRateMinFromCost);
            remarks = "Trucking Company: " + (cost?.params?.TRUCKER_NAME ? cost?.params?.TRUCKER_NAME : "");
            if (cost) {
                rate20 = cost?.params?.TRUCKING_CAHRGES?.length ? cost?.params?.TRUCKING_CAHRGES[0]?.rate20 : 0
                rate40 = cost?.params?.TRUCKING_CAHRGES?.length ? cost?.params?.TRUCKING_CAHRGES[0]?.rate40 : 0
            }

        }


        var selectedItem = items[0];
        for (var i = 0; i < items.length; i++) {
            if (items[i].name == 'Trucking') {
                selectedItem = items[i];
                selectedItem.remark = remarks;
                if (value.shipmentType == 1) {
                    //items[i].unitOfMeasure = 1;
                    selectedItem.selectedUnit = selectedItem.units?.find(u => u.id === 2)
                } else if (value.shipmentType == 3) {
                    //items[i].unitOfMeasure = 4;
                    selectedItem.selectedUnit = selectedItem.units?.find(u => u.id === 4)
                } else {
                    //items[i].unitOfMeasure = 2;
                    selectedItem.selectedUnit = selectedItem.units?.find(u => u.id === 2)
                }

                if (value.shipmentType != 3) {
                    if (rate && min) {
                        selectedItem.rate = rate;
                        selectedItem.min = min;
                    }
                } else {
                    if (rate20) {
                        selectedItem.rate20 = rate20;
                    }
                    if (rate40) {
                        selectedItem.rate40 = rate40;
                    }
                }
                break;
            }
        }

        var item1 = {
            items: items,
            selectedItem: selectedItem,
            selectedUnit: selectedItem.selectedUnit,
            selectedCurrency: selectedItem.selectedCurrency,
            remark: selectedItem.remark,
        }
        //if (value.productType == 2 || value.shipmentType==3) {
        if (value.productType == 2 || value.productType == 1) {

            var updateForeignChargesItem = function (item) {
                item.bOrgValue = true;
                item.orgValue = item.selectedItem.rate;
                item.orgValue20 = item.selectedItem.rate20;
                item.orgValue40 = item.selectedItem.rate40;
                item.remark = item.remark ? item.remark : item.selectedItem.remark;

                if (value.shipmentType == 2) {
                    //if (item?.selectedUnit?.id == 3) {
                    //    item.selectedItem.min = 1;
                    //}
                }

                if (value.shipmentType != 3) {

                    var bUpdate = false;
                    for (var i = 0; i < tempFCI1.length; i++) {
                        if (item.selectedItem.name == tempFCI1[i].selectedItem.name) {
                            bUpdate = true;
                            tempFCI1[i] = item;
                            break;
                        }
                    }
                    if (!bUpdate && (Number(item.selectedItem.min) != 0 || Number(item.selectedItem.rate) != 0) && (!isNaN(Number(item.selectedItem.min)) && !isNaN(Number(item.selectedItem.rate)))) {
                        item.index = tempFCI1.length;
                        tempFCI1.push(item);
                    }
                } else {
                    var bUpdate = false;
                    for (var i = 0; i < tempFCI1.length; i++) {
                        if (item.selectedItem.name == tempFCI1[i].selectedItem.name) {
                            bUpdate = true;
                            tempFCI1[i] = item;
                            break;
                        }
                    }
                    var bRate = false;
                    var bRate1 = false;
                    if (value.flc20 || value.flc40) {
                        if (Number(item.selectedItem.rate20) != 0 && !isNaN(Number(item.selectedItem.rate20))) {
                            bRate = true;
                        }
                        if (Number(item.selectedItem.rate40) != 0 && !isNaN(Number(item.selectedItem.rate40))) {
                            bRate1 = true;
                        }
                    } else {
                        if (Number(item.selectedItem.rate40) != 0 && !isNaN(Number(item.selectedItem.rate40))) {
                            bRate = true;
                        }
                    }


                    if (!bUpdate && ((bRate || bRate1))) {
                        item.index = tempFCI1.length;
                        tempFCI1.push(item);
                    }
                }
            }
            if (rate || rate20 || rate40) {
                updateForeignChargesItem(item1);
            }

            var tF = []
            items.forEach(element => {
                var tObj = JSON.parse(JSON.stringify(element));
                tF.push(tObj);
            });

            var selectedAirImportCharges = [];
            for (var i = 0; i < tF.length; i++) {
                for (var j = 0; j < airImportCharges.length; j++) {
                    if (airImportCharges[j].name == tF[i].name) {
                        //if (airImportCharges[j].name == tF[i].name && (Number(airImportCharges[j].min) != 0 || Number(airImportCharges[j].rate) != 0)) {
                        tF[i].rate = airImportCharges[j].rate;
                        tF[i].min = airImportCharges[j].min;
                        if (value.shipmentType == 3) {
                            tF[i].rate20 = airImportCharges[j].rate20;
                            tF[i].rate40 = airImportCharges[j].rate40;
                        }
                        var item = {
                            items: tF,
                            selectedItem: tF[i],
                            selectedUnit: tF[i].units.find(u => u.id === tF[i].selectedUnit.id),
                            selectedCurrency: tF[i].currencies.find(u => u.id === tF[i].selectedCurrency.id),
                            remark: airImportCharges[j].remarks ? airImportCharges[j].remarks : "",
                        }
                        updateForeignChargesItem(item);
                    }
                }
            }
            window.setTimeout(function () {
                var txCircular = [];
                for (var txCirInd = 0; txCirInd < tempFCI1.length; txCirInd++) {
                    txCircular.push(tempFCI1[txCirInd]);
                }
                setProductForeignChargesItem(txCircular);
                //setProductForeignChargesItem(JSON.parse(JSON.stringify(tempFCI1)));
                setproductForeignChargesItemOriginal(JSON.parse(JSON.stringify(tempFCI1)));
                //setproductForeignChargesItemOriginal(tempFCI1);
            }, 10)
        }
        window.setTimeout(function () {
            // productForeignChargesItemOriginal = [...tempFCI1]
            originalPrice1 = originalPrice1 + getTotalEsitmatedAirForeignOriginal(tempFCI1)
            setoriginalPrice(Number(originalPrice1))
        }, 10)

    }
    const getLclCostByzip = function (zip, pol, getRateMinFromCost) {
        var t = []
        var t1 = null;
        var t1rate = 0;
        listOfLclExportImportAFs.forEach(element => {
            var tObj = JSON.parse(JSON.stringify(element));
            t.push(tObj);
        });
        t.sort(function (a, b) {
            return (Number(b.updateTime) - Number(a.updateTime));
        })
        if (zip) {
            for (var i = 0; i < t.length; i++) {
                if (t1 == null) {
                    t1 = t[i];
                    var t2 = getRateMinFromCost(t[i]);
                    if (t2.rate) {
                        if (getTotalCostSum() > t2.min) {
                            t1rate = getTotalCostSum();
                        } else {
                            t1rate = t2.min;
                        }
                    } else {
                        t1rate = t2.min;
                    }

                }
                else {
                    var t2 = null;
                    try {
                        t2 = getRateMinFromCost(t[i]);
                    } catch (e) {
                        console.log(e);
                    }
                    var rate = 0;
                    if (t2?.rate) {
                        if (getTotalCostSum() > t2.min) {
                            rate = getTotalCostSum();
                        } else {
                            rate = t2.min;
                        }
                    } else {
                        rate = t2.min;
                    }
                    if (rate < t1rate) {
                        t1rate = rate;
                        t1 = t[i];
                    }
                }
            }
        }
        return false
    }

    const getFclCostByZip = function (zip, getRateMinFromCost) {
        var t = [];
        var t1 = null;
        var t1rate = 0;
        listOfFclExportImportAFs.forEach(element => {
            var tObj = JSON.parse(JSON.stringify(element));
            t.push(tObj);
        });
        t.sort(function (a, b) {
            return (Number(b.updateTime) - Number(a.updateTime));
        })
        if (zip) {
            for (var i = 0; i < t.length; i++) {
                if (Number(t[i].zip) == Number(zip) && t[i].importExportType == 3 && value.finalCountryId == t[i].countryId && value.from == t[i].portLid) {
                    if (t1 == null) {
                        t1 = t[i];
                        var t2 = getRateMinFromCost(t[i]);
                        if (t2.rate) {
                            if (getTotalCostSum() > t2.min) {
                                t1rate = getTotalCostSum();
                            } else {
                                t1rate = t2.min;
                            }
                        } else {
                            t1rate = t2.min;
                        }

                    }
                    else {
                        var t2 = getRateMinFromCost(t[i]);
                        var rate = 0;
                        if (t2.rate) {
                            if (getTotalCostSum() > t2.min) {
                                rate = getTotalCostSum();
                            } else {
                                rate = t2.min;
                            }
                        } else {
                            rate = t2.min;
                        }
                        if (rate < t1rate) {
                            t1rate = rate;
                            t1 = t[i];
                        }
                    }
                }
            }
        }

        return t1
    }
    const getCostByZip = function (zip, airId, finalCountryId, getRateMinFromCost) {
        var t = [];
        var t1 = null;
        var t1rate = 0;
        listOfAirExportAFs.forEach(element => {
            var tObj = JSON.parse(JSON.stringify(element));
            t.push(tObj);
        });
        t.sort(function (a, b) {
            return (Number(b.updateTime) - Number(a.updateTime));
        })
        if (zip) {
           
            for (var i = 0; i < t.length; i++) {
                if (Number(t[i].zip) == Number(zip) && t[i].importExportType == 3 && finalCountryId == t[i].countryId && airId == t[i].airportId) {
                    if (t1 == null) {
                        t1 = t[i];
                        var t2 = getRateMinFromCost(t[i]);
                        if (t2.rate) {
                            if (getTotalCostSum() > t2.min) {
                                t1rate = getTotalCostSum();
                            } else {
                                t1rate = t2.min;
                            }
                        } else {
                            t1rate = t2.min;
                        }

                    }
                    else {
                        var t2 = getRateMinFromCost(t[i]);
                        var rate = 0;
                        if (t2.rate) {
                            if (getTotalCostSum() > t2.min) {
                                rate = getTotalCostSum();
                            } else {
                                rate = t2.min;
                            }
                        } else {
                            rate = t2.min;
                        }
                        if (rate < t1rate) {
                            t1rate = rate;
                            t1 = t[i];
                        }
                    }
                }
            }
        }

        return t1
    }
    const setQuote = function (state, text) {

        setQuoteState(state)
    }
    const [productForeignChargesItemOriginal, setproductForeignChargesItemOriginal] = useState([]);
    const [productForeignChargesItem, setProductForeignChargesItem] = useState([]);
    const [productForeignCharges, setProductForeignCharges] = useState([]);

    const removeForeignCharge = function (index) {
        if (fcIndex == 0) {
            setFcIndex(productForeignChargesItem.length);
        }
        var t = []
        for (var i = 0; i < productForeignChargesItem.length; i++) {
            if (productForeignChargesItem[i].index != index)
                t.push(productForeignChargesItem[i])
        }
        //t.splice(ind, 1);
        (setProductForeignChargesItem(t))
    }
    const removeFreightCharge = function (index) {
        if (frcIndex == 0) {
            setFrcIndex(productFreightChargesItem1.length);
        }
        var t = []
        for (var i = 0; i < productFreightChargesItem1.length; i++) {
            if (productFreightChargesItem1[i].index != index)
                t.push(productFreightChargesItem1[i])
        }
        //t.splice(ind, 1);
        (setProductFreightChargesItem1(t))
    }
    const removeCustomCharge = function (index) {
        if (ccIndex == 0) {
            setCcIndex(productCustomChargesItem1.length);
        }
        var t = []
        for (var i = 0; i < productCustomChargesItem1.length; i++) {
            if (productCustomChargesItem1[i].index != index)
                t.push(productCustomChargesItem1[i])
        }
        //t.splice(ind, 1);
        (setProductCustomChargesItem1(t))
    }
    const removeLocalCharge = function (index) {
        if (lcIndex == 0) {
            setLcIndex(productLocalChargesItem1.length);
        }
        var t = []
        for (var i = 0; i < productLocalChargesItem1.length; i++) {
            if (productLocalChargesItem1[i].index != index)
                t.push(productLocalChargesItem1[i])
        }
        //t.splice(ind, 1);
        recalclulateOriginalValuesWithLocalCharges(t);
        setProductLocalChargesItem1(t)
    }
    const checkSelectExists = function (list) {
        var itemExits = false;
        list.forEach(item => {
            if (item.selectedItem.name == "Select") {
                itemExits = true;
            }
        });
        return itemExits;
    }
    const addFreightChargesRec = function () {
        var ind = 0;
        if (frcIndex == 0) {
            ind = productFreightChargesItem1.length;
            setFrcIndex(ind);
        } else {
            ind = frcIndex + 1;
            setFrcIndex(frcIndex + 1);
        }
        if (checkSelectExists(productFreightChargesItem1)) {
            setNewEmptyProductError(true);
            setNewEmptyProductMsg("Freight Charges.")
            return;
        }
        var p = {
            id: -1,
            name: "Select",
            currencies: [{ lable: 'Select', id: -1 }],
            min: "",
            rate: "",
            remark: "",
            selectedCurrency: { lable: 'Select', id: -1 },
            selectedUnit: { lable: 'Select', id: -1 },
            units: [{ lable: 'Select', id: -1 }]
        }
        var productFreightCharges11 = []
        productFreightCharges11 = [p].concat(productFreightCharges1)


        var t1 = {
            items: productFreightCharges11,
            index: ind,
            selectedItem: productFreightCharges11[0],
            selectedUnit: productFreightCharges11[0].units[0],
            selectedCurrency: productFreightCharges11[0].currencies[0],
        }
        var t = [...productFreightChargesItem1]
        t.push(t1);
        (setProductFreightChargesItem1(t));
        recalclulateOriginalValuesWithFreightCharges(t);
    }
    const addCustomeChargesRec = function () {
        var ind = 0;
        if (ccIndex == 0) {
            ind = productCustomChargesItem1.length;
            setCcIndex(ind);
        } else {
            ind = ccIndex + 1;
            setCcIndex(ccIndex + 1);
        }
        if (checkSelectExists(productCustomChargesItem1)) {
            setNewEmptyProductError(true);
            setNewEmptyProductMsg("Custom Charges.")
            return;
        }
        var p = {
            id: -1,
            name: "Select",
            currencies: [{ lable: 'Select', id: -1 }],
            min: "",
            rate: "",
            remark: "",
            selectedCurrency: { lable: 'Select', id: -1 },
            selectedUnit: { lable: 'Select', id: -1 },
            units: [{ lable: 'Select', id: -1 }]
        }
        var productCustomCharges21 = []
        productCustomCharges21 = [p].concat(productCustomCharges2)

        var t1 = {
            items: productCustomCharges21,
            index: ind,
            selectedItem: productCustomCharges21[0],
            selectedUnit: productCustomCharges21[0].units[0],
            selectedCurrency: productCustomCharges21[0].currencies[0],
        }
        var t = [...productCustomChargesItem1]
        t.push(t1);
        (setProductCustomChargesItem1(t));
        recalclulateOriginalValuesWithCustomCharges(t);
    }
    const addLocalChargesRec = function () {
        var ind = 0;
        if (lcIndex == 0) {
            ind = productLocalChargesItem1.length;
            setLcIndex(ind);
        } else {
            ind = lcIndex + 1;
            setLcIndex(lcIndex+1);
        }
        if (checkSelectExists(productLocalChargesItem1)) {
            setNewEmptyProductError(true);
            setNewEmptyProductMsg("Local Charges.")
            return;
        }
        var p = {
            id: -1,
            name: "Select",
            currencies: [{ lable: 'Select', id: -1 }],
            min: "",
            rate: "",
            remark: "",
            selectedCurrency: { lable: 'Select', id: -1 },
            selectedUnit: { lable: 'Select', id: -1 },
            units: [{ lable: 'Select', id: -1 }]
        }
        var productLocalCharges11 = []
        productLocalCharges11 = [p].concat(productLocalCharges1)


        var t1 = {
            items: productLocalCharges11,
            index: ind,
            selectedItem: productLocalCharges11[0],
            selectedUnit: productLocalCharges11[0].units[0],
            selectedCurrency: productLocalCharges11[0].currencies[0],
        }
        var t = [...productLocalChargesItem1]
        t.push(t1);
        (setProductLocalChargesItem1(t));
        recalclulateOriginalValuesWithLocalCharges(t);
    }
    const addForeignChargesRec = function () {
        var ind = 0;
        if (fcIndex == 0) {
            ind = productForeignChargesItem.length;
            setFcIndex(ind);
        } else {
            ind = fcIndex + 1;
            setFcIndex(ind);
        }
        if (checkSelectExists(productForeignChargesItem)) {
            setNewEmptyProductError(true);
            setNewEmptyProductMsg("Foreign Charges.")
            return;
        }
        var p = {
            id: -1,
            name: "Select",
            currencies: [{ lable: 'Select', id: -1 }],
            min: "",
            rate: "",
            remark: "",
            selectedCurrency: { lable: 'Select', id: -1 },
            selectedUnit: { lable: 'Select', id: -1 },
            units: [{ lable: 'Select', id: -1 }]
        }
        var productForeignCharges11 = []
        productForeignCharges11 = [p].concat(productForeignCharges)

        var t1 = {
            index: ind,
            items: productForeignCharges11,
            selectedItem: productForeignCharges11[0],
            selectedUnit: productForeignCharges11[0].units[0],
            selectedCurrency: productForeignCharges11[0].currencies[0],
        }
        var t = [...productForeignChargesItem]
        t.push(t1);
        (setProductForeignChargesItem(t));
        recalclulateOriginalValuesWithForeignCharges(t)
    }
    const [productForeignChargesNew, setProductForeignChargesNew] = useState([]);
    const setForeignChargeItem = function (index, val) {
        var t = [...productForeignChargesItem]
        for (var i = 0; i < t.length; i++) {
            if (t[i].index == index)
                t[i] = val;
        }
        setProductForeignChargesItem(t)
        recalclulateOriginalValuesWithForeignCharges(t);
    }
    const setCustomChargeItemInList = function (index, val) {
        var t = [...productCustomChargesItem1]
        for (var i = 0; i < t.length; i++) {
            if (t[i].index == index)
                t[i] = val;
        }
        (setProductCustomChargesItem1(t));
        recalclulateOriginalValuesWithCustomCharges(t);
    }
    const setFreightChargeItemInList = function (index, val) {
        var t = [...productFreightChargesItem1]
        for (var i = 0; i < t.length; i++) {
            if (t[i].index == index)
                t[i] = val;
        }
        (setProductFreightChargesItem1(t));
        recalclulateOriginalValuesWithFreightCharges(t);
    }
    const setLocalChargeItemInList = function (index, val) {
        var t = [...productLocalChargesItem1]
        for (var i = 0; i < t.length; i++) {
            if (t[i].index == index)
                t[i] = val;
        }
        (setProductLocalChargesItem1(t));
        recalclulateOriginalValuesWithLocalCharges(t);
    }
    const getTotalCostTon = function () {
        var tot = 0;
        for (var i = 0; i < costShipmentDetails?.length; i++) {
            tot = tot + costShipmentDetails[i].weight * costShipmentDetails[i].pieces;
        }
        return Math.ceil(tot / 1000);
        //return Number(Number(value?.manualWeight ? value?.manualWeight : tot).toFixed(2))
    }
    const getTotalCostSum = function () {
        var tot = 0;
        for (var i = 0; i < costShipmentDetails?.length; i++) {
            tot = tot + costShipmentDetails[i].weight * costShipmentDetails[i].pieces;
        }
        return Number(Number(value?.manualWeight ? value?.manualWeight : tot).toFixed(2))
    }
    const getTotalCostVolume = function () {
        var tot = 0;
        for (var i = 0; i < costShipmentDetails?.length; i++) {
            tot = tot + (costShipmentDetails[i].pieces * costShipmentDetails[i].length * costShipmentDetails[i].width * costShipmentDetails[i].height) / 1000000;
        }
        return Number(Number(value?.manualVolume ? value?.manualVolume : tot).toFixed(2));
    }
    const getTotalCostChargeable = function () {
        if (value.manualWeight)
            if (Number(value.manualWeight) != 0)
                return Number(value.manualWeight);
        var kfF = listOfKgFactor1.find(u => u.shipmentType === 'Air')?.kg
        if (!kfF) {
            kfF = 1000;
        }   

        var totCharg = getTotalCostVolume() * kfF;
        if (totCharg < getTotalCostSum()) {
            totCharg = getTotalCostSum();
        }
        return Number(Number(totCharg).toFixed(2));
    }
    const getTotalCostWM = function () {
        var t_w_m = 0;
        //if (value.shipmentType == 2) {
        //    t_w_m = 1;
        //}
        for (var i = 0; i < costShipmentDetails?.length; i++) {
            //tot = tot + costShipmentDetails[i].weight * costShipmentDetails[i].pieces;
            var t_v_m3 = costShipmentDetails[i].pieces * (costShipmentDetails[i].length * costShipmentDetails[i].width * costShipmentDetails[i].height) / 1000000;
            var t_w_kg = costShipmentDetails[i].weight * costShipmentDetails[i].pieces;
            var kfF = Number(listOfKgFactor1.find(u => u.shipmentType === 'Ocean')?.kg)
            if (!kfF) {
                kfF = 1000;
            }
            if (t_w_kg/kfF > t_v_m3) {
                t_w_m += t_w_kg / kfF;
            } else {
                t_w_m += t_v_m3;
            }

        }
        if (value.shipmentType == 2) {
            if (value.manualVolume)
                if (Number(value.manualVolume) != 0)
                    t_w_m = Number(value.manualVolume);
            if (t_w_m) {
                t_w_m = t_w_m < 1 ? 1 : t_w_m;
            }
        }

        return Number(Number(t_w_m).toFixed(2));
    }
    const [isLoaded, setIsLoaded] = useState(false);
    const getCRMsByFunctionTypes1 = function (functionId) {
        var temp = []
        for (var i = 0; i < crmList.length; i++) {
            if (crmList[i].functionId == functionId) {
                temp.push(crmList[i])
            }
        }
        return temp;
    }

    const getCRMsByFunctionTypes = function (functionIdArr) {
        var temp = []
        for (var j = 0; j < functionIdArr.length; j++) {
            for (var i = 0; i < crmList.length; i++) {
                if (crmList[i].functionId == functionIdArr[j]) {
                    var tObj = JSON.parse(JSON.stringify(crmList[i]));
                    tObj.name = tObj.companyName;
                    temp.push(tObj);
                    //break;
                }
            }
        }
        if (temp.length > 0) {
            if (!value.cutomerCrmId) {
                //setValue(prev => ({ ...prev, cutomerCrmId: temp[0].id }))
            }
        }
       
        temp.sort(function (a, b) {
            if (a.companyName?.toLowerCase() < b.companyName?.toLowerCase()) { return -1; }
            if (a.companyName?.toLowerCase() > b.companyName?.toLowerCase()) { return 1; }
            return 0;
        })
        return temp;
    }
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const productCategoriesOpt = listOfProductCategories.map(pr => ({
        id: pr.id,
        name: pr.lable
    }));
    const shipmentTypesOpt = listOfShipmentTypes.map(pr => ({
        id: pr.id,
        name: pr.lable
    }));

    const serviceTypeOpt = listOfServiceType.filter(function (obj) {
        return obj.serviceType != 'Export' && obj.serviceType != 'Import';
    }).map(pr => ({
        id: pr.id,
        name: pr.serviceType
    }));
    const FLCTypes40pt = listOfFLCTypes40.map(pr => ({
        id: pr.id,
        name: pr.type
    }));
    const FLCTypes20pt = listOfFLCTypes20.map(pr => ({
        id: pr.id,
        name: pr.type
    }));
    const incotermsOpt = listOfIncoterms.map(pr => ({
        id: pr.id,
        name: pr.incoterm
    }));
    function formatDate(newDate) {
        const d = newDate
        const year = d.getFullYear()
        const date = d.getDate()
        const monthName = d.getMonth() + 1
        const formatted = `${date}/${monthName}/${year}`
        return formatted.toString()
    }
    const handlePortOfLoading = (event) => {
        setValue(prev => ({ ...prev, from: event.target.value }))
        var p = listOfPort.find(u => u.id === event.target.value);
        if (!p) {
            p = {}
            p.countryId = 1;
        }
        var country = countryList.find(u => u.id === p?.countryId);
        setValue(prev => ({ ...prev, countryId: country.id }));
        setValue(prev => ({ ...prev, finalCountryId: country.id }));
        // setValue(prev => ({ ...prev, params: t }))
    }
    const handleAirPortOfLoading = (event) => {
        setValue(prev => ({ ...prev, from: event.target.value }))
        var p = listOfAirPort.find(u => u.id === event.target.value);
        var country = countryList.find(u => u.id === p.countryId);
        setValue(prev => ({ ...prev, countryId: country.id }));
        setValue(prev => ({ ...prev, finalCountryId: country.id }));
        // setValue(prev => ({ ...prev, params: t }))
    }
    const handleCountryZipParams = (event) => {
        setValue(prev => ({ ...prev, countryAirZipId: event.target.value }))
        var country = zipsByCountry.find(u => u.id === event.target.value);
        var params1 = { ...value.params, countryAirZip: country }
        setValue(prev => ({ ...prev, params: params1 }))
    }
    const handleCountryParams = (event) => {
        var country = countryList.find(u => u.id === event.target.value);
        var params1 = { ...value.params, countryDest: country }
        setValue(prev => ({ ...prev, params: params1 }))
        setValue(prev => ({ ...prev, cdi: event.target.value }))
    }
    const handleCountryParams1 = (event) => {
        var country = countryList.find(u => u.id === event.target.value);
        var params1 = { ...value.params, countryDest: country }
        setValue(prev => ({ ...prev, params: params1 }))
        setValue(prev => ({ ...prev, cdi1: event.target.value }))
    }
    const handleAirPortOfDestination = (event) => {
        setValue(prev => ({ ...prev, to: event.target.value }))
        window.setTimeout(function () {

            var p = listOfAirPort.find(u => u.id === event.target.value);
            var country = countryList.find(u => u.id === p.countryId);
            var params1 = { ...value.params, countryDest: country }
            setValue(prev => ({ ...prev, cdi: country.id }))
            setValue(prev => ({ ...prev, params: params1 }))
        }, 500)
    }

    const handleProductType = (event) => {
        setValue(prev => ({ ...prev, productType: event.target.value }))
        var t = []
        setListOfQuoatationCost(t)
        setProductCustomChargesItem1([]);
        setproductCustomChargesItemOriginal([]);
        setProductForeignCharges([])
        setProductLocalCharges1([])
        setProductFreightCharges1([])
        setProductForeignChargesItem([]);
        setProductFreightChargesItem1([]);
        setProductLocalChargesItem1([]);
        getQuote(value.shipmentType);
    }
    var tempShip = 0;
    const handleShipmentType = (event) => {
        setProductCustomChargesItem1([]);
        setproductCustomChargesItemOriginal([]);
        setProductForeignCharges([])
        setProductLocalCharges1([])
        setProductFreightCharges1([])
        setProductForeignChargesItem([]);
        setProductFreightChargesItem1([]);
        setProductLocalChargesItem1([]);
        tempShip = event.target.value;
        setValue(prev => ({ ...prev, shipmentType: Number(event.target.value) }))
        var t = []
        setListOfQuoatationCost(t)
        getQuote(event.target.value);
        // setValue(prev => ({ ...prev, params: t }))
    }

    const getTotalEsitmatedAirForeignOriginal = function (tempFCI) {

       //return getTotalEsitmatedAirForeignOrgPrice()

        var x = 0;
        tempFCI.forEach(item => {
            if (item.units && item.units.length) {
                if (!item.selectedUnit || !item.selectedUnit.id) {
                    item.selectedUnit = item.units[0]
                }
            }
            if (item.currencies && item.currencies.length) {
                if (!item.selectedCurrency || !item.selectedCurrency.id) {
                    item.selectedCurrency = item.currencies[0]
                }
            }
            if (item.selectedCurrency && item.selectedItem) {
                var exch = listOfExchangeRates.find(u => u.id === item.selectedCurrency.id)
                if (exch) {
                   // var kfF = productForeignChargesItem.find(u => u ?. selectedItem?.id === item?.selectedItem?.id)
                    var p = getCalculations(item.selectedItem.rate, item.selectedItem.min, item.selectedUnit.id) / exch.oneUsd;
                    
                    x = x + p;

                }
            }
        });
        return x 

    }
    const getTotalEsitmatedAirForeignOriginal20 = function (tempFCI) {
        var x = 0;
        tempFCI.forEach(item => {
            if (item.units && item.units.length) {
                if (!item.selectedUnit || !item.selectedUnit.id) {
                    item.selectedUnit = item.units[0]
                }
            }
            if (item.currencies && item.currencies.length) {
                if (!item.selectedCurrency || !item.selectedCurrency.id) {
                    item.selectedCurrency = item.currencies[0]
                }
            }
            if (item.selectedCurrency && item.selectedItem) {
                var exch = listOfExchangeRates.find(u => u.id === item.selectedCurrency.id)
                if (exch)
                    x = x + getCalculations(item.selectedItem.rate20, item.selectedItem.min, item.selectedUnit.id, true) / exch.oneUsd;
            }
        });
        return x

    }
    const getTotalEsitmatedAirForeignOriginal40 = function (tempFCI) {
        var x = 0;
        tempFCI.forEach(item => {
            if (item.units && item.units.length) {
                if (!item.selectedUnit || !item.selectedUnit.id) {
                    item.selectedUnit = item.units[0]
                }
            }
            if (item.currencies && item.currencies.length) {
                if (!item.selectedCurrency || !item.selectedCurrency.id) {
                    item.selectedCurrency = item.currencies[0]
                }
            }
            if (item.selectedCurrency && item.selectedItem) {
                var exch = listOfExchangeRates.find(u => u.id === item.selectedCurrency.id)
                if (exch)
                    x = x + getCalculations(item.selectedItem.rate40, item.selectedItem.min, item.selectedUnit.id) / exch.oneUsd;
            }
        });
        return x

    }
    const getTotalEsitmatedAirForeign = function () {
        var x = 0;
        productForeignChargesItem.forEach(item => {
            if (item.units && item.units.length) {
                if (!item.selectedUnit || !item.selectedUnit.id) {
                    item.selectedUnit = item.units[0]
                }
            }
            if (item.currencies && item.currencies.length) {
                if (!item.selectedCurrency || !item.selectedCurrency.id) {
                    item.selectedCurrency = item.currencies[0]
                }
            }
            if (item.selectedCurrency && item.selectedItem) {
                var exch = listOfExchangeRates.find(u => u.id === item.selectedCurrency.id)
                if (exch) {
                    x = x + getCalculations(item.selectedItem.rate, item.selectedItem.min, item.selectedUnit.id) / exch.oneUsd;

                }
            }
        });
        return x

    }
    const getTotalEsitmatedAirForeignOrgPrice = function () {

        var x = 0;
        for (var i = 0; i < productForeignChargesItemNew.length; i++) {
            var item = productForeignChargesItemNew[i];
            var exch = listOfExchangeRates.find(u => u.id === item?.selectedCurrency?.id)
            if (exch) {
                var p = Number(Number(productForeignChargesItemNew[i].orgPrice ? productForeignChargesItemNew[i].orgPrice : 0).toFixed(2)) / exch.oneUsd
                
                x = x + p
            }           
        }
       
        return x
    }
    const getTotalEsitmatedAirForeign20 = function (rate, min, unit) {
        var x = 0;
        productForeignChargesItem.forEach(item => {
            if (item.units && item.units.length) {
                if (!item.selectedUnit || !item.selectedUnit.id) {
                    item.selectedUnit = item.units[0]
                }
            }
            if (item.currencies && item.currencies.length) {
                if (!item.selectedCurrency || !item.selectedCurrency.id) {
                    item.selectedCurrency = item.currencies[0]
                }
            }
            if (item.selectedCurrency && item.selectedItem) {
                var exch = listOfExchangeRates.find(u => u.id === item.selectedCurrency.id)
                if (exch) {
                    if (item.rate20)
                        x = x + getCalculations(item.rate20, item.selectedItem.min, item.selectedUnit.id, true) / exch.oneUsd;
                }
            }
        });
        return x

    }
    const getTotalEsitmatedAirForeign40 = function (rate, min, unit) {

        var x = 0;
        productForeignChargesItem.forEach(item => {
            if (item.units && item.units.length) {
                if (!item.selectedUnit || !item.selectedUnit.id) {
                    item.selectedUnit = item.units[0]
                }
            }
            if (item.currencies && item.currencies.length) {
                if (!item.selectedCurrency || !item.selectedCurrency.id) {
                    item.selectedCurrency = item.currencies[0]
                }
            }
            if (item.selectedCurrency && item.selectedItem) {
                var exch = listOfExchangeRates.find(u => u.id === item.selectedCurrency.id)
                if (exch) {
                    if (item.rate40) {
                        x = x + getCalculations(item.rate40, item.selectedItem.min, item.selectedUnit.id) / exch.oneUsd;
                        //x = x + exch.oneUsd * getCalculations(item.rate40, item.selectedItem.min, item.selectedUnit.id);
                    }
                }
            }
        });
        return x

    }
    const getTotalEsitmatedAirFreight20 = function (rate, min, unit) {
        var x = 0;
        productFreightChargesItem1.forEach(item => {
            if (item.units && item.units.length) {
                if (!item.selectedUnit || !item.selectedUnit.id) {
                    item.selectedUnit = item.units[0]
                }
            }
            if (item.currencies && item.currencies.length) {
                if (!item.selectedCurrency || !item.selectedCurrency.id) {
                    item.selectedCurrency = item.currencies[0]
                }
            }
            if (item.selectedCurrency && item.selectedItem) {
                var exch = listOfExchangeRates.find(u => u.id === item.selectedCurrency.id)
                if (exch && item.rate20)
                    x = x + getFreightCalculations(item.rate20, item.selectedItem.min, item.selectedUnit.id, true) / exch.oneUsd;
            }
        });
        return x

    }
    const getTotalEsitmatedAirFreight40 = function (rate, min, unit) {
        var x = 0;
        productFreightChargesItem1.forEach(item => {
            if (item.units && item.units.length) {
                if (!item.selectedUnit || !item.selectedUnit.id) {
                    item.selectedUnit = item.units[0]
                }
            }
            if (item.currencies && item.currencies.length) {
                if (!item.selectedCurrency || !item.selectedCurrency.id) {
                    item.selectedCurrency = item.currencies[0]
                }
            }
            if (item.selectedCurrency && item.selectedItem) {
                var exch = listOfExchangeRates.find(u => u.id === item.selectedCurrency.id)
                if (exch && item.rate40)
                    // x = x + exch.oneUsd * getFreightCalculations(item.rate40, item.selectedItem.min, item.selectedUnit.id) / exch.oneUsd;
                    x = x + getFreightCalculations(item.rate40, item.selectedItem.min, item.selectedUnit.id) / exch.oneUsd;
            }
        });
        return x

    }
    const getTotalEsitmatedAirLocal40 = function (rate, min, unit) {
        var x = 0;
        productLocalChargesItem1.forEach(item => {
            if (item.units && item.units.length) {
                if (!item.selectedUnit || !item.selectedUnit.id) {
                    item.selectedUnit = item.units[0]
                }
            }
            if (item.currencies && item.currencies.length) {
                if (!item.selectedCurrency || !item.selectedCurrency.id) {
                    item.selectedCurrency = item.currencies[0]
                }
            }
            if (item.selectedCurrency && item.selectedItem) {
                var exch = listOfExchangeRates.find(u => u.id === item.selectedCurrency.id)
                if (exch && item.rate40)
                    x = x + getCalculations(item.rate40, item.selectedItem.min, item.selectedUnit.id) / exch.oneUsd;
            }
        });
        return x

    }
    const getTotalEsitmatedAirCustome40 = function (rate, min, unit) {
        var x = 0;
        productCustomChargesItem1.forEach(item => {
            if (item.units && item.units.length) {
                if (!item.selectedUnit || !item.selectedUnit.id) {
                    item.selectedUnit = item.units[0]
                }
            }
            if (item.currencies && item.currencies.length) {
                if (!item.selectedCurrency || !item.selectedCurrency.id) {
                    item.selectedCurrency = item.currencies[0]
                }
            }
            if (item.selectedCurrency && item.selectedItem) {
                var exch = listOfExchangeRates.find(u => u.id === item.selectedCurrency.id)
                if (exch && item.rate40)
                    x = x + getCalculations(item.rate40, item.selectedItem.min, item.selectedUnit.id) / exch.oneUsd;
            }
        });
        return x

    }
    const getTotalEsitmatedAirLocal20 = function (rate, min, unit) {
        var x = 0;
        productLocalChargesItem1.forEach(item => {
            if (item.units && item.units.length) {
                if (!item.selectedUnit || !item.selectedUnit.id) {
                    item.selectedUnit = item.units[0]
                }
            }
            if (item.currencies && item.currencies.length) {
                if (!item.selectedCurrency || !item.selectedCurrency.id) {
                    item.selectedCurrency = item.currencies[0]
                }
            }
            if (item.selectedCurrency && item.selectedItem) {
                var exch = listOfExchangeRates.find(u => u.id === item.selectedCurrency.id)
                if (exch && item.rate20)
                    x = x + getCalculations(item.rate20, item.selectedItem.min, item.selectedUnit.id, true) / exch.oneUsd;
            }
        });
        return x

    }
    const getTotalEsitmatedAirCustome20 = function (rate, min, unit) {
        var x = 0;
        productCustomChargesItem1.forEach(item => {
            if (item.units && item.units.length) {
                if (!item.selectedUnit || !item.selectedUnit.id) {
                    item.selectedUnit = item.units[0]
                }
            }
            if (item.currencies && item.currencies.length) {
                if (!item.selectedCurrency || !item.selectedCurrency.id) {
                    item.selectedCurrency = item.currencies[0]
                }
            }
            if (item.selectedCurrency && item.selectedItem) {
                var exch = listOfExchangeRates.find(u => u.id === item.selectedCurrency.id)
                if (exch && item.rate20)
                    x = x + getCalculations(item.rate20, item.selectedItem.min, item.selectedUnit.id, true) / exch.oneUsd;
            }
        });
        return x

    }
    const getTotalEsitmatedAirCustomeOriginale = function (CustomeOriginale) {
        var x = 0;
        CustomeOriginale.forEach(item => {
            if (item.units && item.units.length) {
                if (!item.selectedUnit || !item.selectedUnit.id) {
                    item.selectedUnit = item.units[0]
                }
            }
            if (item.currencies && item.currencies.length) {
                if (!item.selectedCurrency || !item.selectedCurrency.id) {
                    item.selectedCurrency = item.currencies[0]
                }
            }
            if (item.selectedCurrency && item.selectedItem) {
                var exch = listOfExchangeRates.find(u => u.id === item.selectedCurrency.id)
                if (exch)
                    x = x + getCalculations(item.selectedItem.rate, item.selectedItem.min, item.selectedUnit.id) / exch.oneUsd;
            }
        });
        return x

    }
    const getTotalEsitmatedAirCustomeOriginale20 = function (CustomeOriginale) {
        var x = 0;
        CustomeOriginale.forEach(item => {
            if (item.units && item.units.length) {
                if (!item.selectedUnit || !item.selectedUnit.id) {
                    item.selectedUnit = item.units[0]
                }
            }
            if (item.currencies && item.currencies.length) {
                if (!item.selectedCurrency || !item.selectedCurrency.id) {
                    item.selectedCurrency = item.currencies[0]
                }
            }
            if (item.selectedCurrency && item.selectedItem) {
                var exch = listOfExchangeRates.find(u => u.id === item.selectedCurrency.id)
                if (exch)
                    x = x + getCalculations(item.selectedItem.rate20, item.selectedItem.min, item.selectedUnit.id, true) / exch.oneUsd;
            }
        });
        return x

    }
    const getTotalEsitmatedAirCustomeOriginale40 = function (CustomeOriginale) {
        var x = 0;
        CustomeOriginale.forEach(item => {
            if (item.units && item.units.length) {
                if (!item.selectedUnit || !item.selectedUnit.id) {
                    item.selectedUnit = item.units[0]
                }
            }
            if (item.currencies && item.currencies.length) {
                if (!item.selectedCurrency || !item.selectedCurrency.id) {
                    item.selectedCurrency = item.currencies[0]
                }
            }
            if (item.selectedCurrency && item.selectedItem) {
                var exch = listOfExchangeRates.find(u => u.id === item.selectedCurrency.id)
                if (exch)
                    x = x + getCalculations(item.selectedItem.rate40, item.selectedItem.min, item.selectedUnit.id) / exch.oneUsd;
            }
        });
        return x

    }
    const getTotalEsitmatedAirCustome = function (rate, min, unit) {
        var x = 0;
        productCustomChargesItem1.forEach(item => {
            if (item.units && item.units.length) {
                if (!item.selectedUnit || !item.selectedUnit.id) {
                    item.selectedUnit = item.units[0]
                }
            }
            if (item.currencies && item.currencies.length) {
                if (!item.selectedCurrency || !item.selectedCurrency.id) {
                    item.selectedCurrency = item.currencies[0]
                }
            }
            if (item.selectedCurrency && item.selectedItem) {
                var exch = listOfExchangeRates.find(u => u.id === item.selectedCurrency.id)
                if (exch)
                    x = x + getCalculations(item.selectedItem.rate, item.selectedItem.min, item.selectedUnit.id) / exch.oneUsd;
            }
        });
        return x

    }
    const getTotalEsitmatedAirLocalOriginal = function (LocalOriginal) {

        return getTotalEsitmatedAirLocalOrgPrice()
    }
    const getTotalEsitmatedAirLocalOriginal20 = function (LocalOriginal) {
        var x = 0;
        LocalOriginal.forEach(item => {
            if (item.units && item.units.length) {
                if (!item.selectedUnit || !item.selectedUnit.id) {
                    item.selectedUnit = item.units[0]
                }
            }
            if (item.currencies && item.currencies.length) {
                if (!item.selectedCurrency || !item.selectedCurrency.id) {
                    item.selectedCurrency = item.currencies[0]
                }
            }
            if (item.selectedCurrency && item.selectedItem) {
                var exch = listOfExchangeRates.find(u => u.id === item.selectedCurrency.id)
                if (exch)
                    x = x + getCalculations(item.rate20, item.min, item.selectedUnit.id, true) / exch.oneUsd;
            }
        });
        return x

    }
    const getTotalEsitmatedAirLocalOriginal40 = function (LocalOriginal) {
        var x = 0;
        LocalOriginal.forEach(item => {
            if (item.units && item.units.length) {
                if (!item.selectedUnit || !item.selectedUnit.id) {
                    item.selectedUnit = item.units[0]
                }
            }
            if (item.currencies && item.currencies.length) {
                if (!item.selectedCurrency || !item.selectedCurrency.id) {
                    item.selectedCurrency = item.currencies[0]
                }
            }
            if (item.selectedCurrency && item.selectedItem) {
                var exch = listOfExchangeRates.find(u => u.id === item.selectedCurrency.id)
                if (exch)
                    x = x + getCalculations(item.rate40, item.min, item.selectedUnit.id) / exch.oneUsd;
            }
        });
        return x

    }
    const getTotalEsitmatedAirLocal = function (rate, min, unit) {
        var x = 0;
        for (var i = 0; i < productLocalChargesItem1.length; i++) {
            var item = productLocalChargesItem1[i];
            if (item.units && item.units.length) {
                if (!item.selectedUnit || !item.selectedUnit.id) {
                    item.selectedUnit = item.units[0]
                }
            }
            if (item.currencies && item.currencies.length) {
                if (!item.selectedCurrency || !item.selectedCurrency.id) {
                    item.selectedCurrency = item.currencies[0]
                }
            }
            if (item.selectedCurrency && item.selectedItem) {
                var exch = listOfExchangeRates.find(u => u.id === item.selectedCurrency.id)
                if (exch)
                    x = x + getCalculations(item.rate, item.min, item.selectedUnit.id) / exch.oneUsd;
            }
        }
        return x
    }
    const getTotalEsitmatedAirLocalOrgPrice = function () {
        var x = 0;
        for (var i = 0; i < productLocalChargesItem1.length; i++) {
            x = x + Number(productLocalChargesItem1[i].orgPrice ? productLocalChargesItem1[i].orgPrice:0)
        }
        return x
    }
    const getTotalEsitmatedAirFreightOriginal = function (productFreight) {
        var x = 0;
        productFreight.forEach(item => {
            if (item.units && item.units.length) {
                if (!item.selectedUnit || !item.selectedUnit.id) {
                    item.selectedUnit = item.units[0]
                }
            }
            if (item.currencies && item.currencies.length) {
                if (!item.selectedCurrency || !item.selectedCurrency.id) {
                    item.selectedCurrency = item.currencies[0]
                }
            }
            if (item.selectedCurrency && item.selectedItem) {
                var exch = listOfExchangeRates.find(u => u.id === item.selectedCurrency.id)
                if (exch)
                    x = x + getFreightCalculations(item.rate, item.min, item.selectedUnit.id) / exch.oneUsd;
            }
        });
        return x

    }
    const getTotalEsitmatedAirFreightOriginal20 = function (productFreight) {
        var x = 0;
        productFreight.forEach(item => {
            if (item.units && item.units.length) {
                if (!item.selectedUnit || !item.selectedUnit.id) {
                    item.selectedUnit = item.units[0]
                }
            }
            if (item.currencies && item.currencies.length) {
                if (!item.selectedCurrency || !item.selectedCurrency.id) {
                    item.selectedCurrency = item.currencies[0]
                }
            }
            if (item.selectedCurrency && item.selectedItem) {
                var exch = listOfExchangeRates.find(u => u.id === item.selectedCurrency.id)
                if (exch)
                    x = x + getFreightCalculations(item.rate20, item.min, item.selectedUnit.id, true) / exch.oneUsd;
            }
        });
        return x

    }
    const getTotalEsitmatedAirFreightOriginal40 = function (productFreight) {
        var x = 0;
        productFreight.forEach(item => {
            if (item.units && item.units.length) {
                if (!item.selectedUnit || !item.selectedUnit.id) {
                    item.selectedUnit = item.units[0]
                }
            }
            if (item.currencies && item.currencies.length) {
                if (!item.selectedCurrency || !item.selectedCurrency.id) {
                    item.selectedCurrency = item.currencies[0]
                }
            }
            if (item.selectedCurrency && item.selectedItem) {
                var exch = listOfExchangeRates.find(u => u.id === item.selectedCurrency.id)
                if (exch)
                    x = x + getFreightCalculations(item.rate40, item.min, item.selectedUnit.id) / exch.oneUsd;
            }
        });
        return x

    }
    const getTotalEsitmatedAirFreight = function (rate, min, unit) {
        var x = 0;
        productFreightChargesItem1.forEach(item => {
            if (item.units && item.units.length) {
                if (!item.selectedUnit || !item.selectedUnit.id) {
                    item.selectedUnit = item.units[0]
                }
            }
            if (item.currencies && item.currencies.length) {
                if (!item.selectedCurrency || !item.selectedCurrency.id) {
                    item.selectedCurrency = item.currencies[0]
                }
            }
            if (item.selectedCurrency && item.selectedItem && item.selectedUnit) {
                var exch = listOfExchangeRates.find(u => u.id === item.selectedCurrency.id)
                if (exch)
                    x = x + getFreightCalculations(item.rate, item.min, item.selectedUnit.id) / exch.oneUsd;
            }
        });
        return x

    }

    const getCalculations = function (rate1, min, unit, bFlc20) {
        if (!rate1) {
            rate1 = 0;
        }
        var rate = Number(rate1);
        if (value.shipmentType == 1) {
            if (unit == 1) {
                var c = getTotalCostChargeable() * rate;
                if (c < min) {
                    c = min;
                }
                return c;
            } else if (unit == 2 || unit == 4) {
                var c = 1 * rate;
                if (c < min) {
                    c = min;
                }
                return c;
            } else if (unit == 3) {
                var c = getTotalCostChargeable() * rate;
                if (c < min) {
                    c = min;
                }
                return c;
            } else if (unit == 5) {
                var c = getTotalCostTon() * rate;
                if (c < min) {
                    c = min;
                }
                return c;
            }
        }
        if (value.shipmentType == 2) {
            if (unit == 1) {
                var c = getTotalCostWM() * rate;
                if (c < min) {
                    c = min;
                }
                return c;
            } else if (unit == 2 || unit == 4) {
                var c = 1 * rate;
                if (c < min) {
                    c = min;
                }
                return c;
            } else if (unit == 3) {
                var c = getTotalCostWM() * rate;
                if (c < min) {
                    c = min;
                }
                return c;
            } else if (unit == 5) {
                var c = getTotalCostTon() * rate;
                if (c < min) {
                    c = min;
                }
                return c;
            }
        }
        if (value.shipmentType == 3) {

            if (unit == 2) {
                var c = 1 * rate;
                if (c < min) {
                    c = min;
                }
                return c;
            } else if (unit == 4) {
                //var q = value.flc20 ? value.flc20 : value.flc40;
                var q = 0;
                if (bFlc20) {
                    q = value.flc20;
                } else {
                    q = value.flc40;
                }
                if (!q) {
                    q = 0;
                }
                var c = q * rate;
                if (c < min) {
                    c = min;
                }
                return c;
            } else {
                return 0
            }
        }
    }
    const getCalculations2040 = function (rate1, min, unit, bFlc20, val2040) {
        if (!rate1) {
            rate1 = 0;
        }
        var rate = Number(rate1);
        if (value.shipmentType == 1) {
            if (unit == 1) {
                var c = getTotalCostChargeable() * rate;
                if (c < min) {
                    c = min;
                }
                return c;
            } else if (unit == 2 || unit == 4) {
                var c = 1 * rate;
                if (c < min) {
                    c = min;
                }
                return c;
            } else if (unit == 3) {
                var c = getTotalCostChargeable() * rate;
                if (c < min) {
                    c = min;
                }
                return c;
            } else if (unit == 5) {
                var c = getTotalCostTon() * rate;
                if (c < min) {
                    c = min;
                }
                return c;
            }
        }
        if (value.shipmentType == 2) {
            if (unit == 1) {
                var c = getTotalCostWM() * rate;
                if (c < min) {
                    c = min;
                }
                return c;
            } else if (unit == 2 || unit == 4) {
                var c = 1 * rate;
                if (c < min) {
                    c = min;
                }
                return c;
            } else if (unit == 3) {
                var c = getTotalCostWM() * rate;
                if (c < min) {
                    c = min;
                }
                return c;
            } else if (unit == 5) {
                var c = getTotalCostTon() * rate;
                if (c < min) {
                    c = min;
                }
                return c;
            }
        }
        if (value.shipmentType == 3) {

            if (unit == 2) {
                var c = 1 * rate;
                if (c < min) {
                    c = min;
                }
                return c;
            } else if (unit == 4) {
                //var q = value.flc20 ? value.flc20 : value.flc40;
                var q = 0;
                if (bFlc20) {
                    q = val2040;
                } else {
                    q = val2040;
                }
                if (!q) {
                    q = 0;
                }
                var c = q * rate;
                if (c < min) {
                    c = min;
                }
                return c;
            } else {
                return 0
            }
        }
    }
    const getFreightCalculations = function (rate, min, unit, bFlc20) {

        if (!rate) {
            rate = 0;
        }
        if (value.shipmentType == 1) {
            if (unit == 1) {
                var c = getTotalCostChargeable() * rate;
                if (c < min) {
                    c = min;
                }
                return c;
            } else if (unit == 2 || unit == 4) {
                var c = 1 * rate;
                if (c < min) {
                    c = min;
                }
                return c;
            } else if (unit == 3) {
                var c = getTotalCostChargeable() * rate;
                if (c < min) {
                    c = min;
                }
                return c;
            } else if (unit == 5) {
                var c = getTotalCostSum() * rate;
                if (c < min) {
                    c = min;
                }
                return c;
            }
        }
        if (value.shipmentType == 2) {
            if (unit == 1) {
                var c = getTotalCostWM() * rate;
                if (c < min) {
                    c = min;
                }
                return c;
            } else if (unit == 2 || unit == 4) {
                var c = 1 * rate;
                if (c < min) {
                    c = min;
                }
                return c;
            } else if (unit == 4) {
                var c = 1 * rate;
                if (c < min) {
                    c = min;
                }
                return c;
            } else if (unit == 3) {
                var c = getTotalCostWM() * rate;
                if (c < min) {
                    c = min;
                }
                return c;
            } else if (unit == 5) {
                var c = getTotalCostWM() * rate;
                if (c < min) {
                    c = min;
                }
                return c;
            }
        }
        if (value.shipmentType == 3) {

            if (unit == 2) {
                var c = 1 * rate;
                if (c < min) {
                    c = min;
                }
                return c;
            } else if (unit == 4) {
                //var q = value.flc20 ? value.flc20 : value.flc40;
                var q = 0;
                if (bFlc20) {
                    q = value.flc20;
                } else {
                    q = value.flc40;
                }
                if (!q) {
                    q = 0;
                }
                var c = q * rate;
                if (c < min) {
                    c = min;
                }
                return c;
            } else {
                return 0
            }
        }
    }
    const getLableByCur = function (cur) {
        var exch = currencies.find(u => u.id === cur)
        if (exch) {
            return exch.name
        }
        return "USD"
    }
    const getValueByCur = function (val, cur) {
        if (!val) {
            return 0;
        }
        var exch = listOfExchangeRates.find(u => u.id === cur)
        if (exch) {
            val = Number(val) * exch.oneUsd
        }
        return Number(val).toFixed(2);
    }
    const getValueByCur_margin = function (val, cur) {
        recalclulateOriginalValues();
        if (!val) {
            return 0;
        }
        var exch = listOfExchangeRates.find(u => u.id === cur)
        if (exch) {
            val = Number(val) * exch.oneUsd
        }
        return Number(val).toFixed(2);
    }
    const getEstimatedTotalCost = function () {
        return Number(Number(getTotalEsitmatedAirForeign()) + Number(getTotalEsitmatedAirFreight()) + Number(getTotalEsitmatedAirLocal()) + Number(getTotalEsitmatedAirCustome()))?.toFixed(2)
    }
    const getMargin = function () {
        var ori = (props.view === 'VIEW' && !bRate) ? props?.data?.params?.originalPrice : getOriginalPrice();
        var tot = getEstimatedTotalCost()
        return Number(Number(tot) - Number(ori))?.toFixed(2)
    }
    const getOriginalPrice = function () {
        return Number((Number(getTotalEsitmatedAirForeignOriginal(productForeignChargesItemOriginal)) +
            Number(getTotalEsitmatedAirCustomeOriginale(productCustomChargesItemOriginal)) +
            Number(getTotalEsitmatedAirLocalOriginal(productLocalChargesItemOriginal)) +
            Number(getTotalEsitmatedAirFreightOriginal(productFreightChargesItemOriginal))))?.toFixed(2)
    }
    const getOriginalPrice20 = function () {
        return Number((Number(getTotalEsitmatedAirForeignOriginal20(productForeignChargesItemOriginal)) +
            Number(getTotalEsitmatedAirCustomeOriginale20(productCustomChargesItemOriginal)) +
            Number(getTotalEsitmatedAirLocalOriginal20(productLocalChargesItemOriginal)) +
            Number(getTotalEsitmatedAirFreightOriginal20(productFreightChargesItemOriginal))

        )
        )?.toFixed(2)
    }
    const getOriginalPrice40 = function () {
        return Number((Number(getTotalEsitmatedAirForeignOriginal40(productForeignChargesItemOriginal)) +
            Number(getTotalEsitmatedAirCustomeOriginale40(productCustomChargesItemOriginal)) +
            Number(getTotalEsitmatedAirLocalOriginal40(productLocalChargesItemOriginal)) +
            Number(getTotalEsitmatedAirFreightOriginal40(productFreightChargesItemOriginal))

        )
        )?.toFixed(2)
    }
    var getTotal40 = function (obj1) {
        return Number((Number(getTotalEsitmatedAirForeign40()) + Number(getTotalEsitmatedAirFreight40()) + Number(getTotalEsitmatedAirLocal40()) + Number(getTotalEsitmatedAirCustome40())))
    }
    var getTotal20 = function (obj1) {
        return Number((Number(getTotalEsitmatedAirForeign20()) + Number(getTotalEsitmatedAirFreight20()) + Number(getTotalEsitmatedAirLocal20()) + Number(getTotalEsitmatedAirCustome20())))
    }
    var removeCircularKey = function (obj1) {
        for (var k = 0; k < obj1.items?.length; k++) {
            var objSelected1 = {
                currencies: obj1.items[k].selectedItem?.currencies,
                id: obj1.items[k].selectedItem?.id,
                min: obj1.items[k].selectedItem?.min,
                name: obj1.items[k].selectedItem?.name,
                rate: obj1.items[k].selectedItem?.rate,
                rate20: obj1.items[k].selectedItem?.rate20,
                rate40: obj1.items[k].selectedItem?.rate40,
                remark: obj1.items[k].remark ? obj1.items[k].remark : obj1.items[k].selectedItem?.remark,
                selectedCurrency: obj1.items[k].selectedItem?.selectedCurrency,
                selectedUnit: obj1.items[k].selectedItem?.selectedUnit,
                units: obj1.items[k].selectedItem?.units,
            }
            delete obj1.items[k].selectedItem;
            obj1.items[k].selectedItem = objSelected1;
        }
        var objSelected = {
            currencies: obj1.selectedItem.currencies,
            id: obj1.selectedItem.id,
            min: obj1.selectedItem.min,
            name: obj1.selectedItem.name,
            rate: obj1.selectedItem.rate,
            rate20: obj1.selectedItem.rate20,
            rate40: obj1.selectedItem.rate40,
            remark: obj1.remark ? obj1.remark : obj1.selectedItem.remark,
            selectedCurrency: obj1.selectedItem.selectedCurrency,
            selectedUnit: obj1.selectedItem.selectedUnit,
            units: obj1.selectedItem.units,
        }
        delete obj1.selectedItem;
        obj1.selectedItem = objSelected;
        return obj1
    }
    const addQuoatation = function (onSaveButtonPushed) {
        if (onSaveButtonPushed) {
            for (var i = 0; i < costShipmentDetails?.length; i++) {
                if (onHandleOpenModalDimention(costShipmentDetails[i])) {
                    return;
                }
            }
        }
        var tproductForeignChargesItem1 = [];
        var tproductForeignChargesItem = [];
        try {
            tproductForeignChargesItem1 = JSON.parse(JSON.stringify(productForeignChargesItem))
            for (var i = 0; i < tproductForeignChargesItem1?.length; i++) {
                delete tproductForeignChargesItem1[i].items;
                if (!tproductForeignChargesItem1[i].rate) {
                    tproductForeignChargesItem1[i].rate = tproductForeignChargesItem1[i].selectedItem.rate;
                }
                if (!tproductForeignChargesItem1[i].min) {
                    tproductForeignChargesItem1[i].min = tproductForeignChargesItem1[i].selectedItem.min;
                }
                if (!tproductForeignChargesItem1[i].remark) {
                    tproductForeignChargesItem1[i].remark = tproductForeignChargesItem1[i].remark ? tproductForeignChargesItem1[i].remark : tproductForeignChargesItem1[i].selectedItem.remark;
                }
                //props.original?.find(u => u.selectedItem.id === detail.selectedItem.id
                //var org = productForeignChargesItemOriginal.find(u => u.selectedItem.id === tproductForeignChargesItem[i].selectedItem.id)
                //tproductForeignChargesItem[i].org = org;
            }
        } catch (e) {
            var i = 0;

            for (var i = 0; i < productForeignChargesItem.length; i++) {
                var obj1 = productForeignChargesItem[i];//var org = productForeignChargesItemOriginal.find(u => u.selectedItem.id === obj1.selectedItem.id)
                var tF = removeCircularKey(obj1);
                delete tF.items;
                if (!tF.rate) {
                    tF.rate = tF.selectedItem.rate;
                }
                if (!tF.min) {
                    tF.min = tF.selectedItem.min;
                }
                if (!tF.remark) {
                    tF.remark = tF.remark ? tF.remark : tF.selectedItem.remark;
                }
                // tF.org = org;
                //deleteKey(obj1)
                tproductForeignChargesItem1.push(tF);
            }
        }
        if (value.shipmentType != 3) {
            for (var i = 0; i < tproductForeignChargesItem1.length; i++) {
                var exists = false;
                if (tproductForeignChargesItem1[i].selectedItem.rate) {
                    if (Number(tproductForeignChargesItem1[i].selectedItem.rate) != 0) {
                        tproductForeignChargesItem.push(tproductForeignChargesItem1[i]);
                        exists = true;
                    }
                }
                if (tproductForeignChargesItem1[i].selectedItem.min && !exists) {
                    if (Number(tproductForeignChargesItem1[i].selectedItem.min) != 0) {
                        tproductForeignChargesItem.push(tproductForeignChargesItem1[i]);
                        exists = true;
                    }
                }
                if (tproductForeignChargesItem1[i].selectedItem.remark != tproductForeignChargesItem1[i].remark)
                    tproductForeignChargesItem1[i].selectedItem.remark = tproductForeignChargesItem1[i].remark;
                if (tproductForeignChargesItem1[i].selectedItem.remark && !exists) {
                    if (tproductForeignChargesItem1[i].selectedItem.remark != "") {
                        tproductForeignChargesItem.push(tproductForeignChargesItem1[i]);
                    }
                }
            }
        } else {
            for (var i = 0; i < tproductForeignChargesItem1.length; i++) {
                var exists = false;
                if (tproductForeignChargesItem1[i].selectedItem.rate20) {
                    if (Number(tproductForeignChargesItem1[i].selectedItem.rate20) != 0) {
                        tproductForeignChargesItem.push(tproductForeignChargesItem1[i]);
                        exists = true;
                    }
                }
                if (tproductForeignChargesItem1[i].selectedItem.rate40 && !exists) {
                    if (Number(tproductForeignChargesItem1[i].selectedItem.rate40) != 0) {
                        tproductForeignChargesItem.push(tproductForeignChargesItem1[i]);
                        exists = true;
                    }
                }
                if (tproductForeignChargesItem1[i].selectedItem.min && !exists) {
                    if (Number(tproductForeignChargesItem1[i].selectedItem.min) != 0) {
                        tproductForeignChargesItem.push(tproductForeignChargesItem1[i]);
                        exists = true;
                    }
                }
                if (tproductForeignChargesItem1[i].selectedItem.remark != tproductForeignChargesItem1[i].remark)
                    tproductForeignChargesItem1[i].selectedItem.remark = tproductForeignChargesItem1[i].remark;
                if (tproductForeignChargesItem1[i].selectedItem.remark && !exists) {
                    if (tproductForeignChargesItem1[i].selectedItem.remark != "") {
                        tproductForeignChargesItem.push(tproductForeignChargesItem1[i]);
                    }
                }
            }
        }
        var tproductFreightChargesItem1 = [];
        var tproductFreightChargesItem2 = [];
        try {
            tproductFreightChargesItem2 = JSON.parse(JSON.stringify(productFreightChargesItem1))
            for (var i = 0; i < tproductFreightChargesItem2.length; i++) {
                delete tproductFreightChargesItem2[i].items;

                if (!tproductFreightChargesItem2[i].rate) {
                    tproductFreightChargesItem2[i].rate = tproductFreightChargesItem2[i].selectedItem.rate;
                }
                if (!tproductFreightChargesItem2[i].min) {
                    tproductFreightChargesItem2[i].min = tproductFreightChargesItem2[i].selectedItem.min;
                }
                if (!tproductFreightChargesItem2[i].remark) {
                    tproductFreightChargesItem2[i].remark = tproductFreightChargesItem2[i].remark ? tproductFreightChargesItem2[i].remark : tproductFreightChargesItem2[i].selectedItem.remark;
                }
                //var org = productFreightChargesItemOriginal.find(u => u.selectedItem.id === tproductFreightChargesItem1[i].selectedItem.id)
                //tproductFreightChargesItem1[i].org = org;
            }
        } catch (e) {
            var i = 0;

            for (var i = 0; i < productFreightChargesItem1.length; i++) {
                var obj1 = productFreightChargesItem1[i];
                var tF = removeCircularKey(obj1);
                delete tF.items;
                if (!tF.rate) {
                    tF.rate = tF.selectedItem.rate;
                }
                if (!tF.min) {
                    tF.min = tF.selectedItem.min;
                }
                if (!tF.remark) {
                    tF.remark = tF.remark ? tF.remark : tF.selectedItem.remark;
                }
                //deleteKey(obj1)
                tproductFreightChargesItem2.push(tF);
            }
        }
        if (value.shipmentType != 3) {
            for (var i = 0; i < tproductFreightChargesItem2.length; i++) {
                var exists = false;
                if (tproductFreightChargesItem2[i].selectedItem.rate) {
                    if (Number(tproductFreightChargesItem2[i].selectedItem.rate) != 0) {
                        tproductFreightChargesItem1.push(tproductFreightChargesItem2[i]);
                        exists = true;
                    }
                }
                if (tproductFreightChargesItem2[i].selectedItem.min && !exists) {
                    if (Number(tproductFreightChargesItem2[i].selectedItem.min) != 0) {
                        tproductFreightChargesItem1.push(tproductFreightChargesItem2[i]);
                        exists = true;
                    }
                }
                if (tproductFreightChargesItem2[i].selectedItem.remark != tproductFreightChargesItem2[i].remark)
                    tproductFreightChargesItem2[i].selectedItem.remark = tproductFreightChargesItem2[i].remark;

                if (tproductFreightChargesItem2[i].selectedItem.remark && !exists) {
                    if (tproductFreightChargesItem2[i].selectedItem.remark != "") {
                        tproductFreightChargesItem1.push(tproductFreightChargesItem2[i]);
                    }
                }
            }
        } else {
            for (var i = 0; i < tproductFreightChargesItem2.length; i++) {
                var exists = false;
                if (tproductFreightChargesItem2[i].selectedItem.rate20) {
                    if (Number(tproductFreightChargesItem2[i].selectedItem.rate20) != 0) {
                        tproductFreightChargesItem1.push(tproductFreightChargesItem2[i]);
                        exists = true;
                    }
                }
                if (tproductFreightChargesItem2[i].selectedItem.rate40 && !exists) {
                    if (Number(tproductFreightChargesItem2[i].selectedItem.rate40) != 0) {
                        tproductFreightChargesItem1.push(tproductFreightChargesItem2[i]);
                        exists = true;
                    }
                }
                if (tproductFreightChargesItem2[i].selectedItem.min && !exists) {
                    if (Number(tproductFreightChargesItem2[i].selectedItem.min) != 0) {
                        tproductFreightChargesItem1.push(tproductFreightChargesItem2[i]);
                        exists = true;
                    }
                }
                if (tproductFreightChargesItem2[i].selectedItem.remark != tproductFreightChargesItem2[i].remark)
                    tproductFreightChargesItem2[i].selectedItem.remark = tproductFreightChargesItem2[i].remark;

                if (tproductFreightChargesItem2[i].selectedItem.remark && !exists) {
                    if (tproductFreightChargesItem2[i].selectedItem.remark != "") {
                        tproductFreightChargesItem1.push(tproductFreightChargesItem2[i]);
                    }
                }
            }
        }
        var tproductLocalChargesItem1 = [];
        var tproductLocalChargesItem2 = [];
        try {
            tproductLocalChargesItem2 = JSON.parse(JSON.stringify(productLocalChargesItem1))

            for (var i = 0; i < tproductLocalChargesItem2.length; i++) {
                delete tproductLocalChargesItem2[i].items;

                if (!tproductLocalChargesItem2[i].rate) {
                    tproductLocalChargesItem2[i].rate = tproductLocalChargesItem2[i].selectedItem.rate;
                }
                if (!tproductLocalChargesItem2[i].min) {
                    tproductLocalChargesItem2[i].min = tproductLocalChargesItem2[i].selectedItem.min;
                }
                if (!tproductLocalChargesItem2[i].remark) {
                    tproductLocalChargesItem2[i].remark = tproductLocalChargesItem2[i].remark ? tproductLocalChargesItem2[i].remark : tproductLocalChargesItem2[i].selectedItem.remark;
                }
                //var org = productLocalChargesItemOriginal.find(u => u.selectedItem.id === tproductLocalChargesItem1[i].selectedItem.id)
                //tproductLocalChargesItem1[i].org = org;
            }


        } catch (e) {
            var i = 0;

            for (var i = 0; i < productLocalChargesItem1.length; i++) {
                var obj1 = productLocalChargesItem1[i];
                var tF = removeCircularKey(obj1);
                delete tF.items;
                if (!tF.rate) {
                    tF.rate = tF.selectedItem.rate;
                }
                if (!tF.min) {
                    tF.min = tF.selectedItem.min;
                }
                if (!tF.remark) {
                    tF.remark = tF.remark ? tF.remark : tF.selectedItem.remark;
                }
                tproductLocalChargesItem2.push(tF);
            }
        }
        if (value.shipmentType != 3) {
            for (var i = 0; i < tproductLocalChargesItem2.length; i++) {
                var exists = false;
                if (Number(tproductLocalChargesItem2[i].selectedItem.rate) != 0) {
                    if (tproductLocalChargesItem2[i].selectedItem.rate) {
                        tproductLocalChargesItem1.push(tproductLocalChargesItem2[i]);
                        exists = true;
                    }
                }
                if (tproductLocalChargesItem2[i].selectedItem.min && !exists) {
                    if (Number(tproductLocalChargesItem2[i].selectedItem.min) != 0) {
                        tproductLocalChargesItem1.push(tproductLocalChargesItem2[i]);
                        exists = true;
                    }
                }
                if (tproductLocalChargesItem2[i].selectedItem.remark != tproductLocalChargesItem2[i].remark)
                    tproductLocalChargesItem2[i].selectedItem.remark = tproductLocalChargesItem2[i].remark;

                if (tproductLocalChargesItem2[i].selectedItem.remark && !exists) {
                    if (tproductLocalChargesItem2[i].selectedItem.remark != "") {
                        tproductLocalChargesItem1.push(tproductLocalChargesItem2[i]);
                    }
                }
            }
        } else {
            for (var i = 0; i < tproductLocalChargesItem2.length; i++) {
                var exists = false;
                if (tproductLocalChargesItem2[i].selectedItem.rate20) {
                    if (Number(tproductLocalChargesItem2[i].selectedItem.rate20) != 0) {
                        tproductLocalChargesItem1.push(tproductLocalChargesItem2[i]);
                        exists = true;
                    }
                }
                if (tproductLocalChargesItem2[i].selectedItem.rate40 && !exists) {
                    if (Number(tproductLocalChargesItem2[i].selectedItem.rate40) != 0) {
                        tproductLocalChargesItem1.push(tproductLocalChargesItem2[i]);
                        exists = true;
                    }
                }
                if (tproductLocalChargesItem2[i].selectedItem.min && !exists) {
                    if (Number(tproductLocalChargesItem2[i].selectedItem.min) != 0) {
                        tproductLocalChargesItem1.push(tproductLocalChargesItem2[i]);
                        exists = true;
                    }
                }
                if (tproductLocalChargesItem2[i].selectedItem.remark != tproductLocalChargesItem2[i].remark)
                    tproductLocalChargesItem2[i].selectedItem.remark = tproductLocalChargesItem2[i].remark;

                if (tproductLocalChargesItem2[i].selectedItem.remark && !exists) {
                    if (tproductLocalChargesItem2[i].selectedItem.remark != "") {
                        tproductLocalChargesItem1.push(tproductLocalChargesItem2[i]);
                    }
                }
            }
        }
        var tproductCustomChargesItem1 = [];
        var tproductCustomChargesItem2 = [];
        try {
            tproductCustomChargesItem2 = JSON.parse(JSON.stringify(productCustomChargesItem1))

            for (var i = 0; i < tproductCustomChargesItem2.length; i++) {
                delete tproductCustomChargesItem2[i].items;
                if (!tproductCustomChargesItem2[i].rate) {
                    tproductCustomChargesItem2[i].rate = tproductCustomChargesItem2[i].selectedItem.rate;
                }
                if (!tproductCustomChargesItem2[i].min) {
                    tproductCustomChargesItem2[i].min = tproductCustomChargesItem2[i].selectedItem.min;
                }
                if (!tproductCustomChargesItem2[i].remark) {
                    tproductCustomChargesItem2[i].remark = tproductCustomChargesItem2[i].remark ? tproductCustomChargesItem2[i].remark : tproductCustomChargesItem2[i].selectedItem.remark;
                }
                //var org = productCustomChargesItemOriginal.find(u => u.selectedItem.id === tproductCustomChargesItem1[i].selectedItem.id)
                //tproductCustomChargesItem1[i].org = org;
            }

        } catch (e) {
            var i = 0;

            for (var i = 0; i < productCustomChargesItem1.length; i++) {
                var obj1 = productCustomChargesItem1[i];
                var tF = removeCircularKey(obj1);
                delete tF.items;
                if (!tF.rate) {
                    tF.rate = tF.selectedItem.rate;
                }
                if (!tF.min) {
                    tF.min = tF.selectedItem.min;
                }
                if (!tF.remark) {
                    tF.remark = tF.remark ? tF.remark : tF.selectedItem.remark;
                }
                tproductCustomChargesItem2.push(tF);
            }
        }
        if (value.shipmentType != 3) {
            for (var i = 0; i < tproductCustomChargesItem2.length; i++) {
                var exists = false;
                if (tproductCustomChargesItem2[i].selectedItem.rate) {
                    if (Number(tproductCustomChargesItem2[i].selectedItem.rate) != 0) {
                        tproductCustomChargesItem1.push(tproductCustomChargesItem2[i]);
                        exists = true;
                    }
                }
                if (tproductCustomChargesItem2[i].selectedItem.min && !exists) {
                    if (Number(tproductCustomChargesItem2[i].selectedItem.min) != 0) {
                        tproductCustomChargesItem1.push(tproductCustomChargesItem2[i]);
                        exists = true;
                    }
                }
                if (tproductCustomChargesItem2[i].selectedItem.remark != tproductCustomChargesItem2[i].remark)
                    tproductCustomChargesItem2[i].selectedItem.remark = tproductCustomChargesItem2[i].remark;

                if (tproductCustomChargesItem2[i].selectedItem.remark && !exists) {
                    if (tproductCustomChargesItem2[i].selectedItem.remark != "")
                        tproductCustomChargesItem1.push(tproductCustomChargesItem2[i]);
                }
            }
        } else {
            for (var i = 0; i < tproductCustomChargesItem2.length; i++) {
                var exists = false;
                if (tproductCustomChargesItem2[i].selectedItem.rate20) {
                    if (Number(tproductCustomChargesItem2[i].selectedItem.rate20) != 0) {
                        tproductCustomChargesItem1.push(tproductCustomChargesItem2[i]);
                        exists = true;
                    }
                }
                if (tproductCustomChargesItem2[i].selectedItem.rate40 && !exists) {
                    if (Number(tproductCustomChargesItem2[i].selectedItem.rate40) != 0) {
                        tproductCustomChargesItem1.push(tproductCustomChargesItem2[i]);
                        exists = true;
                    }
                }
                if (tproductCustomChargesItem2[i].selectedItem.min && !exists) {
                    if (Number(tproductCustomChargesItem2[i].selectedItem.min) != 0) {
                        tproductCustomChargesItem1.push(tproductCustomChargesItem2[i]);
                        exists = true;
                    }
                }
                if (tproductCustomChargesItem2[i].selectedItem.remark != tproductCustomChargesItem2[i].remark)
                    tproductCustomChargesItem2[i].selectedItem.remark = tproductCustomChargesItem2[i].remark;

                if (tproductCustomChargesItem2[i].selectedItem.remark && !exists) {
                    if (tproductCustomChargesItem2[i].selectedItem.remark != "") {
                        tproductCustomChargesItem1.push(tproductCustomChargesItem2[i]);
                    }
                }
            }
        }

        //console.log(JSON.parse(JSON.stringify(tproductForeignChargesItem)))
        var quoteType = 1;
        var from = null;
        var to = null;
        if (value.productType == 2 && value.shipmentType == 1) {
            quoteType = 2;
        }
        if (value.productType == 2 && value.shipmentType == 2) {
            quoteType = 4;
        }
        if (value.productType == 2 && value.shipmentType == 3) {
            quoteType = 6;
        }

        if (value.productType == 1 && value.shipmentType == 1) {
            quoteType = 1;
        }
        if (value.productType == 1 && value.shipmentType == 2) {
            quoteType = 3;
        }
        if (value.productType == 1 && value.shipmentType == 3) {
            quoteType = 5;
        }
        var flc20 = 0;
        if (value.flc20) {
            flc20 = value.flc20;
        }
        var flc40 = 0;
        if (value.flc40) {
            flc40 = value.flc40;
        }
        var flctype40 = 0;
        if (value.flcType40) {
            flctype40 = value.flcType40;
        }
        var flctype20 = 0;
        if (value.flcType20) {
            flctype20 = value.flcType20;
        }


        if (value.shipmentType == 1) {
            if (!value.from) {
                dispatch(snackbarToggle({ type: 'error', message: 'Please Select Airport of Departure' }));
                return;
            }
            if (!value.to) {
                dispatch(snackbarToggle({ type: 'error', message: 'Please Select Airport of Destination' }));
                return;
            }
        }
        if (value.shipmentType == 2 || value.shipmentType == 3) {
            if (!value.from) {
                dispatch(snackbarToggle({ type: 'error', message: 'Please Select POL' }));
                return;
            }
            if (!value.to) {
                dispatch(snackbarToggle({ type: 'error', message: 'Please Select POA' }));
                return;
            }
        }
        if (!value.incoId) {
            dispatch(snackbarToggle({ type: 'error', message: 'Please select Incoterm' }));
            return;
        }
        if (!value.serviceType) {
            dispatch(snackbarToggle({ type: 'error', message: 'Please select Service type' }));
            return;
        }
        if (!value.cutomerCrmId) {
            dispatch(snackbarToggle({ type: 'error', message: 'Please select Customer' }));
            return;
        }
        if (!value.countryId) {
            dispatch(snackbarToggle({ type: 'error', message: 'Please select Country' }));
            return;
        }
        var total = Number(getEstimatedTotalCost())?.toFixed(2)
        // var total = getTotalEsitmatedAirForeign() + getTotalEsitmatedAirFreight() + getTotalEsitmatedAirLocal() + getTotalEsitmatedAirCustome();
        if (!total) {
            total = 0;
        }
        var total20 = Number(getTotalEsitmatedAirForeign20()) + Number(getTotalEsitmatedAirFreight20()) + Number(getTotalEsitmatedAirLocal20()) + Number(getTotalEsitmatedAirCustome20());
        if (!total20) {
            total20 = 0;
        }
        var total40 = Number(getTotalEsitmatedAirForeign40()) + Number(getTotalEsitmatedAirFreight40()) + Number(getTotalEsitmatedAirLocal40()) + Number(getTotalEsitmatedAirCustome40());
        if (!total40) {
            total40 = 0;
        }
        var totalVolume = 0;
        var totalLable = "";
        if (value.shipmentType == 1) {
            totalVolume = getTotalCostChargeable();
            totalLable = "Chargeable Weight"
        } else if (value.shipmentType == 2) {
            totalVolume = getTotalCostWM();
            totalLable = "Total W/M"
        }
        var estimatedCharges = {}
        var estimatedCharges20 = {}
        var estimatedCharges40 = {}
        if (value.shipmentType !== 3) {
            estimatedCharges = {
                Estimated_Foreign_Charges: value.params.curForeignValue,
                Estimated_Foreign_Charges_CON: getValueByCur(value.params.curForeignValue,selectedCur),
                Estimated_Freight_Charges: value.params.curFreightValue,
                Estimated_Freight_Charges_CON: getValueByCur(value.params.curFreightValue, selectedCur),
                Estimated_Local_Charges: value.params.curLocalValue,
                Estimated_Local_Charges_CON: getValueByCur(value.params.curLocalValue, selectedCur),
                Estimated_Custom_Charges: value.params.curCustomValue,
                Estimated_Custom_Charges_CON: getValueByCur(value.params.curCustomValue, selectedCur),
                Estimated_Total: value.params.curPrice,
                Estimated_Total_CON: getValueByCur(value.params.curPrice, selectedCur)
            }
        } else {
            estimatedCharges20 = {
                Estimated_Foreign_Charges: value.params.curForeignValue20,
                Estimated_Foreign_Charges_CON: getValueByCur(value.params.curForeignValue20, selectedCur),
                Estimated_Freight_Charges: value.params.curFreightValue20,
                Estimated_Freight_Charges_CON: getValueByCur(value.params.curFreightValue20, selectedCur),
                Estimated_Local_Charges: value.params.curLocalValue20,
                Estimated_Local_Charges_CON: getValueByCur(value.params.curLocalValue20, selectedCur),
                Estimated_Custom_Charges: value.params.curCustomValue20,
                Estimated_Custom_Charges_CON: getValueByCur(value.params.curCustomValue20, selectedCur),
                Estimated_Total: flc20 ? (value.params.curPrice20) : "",
                Estimated_Total_CON: flc20 ? (value.params.curPrice20) : "",

            }
            estimatedCharges40 = {
                Estimated_Foreign_Charges: value.params.curForeignValue40,
                Estimated_Foreign_Charges_CON: getValueByCur(value.params.curForeignValue40, selectedCur),
                Estimated_Freight_Charges: value.params.curFreightValue40,
                Estimated_Freight_Charges_CON: getValueByCur(value.params.curFreightValue40, selectedCur),
                Estimated_Local_Charges: value.params.curLocalValue40,
                Estimated_Local_Charges_CON: getValueByCur(value.params.curLocalValue40, selectedCur),
                Estimated_Custom_Charges: value.params.curCustomValue40,
                Estimated_Custom_Charges_CON: getValueByCur(value.params.curCustomValue40, selectedCur),
                Estimated_Total: flc40 ? (value.params.curPrice40) : "",
                Estimated_Total_CON: flc40 ? (getValueByCur(value.params.curPrice40)) : "",

            }
        }
        var data = {
            "saveUserChanges": saveUserChanges,
            "quoteType": quoteType,
            "productType": value.productType,
            "shipmentType": value.shipmentType,
            "cutomerCrmId": value.cutomerCrmId,
            "countryId": value.countryId,
            "from": value.from,
            "to": value.to,
            "incoId": value.incoId,
            "currency": 1,
            "flc20": flc20,
            "flc40": flc40,
            "flctype40": flctype40,
            "flctype20": flctype20,
            "serviceType": value.serviceType,
            "contactPerson": value.contactPerson ? value.contactPerson : "",
            "pickupLocation": value.pickupLocation ? value.pickupLocation : "",
            "remarks": value.remarks ? value.remarks : "",
            "zip": value.zip ? value.zip : "",
            "shipmentDetails": costShipmentDetails,
            "foreignChargesItem": tproductForeignChargesItem,
            "freightChargesItem": tproductFreightChargesItem1,
            "localChargesItem": tproductLocalChargesItem1,
            "customChargesItem": tproductCustomChargesItem1,
            "total": total,
            "total20": total20,
            "total40": total40,
            params: {
                sum_total_weight: getTotalCostSum(),
                agentText: value.agentText,
                sum_total_volume: getTotalCostVolume(),
                totalVolume: totalVolume,
                /*originalPrice: getOriginalPrice(),
                originalPrice20: value.flc20 ? getOriginalPrice20() : 0,
                originalPrice40: value.flc40 ? getOriginalPrice40() : 0,*/
                originalPrice: value.params.originalPrice,
                originalPrice20: value.params.originalPrice20,
                originalPrice40: value.params.originalPrice40,
                curPrice: value.params.curPrice,
                curPrice20: value.params.curPrice20,
                curPrice40: value.params.curPrice40,
                orgForeignValue: value.params.orgForeignValue,
                orgForeignValue20: value.params.orgForeignValue20,
                orgForeignValue40: value.params.orgForeignValue40,
                curForeignValue: value.params.curForeignValue,
                curForeignValue20: value.params.curForeignValue20,
                curForeignValue40: value.params.curForeignValue40,
                orgFreightValue: value.params.orgFreightValue,
                orgFreightValue20: value.params.orgFreightValue20,
                orgFreightValue40: value.params.orgFreightValue40,
                curFreightValue: value.params.curFreightValue,
                curFreightValue20: value.params.curFreightValue20,
                curFreightValue40: value.params.curFreightValue40,
                orgCustomValue: value.params.orgCustomValue,
                orgCustomValue20: value.params.orgCustomValue20,
                orgCustomValue40: value.params.orgCustomValue40,
                curCustomValue: value.params.curCustomValue,
                curCustomValue20: value.params.curCustomValue20,
                curCustomValue40: value.params.curCustomValue40,
                orgLocalValue: value.params.orgLocalValue,
                orgLocalValue20: value.params.orgLocalValue20,
                orgLocalValue40: value.params.orgLocalValue40,
                curLocalValue: value.params.curLocalValue,
                curLocalValue20: value.params.curLocalValue20,
                curLocalValue40: value.params.curLocalValue40,
                margin: value.params.margin,
                margin20: value.params.margin20,
                margin40: value.params.margin40,

                totalLable: totalLable,
                selectedCur: selectedCur,
                estimatedCharges: estimatedCharges,
                estimatedCharges20: estimatedCharges20,
                estimatedCharges40: estimatedCharges40,
                finalCountry: countryList.find(u => u.id === value.finalCountryId),
                finalDest: value.finalDest,//value.params.countryDest.id
                countryDest: countryList.find(u => u.id === value.cdi),
                countryAirDest: countryList.find(u => u.id === value.cdi1),
                countryAirZip: value.countryAirZip,
                countryAirZipId: value.countryAirZipId,
                manualWeight: value.manualWeight,
                manualVolume: value.manualVolume,
               /* productCustomChargesItemOriginal: productCustomChargesItemOriginal,
                productLocalChargesItemOriginal: productLocalChargesItemOriginal,
                productFreightChargesItemOriginal: productFreightChargesItemOriginal,
                productForeignChargesItemOriginal: productForeignChargesItemOriginal,*/
            }
        }
        if (props?.data?.id) {
            data.id = props?.data?.id
            updateQS(dispatch, snackbarToggle, data, admin)
        } else {
            addQs(dispatch, snackbarToggle, data, admin)
        }


        props.onHandleCancel();
    }
    const [productType, setProductType] = useState(0);
    const handleExportImportChange = (v) => {
        setValue(prev => ({ ...prev, productType: v }))
        

        var t = []
        setListOfQuoatationCost(t)
        setProductCustomChargesItem1([]);
        setproductCustomChargesItemOriginal([]);
        setProductForeignCharges([])
        setProductLocalCharges1([])
        setProductFreightCharges1([])
        setProductForeignChargesItem([]);
        setProductFreightChargesItem1([]);
        setProductLocalChargesItem1([]);
        setproductLocalChargesItemOriginal([]);
        getQuote(value.shipmentType);
       /* getProductChargesByProdShipFreightOnLoad(value.shipmentType, v)
        getProductChargesByProdShipForeignOnload(value.shipmentType, v)
        getProductChargesByProdShipChargeOnLoad(value.shipmentType, v)
        getProductChargesByProdShipChargeCustomeChargesOnLoad(value.shipmentType, v)*/
        if (admin?.data?.user) {
            /* if (value.productType == 2 && value.shipmentType==1 && admin?.data?.user.permissions?.site == 1) {
                 handleAirPortOfDestination({ target: { value: listOfAirPort.find(u => u.name === 'TLV')?.id } })
             }
             if (value.productType == 2 && value.shipmentType == 1 && admin?.data?.user.permissions?.site == 2) {
                 handleAirPortOfLoading({ target: { value: listOfAirPort.find(u => u.name === 'AMS')?.id } })
             }
             if (value.productType == 1 && value.shipmentType == 1 && admin?.data?.user.permissions?.site == 1) {
                 handleAirPortOfLoading({ target: { value: listOfAirPort.find(u => u.name === 'TLV')?.id } })
             }
             if (value.productType == 1 && value.shipmentType == 1 && admin?.data?.user.permissions?.site == 2) {
                 handleAirPortOfDestination({ target: { value: listOfAirPort.find(u => u.name === 'AMS')?.id } })
             }*/
        }
    };

    const setDefCharges = () => {
        setoriginalPrice(0)
        setProductForeignChargesItem([])
        setProductFreightChargesItem1([])
        setProductLocalChargesItem1([])
        setProductCustomChargesItem1([])
        setproductCustomChargesItemOriginal([])
        setproductLocalChargesItemOriginal([])
        setproductFreightChargesItemOriginal([])
        setproductForeignChargesItemOriginal([])
        setProductFreightCharges1([])
    }
    const getCostUpdated = (callback) => {
        setDefCharges()

        setbLoadingLocal(true)
        setListOfFclQuotations([])
        setListOfLclQuoatationCost([])
        setListOfQuoatationCost([])
        setListOfLclQuoatationCost([])
        // dispatch(setAirExportAFs([]));
        window.setTimeout(function () {

            getAirExportAFs(dispatch, admin, function () {
                getLclExportImportAFs(dispatch, admin, function () {
                    getFclExportImportAFs(dispatch, admin, function () {
                        callback()
                        setbLoadingLocal(false)
                    });
                });
            });
        }, 10)


    }

    useEffect(() => {
        if (value.shipmentType && value.productType) {
            getProductChargesByProdShipFreightOnLoad(value.shipmentType, value.productType)
            getProductChargesByProdShipForeignOnload(value.shipmentType, value.productType)
            getProductChargesByProdShipChargeOnLoad(value.shipmentType, value.productType)
            getProductChargesByProdShipChargeCustomeChargesOnLoad(value.shipmentType, value.productType)
        }
    }, [value.shipmentType, value.productType]);
    useEffect(() => {
        if (value.to) {
            if (value.shipmentType == 1) {
                var p = listOfAirPort.find(u => u.id === value.to);
                var country = countryList.find(u => u.id === p.countryId);
                var params1 = { ...value.params, countryDest: country }
                setValue(prev => ({ ...prev, cdi: country.id }))
                setValue(prev => ({ ...prev, params: params1 }))
            }
            else {
                var p = listOfPort.find(u => u.id === value.to);
                var country = countryList.find(u => u.id === p.countryId);
                var params1 = { ...value.params, countryDest: country }
                setValue(prev => ({ ...prev, cdi: country.id }))
                setValue(prev => ({ ...prev, params: params1 }))
            }
        }
    }, [value.to]);

    /**/useEffect(() => {
        //console.log(productForeignChargesItem) 
        //console.log("-----")
        getProductChargesByProdShipForeignOnload(value.shipmentType, value.productType)
    }, [productForeignChargesItem]);

    useEffect(() => {

        getAirExportAFs(dispatch, admin, function () {

        });
        getLclExportImportAFs(dispatch, admin, function () {

        });
        getFclExportImportAFs(dispatch, admin, function () {

        });
        if (!isLoaded && shipmentTypesOpt.length && productCategoriesOpt.length) {

            if (productForeignCharges.length == 0)
                getProductChargesByProdShipForeignCharges1()
            if (productFreightCharges1.length == 0)
                getProductChargesByProdShipFreightCharges()
            if (productLocalCharges1.length == 0)
                getProductChargesByProdShipChargeLocalCharges()
            if (productCustomCharges2.length == 0)
                getProductChargesByProdShipChargeCustomeCharges()
            if (currentPage != 'addQs') {
                dispatch(setCurrentPage("addQs"));

            }
            listOfUpdatedCrm = [...crmList]
            if (!value.id) {
                var s = serviceTypeOpt?.find(u => u.name === 'General Cargo')
                setValue(prev => ({ ...prev, serviceType: s?.id }));
            }
            getCRMList(dispatch, admin);
            setIsLoaded(true);
            if (!value.id) {
                setValue(prev => ({ ...prev, shipmentType: shipmentTypesOpt[0].id }))
                setValue(prev => ({ ...prev, productType: productCategoriesOpt[1].id }))
                getProductChargesByProdShipFreightOnLoad(shipmentTypesOpt[0].id, productCategoriesOpt[1].id)
                getProductChargesByProdShipForeignOnload(shipmentTypesOpt[0].id, productCategoriesOpt[1].id)
                getProductChargesByProdShipChargeOnLoad(shipmentTypesOpt[0].id, productCategoriesOpt[1].id)
                getProductChargesByProdShipChargeCustomeChargesOnLoad(shipmentTypesOpt[0].id, productCategoriesOpt[1].id)
            }
            if (value.id) {
                if (props.data.params?.selectedCur) {
                    setSelectedCur(props.data.params?.selectedCur)
                }
                var flcType20Obj = FLCTypes20pt?.find(u => u.name === props.data.flcType20)
                if (flcType20Obj) {
                    setValue(prev => ({ ...prev, flcType20: flcType20Obj.id }))
                }
                var flcType40Obj = FLCTypes40pt?.find(u => u.name === props.data.flcType40)
                if (flcType40Obj) {
                    setValue(prev => ({ ...prev, flcType40: flcType40Obj.id }))
                }

                getProductChargesByProdShipForeignEdit(value.shipmentType,value.productType)
                getProductChargesByProdShipChargeEdit(value.shipmentType,value.productType)
                getProductChargesByProdShipFreightEdit(value.shipmentType,value.productType)
                getProductChargesByProdShipChargeCustomeChargesEdit(value.shipmentType,value.productType)
                
                setbLoadingLocal(false);

            }
        }

        if (admin?.data?.user) {
        }
        getCRMsForMail(dispatch, admin, function (data) {
            setcrmsForMail(data)
        });
    }, []);

    const getFrom = () => {
        var from = ""
        if (value.shipmentType == 1) {
            from = listOfAirPort.find(u => u.id === value.from)?.name;
        } else {
            from = listOfPort.find(u => u.id === value.from)?.name;
        }
        return from;
    }
    const getTo = () => {
        var from = ""
        if (value.shipmentType == 1) {
            from = listOfAirPort.find(u => u.id === value.to)?.name;
        } else {
            from = listOfPort.find(u => u.id === value.to)?.name;
        }
        return from;
    }
    const [openOrderModalCost, setOpenOrderModalCost] = useState(false);
    const onHandleOpenModalCost = () => {
        setOpenOrderModalCost(true);
    };

    const onHandleCancelModalCost = () => setOpenOrderModalCost(false);


    const [openOrderModalZip, setOpenOrderModalZip] = useState(false);
    const onHandleOpenModalZip = () => {
        setOpenOrderModalZip(true);
    };

    const onHandleCancelModalZip = () => setOpenOrderModalZip(false);


    const [unusualDimentionMsg, setUnusualDimentionMsg] = useState("");
    const [openOrderModalDimention, setOpenOrderModalDimention] = useState(false);
    const onHandleOpenModalDimention = (val) => {
       // setUnusualDimentionMsg(msg)
        // setOpenOrderModalDimention(true);
        var length = 0;
        var height = 0;
        var width = 0;
        if (value.shipmentType == 1) {
            length = 300;
            height = 295;
            width = 230;
        } else if (value.shipmentType == 2) {
            length = 350;
            height = 230;
            width = 230;
        }
        if (val?.length > length) {
            setUnusualDimentionMsg("Abnormal length. Max allowed length is " + length +" cm.");
            setOpenOrderModalDimention(true);
            return true;
        }
        if (val?.height > height) {
            setUnusualDimentionMsg("Abnormal height. Max allowed height is " + height +" cm.");
            setOpenOrderModalDimention(true);
            return true;
        }
        if (val?.width > width) {
            setUnusualDimentionMsg("Abnormal width. Max allowed width is " + width +" cm.");
            setOpenOrderModalDimention(true);
            return true;
        }
        return false;
    };

    const onHandleCancelModalDimention = () => { setOpenOrderModalDimention(false);  }


    const [openOrderModalProfit, setOpenOrderModalProfit] = useState(false);
    const onHandleOpenModalProfit = () => {
        setOpenOrderModalProfit(true);
    };

    const onHandleCancelModalProfit = () => setOpenOrderModalProfit(false);

    const [openOrderModalLclCost, setOpenOrderModalLclCost] = useState(false);
    const onHandleOpenModalLclCost = () => {
        setOpenOrderModalLclCost(true);
    };

    const onHandleCancelModalLclCost = () => setOpenOrderModalLclCost(false);


    const handleChange = (event) => {
    };

    const [openOrderModalCloneFcl, setOpenOrderModalCloneFcl] = useState(false);
    const onHandleOpenModalCloneFcl = () => {
        setOpenOrderModalCloneFcl(true);
    };
    const onHandleCancelModalCloneFcl = () => setOpenOrderModalCloneFcl(false);

    const [openOrderModalMail, setOpenOrderModalMail] = useState(false);
    const onHandleOpenModalMail = () => {
        setOpenOrderModalMail(true);
    };
    const onHandleCancelModalMail = () => setOpenOrderModalMail(false);


    const [openOrderModalMailAgent, setOpenOrderModalMailAgent] = useState(false);
    const onHandleOpenModalMailAgent = () => {
        setOpenOrderModalMailAgent(true);
    };
    const onHandleCancelModalMailAgent = () => setOpenOrderModalMailAgent(false);

    const [failedMailList, setfailedMailList] = useState("");
    const [succesMailList, setsuccesMailList] = useState("");
    const [message, setMessage] = useState("");
    const onHandleNewEmptyProduct = function () {
        setNewEmptyProductError(false);
    }

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 'none' }} key={refreshView}>
            <Grid item container justifyContent='start' lg={12} style={{ paddingLeft: "6vw", paddingRight: "6vw", background: "#eef0f3" }}>
                <Button variant='contained' sx={{ mt: '12px', ml: 3, mb: '26px', height: '4vh', background: "#dcdfe3" }} color='secondary' onClick={function () { navigate("/menu/qs"); props.onHandleCancel() }}>
                    <FaAngleLeft style={{ marginLeft: -4 }} /> &nbsp;Back  {/*s: {value.shipmentType} p:  {value.productType}*/}
                </Button>
                {value.productType == 1 && value.shipmentType == 1 && <Grid sx={{ ml: 2, mt: "7px" }} item container justifyContent='start' lg={4}> <img src={air_imp_2} width="40" height="40" /><Grid sx={{ ml: 2 }} justifyContent='start' item container lg={4}><span>Quotation: <b>{value.id}</b>  </span><span style={{ ml: 2, mt: -1, color: 'black' }}><Typography fontSize="1rem" fontWeight='bold'> {shipmentTypesOpt.find(u => u.id === value.shipmentType)?.name || '-'} {listOfProductCategories.find(u => u.id === value.productType)?.lable || '-'}</Typography></span ></Grid></Grid>}
                {value.productType == 2 && value.shipmentType == 1 && <Grid sx={{ ml: 2, mt: "7px" }} item container justifyContent='start' lg={4}> <img src={air_imp} width="40" height="40" /> <Grid sx={{ ml: 2 }} justifyContent='start' item container lg={4}><span>Quotation: <b>{value.id}</b>  </span><span style={{ ml: 2, mt: -1, color: 'black' }}><Typography fontSize="1rem" fontWeight='bold'>{shipmentTypesOpt.find(u => u.id === value.shipmentType)?.name || '-'} {listOfProductCategories.find(u => u.id === value.productType)?.lable || '-'}</Typography></span ></Grid></Grid>}
                {value.productType == 1 && value.shipmentType == 2 && <Grid sx={{ ml: 2, mt: "7px" }} item container justifyContent='start' lg={4}> <img src={air_imp_7} width="40" height="40" /> <Grid sx={{ ml: 2 }} justifyContent='start' item container lg={4}><span>Quotation: <b>{value.id}</b>  </span><span style={{ ml: 2, mt: -1, color: 'black' }}><Typography fontSize="1rem" fontWeight='bold'>{shipmentTypesOpt.find(u => u.id === value.shipmentType)?.name || '-'} {listOfProductCategories.find(u => u.id === value.productType)?.lable || '-'}</Typography></span ></Grid></Grid>}
                {value.productType == 2 && value.shipmentType == 2 && <Grid sx={{ ml: 2, mt: "7px" }} item container justifyContent='start' lg={4}> <img src={air_imp_8} width="40" height="40" /> <Grid sx={{ ml: 2 }} justifyContent='start' item container lg={4}><span>Quotation: <b>{value.id}</b>  </span><span style={{ ml: 2, mt: -1, color: 'black' }}><Typography fontSize="1rem" fontWeight='bold'>{shipmentTypesOpt.find(u => u.id === value.shipmentType)?.name || '-'} {listOfProductCategories.find(u => u.id === value.productType)?.lable || '-'}</Typography></span ></Grid></Grid>}
                {value.productType == 1 && value.shipmentType == 3 && <Grid sx={{ ml: 2, mt: "7px" }} item container justifyContent='start' lg={4}> <img src={air_imp_5} width="40" height="40" /> <Grid sx={{ ml: 2 }} justifyContent='start' item container lg={4}><span>Quotation: <b>{value.id}</b>  </span><span style={{ ml: 2, mt: -1, color: 'black' }}><Typography fontSize="1rem" fontWeight='bold'>{shipmentTypesOpt.find(u => u.id === value.shipmentType)?.name || '-'} {listOfProductCategories.find(u => u.id === value.productType)?.lable || '-'}</Typography></span ></Grid></Grid>}
                {value.productType == 2 && value.shipmentType == 3 && <Grid sx={{ ml: 2, mt: "7px" }} item container justifyContent='start' lg={4}> <img src={air_imp_6} width="40" height="40" /> <Grid sx={{ ml: 2 }} justifyContent='start' item container lg={4}><span>Quotation: <b>{value.id}</b>  </span><span style={{ ml: 2, mt: -1, color: 'black' }}><Typography fontSize="1rem" fontWeight='bold'>{shipmentTypesOpt.find(u => u.id === value.shipmentType)?.name || '-'} {listOfProductCategories.find(u => u.id === value.productType)?.lable || '-'}</Typography></span ></Grid></Grid>}


            </Grid>
            {(bLoadingCustom || bLoadingForeign || bLoadingFrieght || bLoadingLocal) && < div className="mailLoader"> <LoadingSpinner /></div>}


            { originalCostErrorDlg && (
                <Dialog maxWidth='md' open={originalCostErrorDlg} title={'Original Cost Error'} onHandleCancel={hideOriginalCostError}>
                    <Grid container item lg={12} justifyContent="center">
                        <p>An empty Original Price exists, please fill all the values.</p>
                    </Grid>
                </Dialog>
            )}
            {newEmptyProductError && (
                <Dialog maxWidth='md' open={newEmptyProductError} title={'Add Product Error'} onHandleCancel={onHandleNewEmptyProduct}>
                    <Grid container item lg={12} justifyContent="center">
                        <p>An empty Product already esists in {newEmptyProductMsg}</p>
                    </Grid>
                </Dialog>
            )}
            {openOrderModalZip && (
                <Dialog maxWidth='md' open={openOrderModalZip} title={'Zip info'} onHandleCancel={onHandleCancelModalZip}>
                    <Grid container item lg={12} justifyContent="center">
                        <Grid container item lg={12} style={{ fontWeight: "bold", margin:"10px" }}>
                            <Grid container item lg={12}>
                                { `01 -        ירושלים`}<br/>
                                { `02 -        בית-שמש  מודיעין`}<br/>
                                { `03 -        קריית-גת קריית-מלאכי     אור-עקיבא עמק-חפר קיסריה אשקלון חדרה`}<br/>
                                { `04 -        פתח-תקווה  ראש-העין לוד רמלה    אור-יהודה יהוד אזור משמר-השבעה קריית אונו בני-ברק ראשון-לציון    בת-ים    חולון       תל אביב`}<br/>
                                { `05 -        נתניה    קדימה   כנות    יבנה    אשדוד    רחובות  רעננה    כפר-סבא הרצליה רמת-השרון הוד-השרון      כוכב-יאיר נס-ציונה`}<br/>
                                { `06 -        באר שבע נתיבות שדרות  עומר   אופקים   רביבים `}<br/>
                                { `07 -        דימונה   מישור-רותם ערד ירוחם `}<br/>
                                { `08 -        בנימינה  נצרת חיפה קריות עפולה  יקנעםמגדל-העמק `}<br/>
                                { `09 -        כרמיאל  עכו משגב נהריה`}<br/>
                                { `10 -        קרית-שמונה מעלות טבריה בית-שאן ראש-פינה צפת`}<br/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Dialog>
            )}
            {openOrderModalDimention && (
                <Dialog style={{zIndex:"999"}} open={openOrderModalDimention} title={'Unusual dimensions'} onHandleCancel={onHandleCancelModalDimention}>
                    <Grid container item lg={12} justifyContent="flex-start">
                        <Grid container item lg={12} style={{ fontWeight: "bold",width:"25vw" }}>
                            {unusualDimentionMsg}
                        </Grid>
                        <Grid container item lg={12} style={{ fontWeight: "bold", width: "25vw" }} justifyContent="flex-end">
                            <Button onClick={function () {
                                addQuoatation(false);
                            }}>Save</Button>
                        </Grid>
                    </Grid>
                </Dialog>
            )}
            {openOrderModalProfit && (
                <Dialog maxWidth='md' open={openOrderModalProfit} title={'Profit'} onHandleCancel={onHandleCancelModalProfit}>
                    <Grid container item lg={12} justifyContent="center">    
                        {value.shipmentType != 3 && <Grid container item lg={12} justifyContent="center">
                            Margin [{getLableByCur(selectedCur)}]: {(getValueByCur(value.params?.margin, selectedCur))}
                        </Grid>}

                        {value.shipmentType == 3 && <Grid container item lg={12} justifyContent="center">
                            Margin 20 [{getLableByCur(selectedCur)}]: {(getValueByCur(value.params?.margin20, selectedCur))}
                        </Grid>}
                        {value.shipmentType == 3 && <Grid container item lg={12} justifyContent="center">
                            Margin 40 [{getLableByCur(selectedCur)}]: {(getValueByCur(value.params?.margin40, selectedCur))}
                        </Grid>
                        }
                        <Grid container item lg={12} justifyContent="center" sx={{ mt: 4 }} alignItems="center">
                            <FormControlLabel control={<Checkbox checked={saveUserChanges} onChange={e => setSaveUserChanges(!saveUserChanges) } />} label="Overwrite user ownership?" /> 
                        </Grid>
                        <Grid container item lg={12} justifyContent="center" sx={{ mt: 4 }}>
                            <Button onClick={function () {
                                addQuoatation(true);
                            }}>Save</Button>
                        </Grid>
                    </Grid>
                </Dialog>
            )}


            <Dialog open={openOrderModal} title={"Import / Export"} onHandleCancel={onHandleCancelModal}>
                <Grid style={{ width: '30vw' }} container justifyContent='space-between' lg={12} >

                    <Grid onClick={function () { handleExportImportChange(2) }} sx={{ border: 1, mt: 2, background: (value.productType == 1 ? '#80808099' : ''), padding: 0, margin: 0, mr: 1, height: '260px' }} item container justifyContent='start' lg={5.6}>
                        <Grid sx={{ padding: '0px !important', height: '50px', margin: 0 }} item container justifyContent='center' lg={12}>
                            <Button variant='contained' sx={{ width: '100% !important', mt: 0, mr: 0, background: (value.productType == 1 ? '#7a9cc3' : '') }} className={value.productType == 2 ? 'import_export_dlg_lable import' : 'import_export_dlg_lable_disabled import'}>
                                Import
                            </Button>
                        </Grid>
                        <Grid sx={{ padding: 0, margin: 0 }} item container justifyContent='center' lg={12}>
                            <RadioGroup
                                disabled={value.productType == 1}
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={value.shipmentType}
                                onChange={handleShipmentType}
                            >
                                {
                                    shipmentTypesOpt.map(qs => (
                                        <FormControlLabel disabled={value.productType == 1} style={{ fontSize: '0.8rem', maxWidth: '4vw', padding: '5px' }} value={qs.id} key={qs.id} control={<Radio color={'info'} />} label={qs.name} />
                                    ))
                                }
                            </RadioGroup>
                        </Grid>
                    </Grid>
                    <Grid onClick={function () { handleExportImportChange(1) }} sx={{ border: 1, mt: 2, background: (value.productType == 2 ? '#80808099' : ''), padding: 0, margin: 0, mr: 1, height: '260px' }} container justifyContent='start' lg={5.6}>
                        <Grid sx={{ padding: '0px !important', height: '50px', margin: 0 }} item container justifyContent='center' lg={12}>
                            <Button variant='contained' sx={{ width: '100% !important', mt: 0, mr: 0, background: (value.productType == 2 ? '#7a9cc3' : '') }} className={value.productType == 1 ? 'import_export_dlg_lable export' : 'import_export_dlg_lable_disabled export'}>
                                Export
                            </Button>
                        </Grid>
                        <Grid sx={{ padding: 0, margin: 0 }} item container justifyContent='center' lg={12}>


                            <RadioGroup
                                disabled={value.productType == 2}
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={value.shipmentType}
                                onChange={handleShipmentType}
                            >
                                {
                                    shipmentTypesOpt.map(qs => (
                                        <FormControlLabel disabled={value.productType == 2} style={{ fontSize: '0.8rem', maxWidth: '4vw', padding: '5px' }} value={qs.id} key={qs.id} control={<Radio color={'info'} />} label={qs.name} />
                                    ))
                                }
                            </RadioGroup>
                        </Grid>
                    </Grid>
                </Grid>
                {<Grid container item justifyContent='center' lg={12} sx={{ mt: 5 }}>
                    <Button variant='contained' sx={{ mt: 2, mr: 2 }} onClick={onHandleCancelModal} className="importExportBtn">
                        Create
                    </Button>
                </Grid>}
            </Dialog>
            <Grid container item spacing={2} lg={12} justifyContent='flex-start' style={{ border: 'none', boxShadow: 'none', paddingRight: "2vw", paddingLeft: "2vw", marginTop: "10px" }}>
                <TableContainer>
                    <Table>

                        <TableBody>
                            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                                <TableCell>
                                    <Typography variant='h7'>

                                    </Typography>
                                </TableCell>
                                <TableCell style={{ borderBottom: 0, display: 'flex', maxHeight: (value.shipmentType != 3 ? '60vh' : '70vh'), width: '40vw', float: 'left' }}>
                                    <Grid container justifyContent='flex-start' lg={12} spacing={2} style={{ overflow: 'auto', width: '42vw', marginTop: "-13px", marginLeft: 2, verticalAlign: 'top', borderRadius: 5 }}>

                                        <Grid container justifyContent='flex-start' lg={6} spacing={2} sx={{ ml: 1, mt: "1px" }} alignContent="flex-start">
                                            {false && <Autocomplete
                                                required
                                                value={value.productType}
                                                lg={6}
                                                //onChange={event => setValue(prev => ({ ...prev, productType: event.target.value }))}
                                                onChange={handleProductType}
                                                label='Product Category'
                                                options={productCategoriesOpt}
                                            />}
                                            {false && <Autocomplete
                                                required
                                                value={value.shipmentType}
                                                lg={6}
                                                //onChange={event => setValue(prev => ({ ...prev, shipmentType: event.target.value }))}
                                                onChange={handleShipmentType}
                                                label='Shipment Type'
                                                options={shipmentTypesOpt}
                                            />
                                            }
                                            <Autocomplete
                                                required
                                                value={value.cutomerCrmId}
                                                lg={11}
                                                onChange={event =>
                                                    setValue(prev => ({ ...prev, cutomerCrmId: event.target.value }))
                                                }
                                                label='Customer'
                                                options={getCRMsByFunctionTypes([1, 2])}
                                            />
                                            {/*<Autocomplete
                                                required
                                                value={value.cutomerCrmId}
                                                lg={11}
                                                onChange={event => setValue(prev => ({ ...prev, cutomerCrmId: event.target.value }))}
                                                label='Customer'
                                                options={getCRMsByFunctionTypes([1, 2])}
                                            />*/}

                                            <Autocomplete
                                                required
                                                value={value.incoId}
                                                lg={11}
                                                onChange={event => setValue(prev => ({ ...prev, incoId: event.target.value }))}
                                                label='Incoterms'
                                                options={incotermsOpt}
                                            />

                                            {value.shipmentType == 1 && <Autocomplete
                                                required
                                                value={value.from}
                                                lg={11}
                                                onChange={handleAirPortOfLoading}
                                                label='Airport of Departure'
                                                options={listOfAirPort}
                                            />
                                            }
                                            {(value.shipmentType == 2 || value.shipmentType == 3) && <Autocomplete
                                                required
                                                value={value.from}
                                                lg={11}
                                                onChange={handlePortOfLoading}
                                                label='Port Of Loading'
                                                options={listOfPort}
                                            />
                                            }
                                            <Autocomplete
                                                required
                                                value={value.countryId}
                                                lg={11}
                                                onChange={event => setValue(prev => ({ ...prev, countryId: event.target.value }))}
                                                label='Country Of Loading'
                                                options={countryList}
                                            />

                                            <Autocomplete
                                                value={value.finalCountryId}
                                                lg={11}
                                                onChange={event => setValue(prev => ({ ...prev, finalCountryId: event.target.value }))}
                                                label='Pick-up country'
                                                options={countryList}
                                            />

                                            <Grid container item lg={11} alignItems="center" justifyContent="space-between">

                                                <TextField
                                                    lg={value.productType == 1 ? 10.8 : 12}
                                                    value={value.zip}
                                                    onChange={event => setValue(prev => ({ ...prev, zip: event.target.value }))}
                                                    label='Pick-up zip code'
                                                />
                                                {value.productType == 1 && <FaInfoCircle style={{ cursor: "pointer", width: "20px", height: "20px" }} onClick={() => { onHandleOpenModalZip() }} />}
                                            </Grid>

                                            {(value.shipmentType == 3) && <Autocomplete
                                                required
                                                value={value.flcType40}
                                                lg={11}
                                                onChange={event => setValue(prev => ({ ...prev, flcType40: event.target.value }))}
                                                label='FCL Types 40'
                                                options={FLCTypes40pt}
                                            />
                                            }

                                            {(value.shipmentType == 3) &&

                                                <Autocomplete
                                                    required
                                                    value={value.flcType20}
                                                    lg={11}

                                                    onChange={event => setValue(prev => ({ ...prev, flcType20: event.target.value }))}
                                                    label='FCL Types 20'
                                                    options={FLCTypes20pt}
                                                />
                                            }
                                            <TextField
                                                lg={11}
                                                value={value.pickupLocation}
                                                onChange={event => setValue(prev => ({ ...prev, pickupLocation: event.target.value }))}
                                                label='Pick-up address'
                                            />
                                            {value.productType == 1 && value.shipmentType == 1 && < TextField
                                                lg={11}
                                                value={value.remarks}
                                                onChange={event => setValue(prev => ({ ...prev, remarks: event.target.value }))}
                                                label='Remarks'
                                            />}
                                        </Grid>
                                        <Grid container justifyContent='flex-start' lg={6} spacing={2} alignContent="flex-start" sx={{ mt: "1px" }}>
                                            <TextField
                                                lg={11}
                                                value={value.contactPerson}
                                                onChange={event => setValue(prev => ({ ...prev, contactPerson: event.target.value }))}
                                                label='Contact'
                                            />
                                            <Autocomplete
                                                required
                                                value={value.serviceType}
                                                lg={11}
                                                onChange={event => setValue(prev => ({ ...prev, serviceType: event.target.value }))}
                                                label='Shipment Type'
                                                options={serviceTypeOpt}
                                            />
                                            {value.shipmentType == 1 && <Autocomplete
                                                required
                                                value={value.to}
                                                lg={11}
                                                onChange={handleAirPortOfDestination}
                                                label='Airport of Arrival'
                                                options={listOfAirPort}
                                            />}
                                            {(value.shipmentType == 1 && (value.productType == 1)) && <Autocomplete
                                                required
                                                value={value.cdi}
                                                lg={11}
                                                onChange={handleCountryParams}
                                                label='Country of Destination'
                                                options={countryList}
                                            />
                                            }


                                            {(value.shipmentType == 2 || value.shipmentType == 3) && <Autocomplete
                                                required
                                                value={value.to}
                                                lg={11}
                                                onChange={event => {
                                                    setValue(prev => ({ ...prev, to: event.target.value }))
                                                    window.setTimeout(function () {

                                                        var p = listOfPort.find(u => u.id === event.target.value);
                                                        var country = countryList.find(u => u.id === p.countryId);
                                                        var params1 = { ...value.params, countryDest: country }
                                                        setValue(prev => ({ ...prev, cdi: country.id }))
                                                        setValue(prev => ({ ...prev, params: params1 }))
                                                        
                                                    }, 500)
                                                }}
                                                label='Port Of Discharge'
                                                options={listOfPort}
                                            />
                                            }
                                            {(value.shipmentType == 2 || value.shipmentType == 3 || value.productType == 2) && < TextField
                                                lg={11}
                                                value={value.remarks}
                                                onChange={event => setValue(prev => ({ ...prev, remarks: event.target.value }))}
                                                label='Remarks'
                                            />}
                                            {<Autocomplete
                                                required
                                                value={value.cdi}
                                                lg={11}
                                                onChange={handleCountryParams}
                                                label='Destination Country'
                                                options={countryList}
                                            />
                                            }
                                            {/*<Grid container item lg={11} alignItems="center" justifyContent="space-between">

                                                <TextField
                                                    lg={value.productType == 2 ? 10.8 : 12}
                                                    value={value.countryAirZipId}
                                                    onChange={event => setValue(prev => ({ ...prev, countryAirZipId: event.target.value }))}
                                                    label='Delivery Address'
                                                />
                                                
                                                {value.productType == 2 && <FaInfoCircle style={{ cursor: "pointer", width: "20px", height: "20px" }} onClick={() => { onHandleOpenModalZip() }} />}
                                            </Grid>*/}

                                            <TextField
                                                lg={11}
                                                value={value.finalDest}
                                                onChange={event => setValue(prev => ({ ...prev, finalDest: event.target.value }))}
                                                label='Delivery Address'
                                            />
                                            {(value.shipmentType == 3) && <TextField
                                                lg={11}
                                                value={value.flc40}
                                                onChange={event => onChangeRate40(event.target.value)}
                                                label='Quantity 40'
                                            />}
                                            {(value.shipmentType == 3) && <TextField
                                                lg={11}
                                                value={value.flc20}
                                                onChange={event => onChangeRate20(event.target.value)}
                                                label='Quantity 20'
                                            />}
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                {value.shipmentType != 3 && <TableCell style={{ borderBottom: 0, display: 'flex', maxHeight: '60vh', width: '52vw', float: 'left', marginTop: "-13px" }}>
                                    <Grid container lg={12} style={{ verticalAlign: 'top', borderRadius: 5, width: '42vw', overflow: 'auto', marginTop: 9 }}>
                                        {(props.view === 'VIEW' && !bEdit) ? <Grid container item justifyContent='flex-start' lg={12} spacing={2}>
                                            <h3 style={{ marginLeft: "40%", width: '100%' }}>
                                                <label >The Quotation:
                                                </label>
                                            </h3>

                                            <TextField
                                                lg={12}
                                                value={value.id}
                                                label='Id'
                                                disabled={true}
                                            />
                                            <TextField
                                                lg={12}
                                                value={qsStatusList.find(u => u.id === value.status)?.lable || '-'}
                                                label='Status:'
                                                disabled={true}
                                            />
                                            <h3 style={{ marginLeft: "40%", width: '100%' }}>
                                                <label >The Quotation was framed by:
                                                </label>
                                            </h3>

                                            <TextField
                                                lg={12}
                                                value={adminList.find(u => u.id === value.userId)?.adminName || '-'}
                                                label='Name:'
                                                disabled={true}
                                            />

                                            <TextField
                                                lg={12}
                                                value={adminList.find(u => u.id === value.userId)?.phone || '-'}
                                                label='Telephone:'
                                                disabled={true}
                                            />

                                            <TextField
                                                lg={12}
                                                value={adminList.find(u => u.id === value.userId)?.email || '-'}
                                                label='E-mail:'
                                                disabled={true}
                                            />
                                            <label style={{ width: '20px', height: '62px' }}>
                                            </label>
                                        </Grid> : <Grid container item justifyContent='flex-end' lg={12}>


                                            <Grid key={uuidv4()} container item justifyContent='flex-end' lg={12}>
                                                {openOrderModalCost && (
                                                    <Dialog maxWidth='md' open={openOrderModalCost} title={listOfProductCategories.find(u => u.id === value.productType)?.lable} onHandleCancel={onHandleCancelModalCost}>
                                                        <AddAirCost setAirImportExport={() => {

                                                        }}
                                                            callBack={() => {
                                                                getAirExportAFs(dispatch, admin, function () {
                                                                    const elem = document.getElementById('getCostQuoat');
                                                                    elem?.click();
                                                                });


                                                                }} typeData={listOfProductCategories.find(u => u.id === value.productType)} data={{
                                                                    id: null,
                                                                    clAgent: null,
                                                                    countryId: null,
                                                                    createTime: null,
                                                                    crmId: null,
                                                                    importExportType: null,
                                                                    min: null,
                                                                    fromId: value.from,
                                                                    toId: value.to,
                                                                    rateList: null,
                                                                    rate: null,
                                                                    tt: null,
                                                                    updateTime: null,
                                                                    validDate: null,
                                                                    validfrom: null,
                                                                    weightList: null,
                                                                    weight: getTotalCostChargeableAir(),
                                                                    zip: null,
                                                                    product: null,
                                                                    unitOfMeasure: null,
                                                                    currency: null,
                                                                    remark: null,
                                                                    params: null,
                                                                    weightList: null,
                                                                }} isAddFromQsCostTable={true} importExportType={value.productType} onHandleCancel={() => setOpenOrderModalCost(false)} id={null} airImportExport={null} />
                                                    </Dialog>
                                                )}

                                                {openOrderModalLclCost && (
                                                    <Dialog maxWidth='md' open={openOrderModalLclCost} title={listOfProductCategories.find(u => u.id === value.productType)?.lable} onHandleCancel={onHandleCancelModalLclCost}>
                                                            <AddLclCost setAirImportExport={() => {

                                                            }}
                                                                callBack={() => {
                                                                    getLclExportImportAFs(dispatch, admin, function () {
                                                                        const elem = document.getElementById('getCostQuoat');
                                                                        elem?.click();
                                                                    });


                                                                }} typeData={listOfProductCategories.find(u => u.id === value.productType)} data={{
                                                                    id: null,
                                                                    clAgent: null,
                                                                    countryId: null,
                                                                    createTime: null,
                                                                    crmId: null,
                                                                    importExportType: null,
                                                                    min: null,
                                                                    portLid: value.from,
                                                                    portAid: value.to,
                                                                    rateList: null,
                                                                    rate: null,
                                                                    tt: null,
                                                                    updateTime: null,
                                                                    validDate: null,
                                                                    validfrom: null,
                                                                    weightList: null,
                                                                    weight: null,
                                                                    zip: null,
                                                                    product: null,
                                                                    unitOfMeasure: null,
                                                                    currency: null,
                                                                    remark: null,
                                                                    params: null,
                                                                }} importExportType={value.productType} isAddFromQsCostTable={true} onHandleCancel={() => setOpenOrderModalLclCost(false)} id={null} airImportExport={null} />
                                                    </Dialog>
                                                )}

                                                {<Button variant='contained' onClick={function () {
                                                    if (value.shipmentType == 1) {
                                                        onHandleOpenModalCost();
                                                    } else if (value.shipmentType == 2) {
                                                        onHandleOpenModalLclCost();
                                                    }

                                                }} >
                                                    Add New
                                                </Button>}
                                                {openOrderModalMail && (
                                                        <>{value?.id ? <MailTemplate id={value?.id} airOrigin={listOfAirPort.find(u => u.id === value.from)?.name} airDest={listOfAirPort.find(u => u.id === value.to)?.name} pol={listOfPort.find(u => u.id === value.from)?.name} pod={listOfPort.find(u => u.id === value.to)?.name} flc40={value?.flc40} flc20={value?.flc20} flcType40={listOfFLCTypes40.find(u => u.id === value?.flcType40)?.type} flcType20={value?.flcType20} manualVolume={value?.manualVolume} manualWeight={value?.manualWeight} getTotalCostChargeable={getTotalCostChargeable} getTotalCostSum={getTotalCostSum} address={value.pickupLocation} incoterm={incotermsOpt.find(u => u.id === value.incoId)?.name} to={getTo()} from={getFrom()} value={value} shipmentDetails={costShipmentDetails} productType={value.productType} shipmentType={value.shipmentType} listOfAirLine={listOfAirLine} listOfFclQuotations={listOfFclQuotations} listOfLclQuoatationCost={listOfLclQuoatationCost} listOfQuoatationCost={listOfQuoatationCost} openOrderModalMail={openOrderModalMail} onHandleCancelModalMail={onHandleCancelModalMail}></MailTemplate>
                                                            : <>
                                                                <Dialog maxWidth='md' open={openOrderModalMail} title={`Save Order`} onHandleCancel={function () { setOpenOrderModalMail(false); } }>
                                                                    <Grid item container>
                                                                        <p>Order was not saved yet, please save order before sending the mail.</p>
                                                                    </Grid>
                                                                    <Grid item container flexDirection= "row-reverse">
                                                                        <Button variant='contained' onClick={function () {
                                                                            setOpenOrderModalMail(false);
                                                                        }}>OK</Button>
                                                                    </Grid>
                                                                </Dialog>
                                                            </>}</>
                                                )}
                                                    {<span title={value?.id ? "Send Mail." : "Please save the Quote."} ><Button disabled={!(isMailSelected() && value?.id)} variant='contained' onClick={function () {
                                                    onHandleOpenModalMail()

                                                }} sx={{ ml: 2 }}>
                                                    Send Mail
                                                    </Button></span>}
                                                <TableContainer>
                                                    <Table>
                                                        <AirLclQuoteHeader selectAllMail={selectAllMail} addRemoveSelectedMailAll={addRemoveSelectedMailAll} value={value} />
                                                        {value.shipmentType == 1 &&
                                                            <TableBody>
                                                                {
                                                                    listOfQuoatationCost.map((detail, index) => (
                                                                        <AirQuote setbLoadingLocal={setbLoadingLocal} setDefCharges={setDefCharges} calculation={getCalculations} addRemoveSelectedMail={addRemoveSelectedMail} typeData={listOfProductCategories.find(u => u.id === value.productType)} getQuote={() => {
                                                                            getQuote(value.shipmentType);//pp

                                                                            const elem = document.getElementById('getCostQuoat1');
                                                                            elem?.click();
                                                                        }} setListOfQuoatationCost={setListOfQuoatationCost} listOfQuoatationCost={listOfQuoatationCost} quoteByRate={function () {
                                                                            quoteState1 = 1;
                                                                            setQuoteState(1);
                                                                            var cr = detail.crmId ? crmList.find(u => u.id === detail.crmId)?.companyName : listOfAirLine.find(u => u.id == detail.airLineId)?.name
                                                                            setAgentText(cr);
                                                                            quoteByRate(detail)
                                                                        }} value={value} key={uuidv4()} detail={detail} />
                                                                    ))
                                                                }
                                                            </TableBody>
                                                        }
                                                        {value.shipmentType == 2 &&
                                                            <TableBody>
                                                                {
                                                                    listOfQuoatationCost.map((detail, index) => (
                                                                        <LclQuote setbLoadingLocal={setbLoadingLocal} getFreightCalculations={getFreightCalculations} addRemoveSelectedMail={addRemoveSelectedMail} typeData={listOfProductCategories.find(u => u.id === value.productType)} getQuote={() => {
                                                                            getQuote(value.shipmentType);
                                                                        }} setProductFreightChargesItem1={setProductFreightChargesItem1} setListOfQuoatationCost={setListOfQuoatationCost} listOfQuoatationCost={listOfQuoatationCost} quoteByRate={function () {
                                                                            quoteState1 = 1;
                                                                            setQuoteState(1);
                                                                            setAgentText(crmList.find(u => u.id === detail.crmId)?.companyName);
                                                                            quoteByRate(detail, true)
                                                                        }} value={value} key={uuidv4()} detail={detail} />
                                                                    ))
                                                                }
                                                            </TableBody>
                                                        }

                                                    </Table>
                                                    {value.shipmentType == 2 && value.productType != 1 && listOfLclQuoatationCost.length > 0 &&



                                                        <Table>

                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>
                                                                        <Typography variant='h7'>
                                                                            {/*<Checkbox checked={selectAllLclAgentMail} onChange={(event) => {
                                                                                    //setValue(prev => ({ ...prev, selected: event.target.checked }));
                                                                                    addRemoveSelectedMailLclAgentAll(props.detail, event.target.checked);
                                                                                }} />*/}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Typography variant='h7'>
                                                                            Agent
                                                                        </Typography>
                                                                    </TableCell>

                                                                    <TableCell>
                                                                        <Typography variant='h7'>
                                                                            FCA
                                                                        </Typography>
                                                                    </TableCell>

                                                                    <TableCell style={{ minWidth: "22vw" }}>
                                                                        <Typography variant='h7'>

                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Typography variant='h7'>
                                                                            Action
                                                                        </Typography>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {listOfLclQuoatationCost.map((detail, index) => (<TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                                                                    <TableCell>
                                                                        <Typography variant='h7'>
                                                                            <Checkbox checked={detail.selected} onChange={(event) => {
                                                                                addRemoveSelectedLclAgentMail(detail, event.target.checked);
                                                                            }} />
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Typography variant='h7'>
                                                                            {detail.companyName}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Typography variant='h7'>
                                                                            $ {detail.fcaCalCharges}
                                                                        </Typography>
                                                                    </TableCell>

                                                                    <TableCell>
                                                                        <Typography variant='h7'>

                                                                        </Typography>
                                                                    </TableCell>

                                                                    <TableCell>
                                                                        <Button variant='contained' onClick={function () { quoteState1 = 2; setQuoteState(2); setAgentText1(detail.companyName); quoteByRate(detail, false) }} >
                                                                            Quote
                                                                        </Button>
                                                                    </TableCell>
                                                                </TableRow>
                                                                ))
                                                                }
                                                            </TableBody>
                                                        </Table>

                                                    }
                                                </TableContainer>
                                            </Grid>

                                        </Grid>
                                        }
                                    </Grid>
                                </TableCell>


                                }

                                {(props.view === 'VIEW' && !bEdit && value.shipmentType == 3) ? <TableCell style={{ borderBottom: 0, display: 'flex', maxHeight: '60vh', width: '52vw', float: 'left' }}><Grid container item justifyContent='flex-start' lg={12} spacing={2}>
                                    <h3 style={{ marginLeft: "40%", width: '100%' }}>
                                        <label >The Quotation:
                                        </label>
                                    </h3>

                                    <TextField
                                        lg={12}
                                        value={value.id}
                                        label='Id'
                                        disabled={true}
                                    />
                                    <TextField
                                        lg={12}
                                        value={qsStatusList.find(u => u.id === value.status)?.lable || '-'}
                                        label='Status:'
                                        disabled={true}
                                    />
                                    <h3 style={{ marginLeft: "40%", width: '100%' }}>
                                        <label >The Quotation was framed by:
                                        </label>
                                    </h3>

                                    <TextField
                                        lg={12}
                                        value={adminList.find(u => u.id === value.userId)?.adminName || '-'}
                                        label='Name:'
                                        disabled={true}
                                    />

                                    <TextField
                                        lg={12}
                                        value={adminList.find(u => u.id === value.userId)?.phone || '-'}
                                        label='Telephone:'
                                        disabled={true}
                                    />

                                    <TextField
                                        lg={12}
                                        value={adminList.find(u => u.id === value.userId)?.email || '-'}
                                        label='E-mail:'
                                        disabled={true}
                                    />
                                    <label style={{ width: '20px', height: '62px' }}>
                                    </label>
                                </Grid></TableCell> :
                                    <>
                                        {value.shipmentType == 3 &&

                                            <TableCell style={{ borderBottom: 0, display: 'flex', maxHeight: '60vh', width: '52vw', float: 'left' }}>

                                                <TableContainer>
                                                    {openOrderModalMail && (
                                                        <MailTemplate id={value?.id} airOrigin={listOfAirPort.find(u => u.id === value.from)?.name} airDest={listOfAirPort.find(u => u.id === value.to)?.name} pol={listOfPort.find(u => u.id === value.from)?.name} pod={listOfPort.find(u => u.id === value.to)?.name} flc40={value?.flc40} flc20={value?.flc20} flcType40={listOfFLCTypes40.find(u => u.id === value?.flcType40)?.type} flcType20={listOfFLCTypes20.find(u => u.id === value?.flcType20)?.type} manualVolume={value?.manualVolume} manualWeight={value?.manualWeight} getTotalCostChargeable={getTotalCostChargeable} getTotalCostSum={getTotalCostSum} address={value.pickupLocation} incoterm={incotermsOpt.find(u => u.id === value.incoId)?.name} to={getTo()} from={getFrom()} value={value} shipmentDetails={costShipmentDetails} productType={value.productType} shipmentType={value.shipmentType} listOfAirLine={listOfAirLine} listOfFclQuotations={listOfFclQuotations} listOfLclQuoatationCost={listOfLclQuoatationCost} listOfQuoatationCost={listOfQuoatationCost} openOrderModalMail={openOrderModalMail} onHandleCancelModalMail={onHandleCancelModalMail}></MailTemplate>
                                                    )}
                                                    {<Button style={{ float: "right" }} disabled={!isMailSelected()} variant='contained' onClick={function () {
                                                        onHandleOpenModalMail()

                                                    }} sx={{ ml: 2 }}>
                                                        Send Mail
                                                    </Button>}
                                                    {<Button style={{ float: "right" }} variant='contained' onClick={function () {
                                                        onHandleOpenModalCloneFcl()

                                                    }} sx={{ ml: 2 }}>
                                                        Add New
                                                    </Button>}
                                                    {openOrderModalCloneFcl && (
                                                        <Dialog maxWidth='md' open={openOrderModalCloneFcl} title={listOfProductCategories.find(u => u.id === value.productType)?.lable} onHandleCancel={onHandleCancelModalCloneFcl}>
                                                            <AddFclCost isAddFromQsCostTable={true} setAirImportExport={() => {

                                                            }}
                                                                callBack={() => {
                                                                    getFclExportImportAFs(dispatch, admin, function () {
                                                                        window.setTimeout(function () {
                                                                            const elem = document.getElementById('getCostQuoat');
                                                                            elem?.click();
                                                                        }, 10);
                                                                    });


                                                                }} typeData={listOfProductCategories.find(u => u.id === value.productType)} data={{
                                                                    id: null,
                                                                    clAgent: null,
                                                                    countryId: null,
                                                                    createTime: null,
                                                                    crmId: null,
                                                                    importExportType: null,
                                                                    min: null,
                                                                    portLid: value.from,
                                                                    portAid: value.to,
                                                                    rateList: null,
                                                                    rate: null,
                                                                    tt: null,
                                                                    updateTime: null,
                                                                    validDate: null,
                                                                    validfrom: null,
                                                                    weightList: null,
                                                                    weight: null,
                                                                    zip: null,
                                                                    product: null,
                                                                    unitOfMeasure: null,
                                                                    currency: null,
                                                                    remark: null,
                                                                    params: null,
                                                                }} importExportType={value.productType} onHandleCancel={() => setOpenOrderModalCloneFcl(false)} id={null} airImportExport={null} />
                                                        </Dialog>
                                                    )}


                                                    <Table>
                                                        <TableHead style={{ padding: '2px', lineHeight: '1' }}>
                                                            <TableRow sx={{ '& > *': { borderBottom: 'unset', lineHeight: '1', margin: 0, padding: '2px' } }}>
                                                                <TableCell style={{ width: '2vw', padding: '15px', fontWeight: 'bold' }}>
                                                                    <Typography variant='h7'>

                                                                    </Typography>
                                                                </TableCell>
                                                                {value.productType == 2 && <TableCell style={{ padding: '15px', fontWeight: 'bold', minWidth: "6vw" }}>
                                                                    <Typography variant='h7'>
                                                                        Agent
                                                                    </Typography>
                                                                </TableCell>
                                                                }
                                                                <TableCell style={{ padding: '15px', fontWeight: 'bold' }}>
                                                                    <Typography variant='h7'>
                                                                        Shipping Line
                                                                    </Typography>
                                                                </TableCell>
                                                                {value.productType == 2 && <TableCell style={{ padding: '15px', fontWeight: 'bold', minWidth: "6vw" }}>
                                                                    <Typography variant='h7'>
                                                                        FCA (20, 40)
                                                                    </Typography>
                                                                </TableCell>
                                                                }
                                                                {<TableCell style={{ padding: '15px', fontWeight: 'bold' }}>
                                                                    <Typography variant='h7'>
                                                                        Rate 20
                                                                    </Typography>
                                                                </TableCell>
                                                                }
                                                                {<TableCell style={{ padding: '15px', fontWeight: 'bold' }}>
                                                                    <Typography variant='h7'>
                                                                        Rate 40
                                                                    </Typography>
                                                                </TableCell>
                                                                }
                                                                {
                                                                    value.productType == 2 && <TableCell style={{ padding: '15px', fontWeight: 'bold' }}>
                                                                        <Typography variant='h7'>
                                                                            TT
                                                                        </Typography>
                                                                    </TableCell>
                                                                }
                                                                {value.productType == 2 && <TableCell style={{ padding: '15px', fontWeight: 'bold' }}>
                                                                    <Typography variant='h7'>
                                                                        Direct
                                                                    </Typography>
                                                                </TableCell>
                                                                }
                                                                {<TableCell style={{ padding: '15px', fontWeight: 'bold' }}>
                                                                    <Typography variant='h7'>
                                                                        Updated
                                                                    </Typography>
                                                                </TableCell>
                                                                }

                                                                {<TableCell style={{ padding: '15px', fontWeight: 'bold' }}>
                                                                    <Typography variant='h7'>
                                                                        Valid Start
                                                                    </Typography>
                                                                </TableCell>
                                                                }
                                                                {<TableCell style={{ padding: '15px', fontWeight: 'bold' }}>
                                                                    <Typography variant='h7'>
                                                                        Valid End
                                                                    </Typography>
                                                                </TableCell>
                                                                }
                                                                {<TableCell style={{ padding: '15px', fontWeight: 'bold', minWidth: "12vw" }}>
                                                                    <Typography variant='h7'>

                                                                    </Typography>
                                                                </TableCell>
                                                                }




                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>

                                                            {
                                                                listOfFclQuotations.map((detail, index) => (
                                                                    <FclQuote setbLoadingLocal={setbLoadingLocal} getCalculations={getFreightCalculations} importExportType={value.productType} addRemoveSelectedMail={addRemoveSelectedMail} id={listOfProductCategories.find(u => u.id === value.productType).id} typeData={listOfProductCategories.find(u => u.id === value.productType)} getQuote={() => {
                                                                        getQuote(value.shipmentType);
                                                                    }} setListOfQuoatationCost={setListOfFclQuotations} listOfQuoatationCost={listOfFclQuotations} quoteByRate={function () {
                                                                        quoteState1 = 1;
                                                                        setQuoteState(1);
                                                                        
                                                                        var cr = detail.agentCrmId ? crmList.find(u => u.id == detail.agentCrmId)?.companyName : crmList.find(u => u.id == detail.shippingCrmId)?.companyName;
                                                                        setAgentText(cr);
                                                                        quoteByRate(detail)
                                                                    }} value={value} key={uuidv4()} data={detail} detail={detail} />
                                                                ))
                                                            }
                                                            {/*{
                                                                    listOfQuoatationCost.map((detail, index) => (
                                                                        <LclQuote addRemoveSelectedMail={addRemoveSelectedMail} typeData={listOfProductCategories.find(u => u.id === value.productType)} getQuote={() => {
                                                                            getQuote(value.shipmentType);
                                                                        }} setListOfQuoatationCost={setListOfQuoatationCost} listOfQuoatationCost={listOfQuoatationCost} quoteByRate={function () { quoteState1 = 1; setQuoteState(1); setAgentText(crmList.find(u => u.id === detail.crmId)?.companyName); quoteByRate(detail) }} value={value} key={uuidv4()} detail={detail} />
                                                                    ))
                                                                }*/}
                                                        </TableBody></Table>
                                                </TableContainer>
                                            </TableCell>
                                        }
                                    </>}
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                {value.shipmentType != 3 && <Grid container item justifyContent='flex-start' lg={5.4} style={{ marginTop: 5, marginLeft: 35, marginRight: 10, borderRadius: 5 }}>
                    <TableContainer>
                        <Table>

                            <TableBody>
                                {<TableRow sx={{ '& > *': { borderBottom: 'unset', margin: 0, padding: 0 } }}>
                                    <TableCell>
                                        <Typography variant='h7'>
                                            Agent
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='h7'>
                                            {value.agentText}
                                        </Typography>
                                    </TableCell>
                                </TableRow>}
                                <TableRow sx={{ '& > *': { borderTop: '1px solid gray', borderBottom: '1px solid gray', margin: 0, padding: '5px' } }}>
                                    <TableCell>
                                        <Typography variant='h7'>
                                            Estimated Foreign Charges
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='h7'>
                                            {getValueByCur(value.params.curForeignValue, selectedCur)}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{ '& > *': { borderBottom: '1px solid gray', margin: 0, padding: '5px' } }}>
                                    <TableCell>
                                        <Typography variant='h7'>
                                            Estimated Freight Charges
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='h7'>
                                            {getValueByCur(value.params.curFreightValue, selectedCur)}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{ '& > *': { borderBottom: '1px solid gray', margin: 0, padding: '5px' } }}>
                                    <TableCell>
                                        <Typography variant='h7'>
                                            Estimated Local Charges
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='h7'>
                                            {getValueByCur(value.params.curLocalValue, selectedCur)}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                {value.productType != 1 && <TableRow sx={{ '& > *': { borderBottom: '1px solid gray', margin: 0, padding: '5px' } }}>
                                    <TableCell>
                                        <Typography variant='h7'>
                                            Estimated Custom Charges
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='h7'>
                                            {getValueByCur(value.params.curCustomValue, selectedCur)}
                                        </Typography>
                                    </TableCell>
                                </TableRow>}

                                <TableRow sx={{ '& > *': { borderBottom: '1px solid gray', margin: 0, padding: '5px' } }}>
                                    <TableCell>
                                        <Typography variant='h7' fontWeight='bold'>
                                            Total buying [{getLableByCur(selectedCur)}]
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='h7'>
                                            {(getValueByCur(value.params?.originalPrice, selectedCur))}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{ '& > *': { borderBottom: '1px solid gray', margin: 0, padding: '5px' } }}>
                                    <TableCell>
                                        <Typography variant='h7' fontWeight='bold'>
                                            Margin [{getLableByCur(selectedCur)}]
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='h7'>
                                            {(getValueByCur(value.params?.margin, selectedCur))}
                                        </Typography>
                                    </TableCell>
                                </TableRow>

                                <TableRow sx={{ '& > *': { borderBottom: '1px solid gray', margin: 0, padding: '5px' } }}>
                                    <TableCell>
                                        <Typography variant='h7' fontWeight='bold'>

                                            <Grid container item lg={12} alignItems='center'>
                                                <Grid container item lg={2}>Total selling</Grid>
                                                <Grid container item lg={2} style={{ minWidth: "5vw" }}><SelectField
                                                    required
                                                    value={selectedCur}
                                                    lg={12}
                                                    onChange={event => setSelectedCur(event.target.value)}
                                                    label=''
                                                    options={currencies}
                                                /></Grid>

                                            </Grid>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='h7' fontWeight='bold'>
                                            {getValueByCur(value.params?.curPrice, selectedCur)}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                }

                {value.shipmentType == 3 && <Grid container item justifyContent='flex-start' lg={5.4} style={{ marginTop: 10, marginLeft: 35, marginRight: 10, borderRadius: 5 }}>
                    <TableContainer>
                        <Table>

                            <TableBody>

                                {<TableRow sx={{ '& > *': { borderBottom: 'unset', margin: 0, padding: 0 } }}>
                                    <TableCell>
                                        <Typography variant='h7'>
                                            Agent
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='h7'>
                                            {value.agentText}
                                        </Typography>
                                    </TableCell>
                                </TableRow>}
                                <TableRow title={getTotalEsitmatedAirForeign20()} sx={{ '& > *': { borderTop: '1px solid gray', borderBottom: '1px solid gray', margin: 0, padding: '5px' } }}>
                                    <TableCell>
                                        <Typography variant='h7'>
                                            Estimated Foreign Charges 20
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='h7' >
                                            {getValueByCur(value.params.curForeignValue20, selectedCur)}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{ '& > *': { borderTop: '1px solid gray', borderBottom: '1px solid gray', margin: 0, padding: '5px' } }}>
                                    <TableCell>
                                        <Typography variant='h7'>
                                            Estimated Freight Charges 20
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='h7'>
                                            {getValueByCur(value.params.curFreightValue20, selectedCur)}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{ '& > *': { borderTop: '1px solid gray', borderBottom: '1px solid gray', margin: 0, padding: '5px' } }}>
                                    <TableCell>
                                        <Typography variant='h7'>
                                            Estimated Local Charges 20
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='h7'>
                                            {getValueByCur(value.params.curLocalValue20, selectedCur)}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                {<TableRow sx={{ '& > *': { borderTop: '1px solid gray', borderBottom: '1px solid gray', margin: 0, padding: '5px' } }}>
                                    <TableCell>
                                        <Typography variant='h7'>
                                            Estimated Custom Charges 20
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='h7'>
                                            {getValueByCur(value.params.curCustomValue20, selectedCur)}
                                        </Typography>
                                    </TableCell>
                                </TableRow>}
                                <TableRow sx={{ '& > *': { borderTop: '1px solid gray', borderBottom: '1px solid gray', margin: 0, padding: '5px' } }}>
                                    <TableCell>
                                        <Typography variant='h7' fontWeight='bold'>
                                            Total Buying Charges 20 [{getLableByCur(selectedCur)}]
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='h7'>
                                            {(getValueByCur(value.params?.originalPrice20, selectedCur))}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{ '& > *': { borderTop: '1px solid gray', borderBottom: '1px solid gray', margin: 0, padding: '5px' } }}>
                                    <TableCell>
                                        <Typography variant='h7' fontWeight='bold'>
                                            Margin 20 [{getLableByCur(selectedCur)}]
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='h7'>
                                            {(getValueByCur(value.params?.margin20, selectedCur))}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{ '& > *': { borderTop: '1px solid gray', borderBottom: '1px solid gray', margin: 0, padding: '5px' } }}>
                                    <TableCell>
                                        <Typography variant='h7' fontWeight='bold'>
                                            {/* Total selling Charges 20 [USD]*/}


                                            <Grid container item lg={12} alignItems='center'>
                                                <Grid container item lg={4}>Total selling Charges 20</Grid>
                                                <Grid container item lg={2} style={{ minWidth: "5vw" }}><SelectField
                                                    required
                                                    value={selectedCur}
                                                    lg={12}
                                                    onChange={event => setSelectedCur(event.target.value)}
                                                    label=''
                                                    options={currencies}
                                                /></Grid>

                                            </Grid>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='h7' fontWeight='bold'>
                                            {getValueByCur(value.params?.curPrice20, selectedCur)}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                }
                {value.shipmentType == 3 && <Grid container item justifyContent='flex-start' lg={5.4} style={{ marginTop: 10, marginLeft: 35, marginRight: 10, borderRadius: 5 }}>
                    <TableContainer>
                        <Table>

                            <TableBody>

                                <TableRow sx={{ '& > *': { borderTop: '1px solid gray', borderBottom: '1px solid gray', margin: 0, padding: '5px' } }}>
                                    <TableCell>
                                        <Typography variant='h7'>
                                            Estimated Foreign Charges 40
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='h7'>
                                            {getValueByCur(value.params.curForeignValue40, selectedCur)}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{ '& > *': { borderTop: '1px solid gray', borderBottom: '1px solid gray', margin: 0, padding: '5px' } }}>
                                    <TableCell>
                                        <Typography variant='h7'>
                                            Estimated Freight Charges 40
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='h7'>
                                            {getValueByCur(value.params.curFreightValue40, selectedCur)}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{ '& > *': { borderTop: '1px solid gray', borderBottom: '1px solid gray', margin: 0, padding: '5px' } }}>
                                    <TableCell>
                                        <Typography variant='h7'>
                                            Estimated Local Charges 40
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='h7'>
                                            {getValueByCur(value.params.curLocalValue40, selectedCur)}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                {<TableRow sx={{ '& > *': { borderTop: '1px solid gray', borderBottom: '1px solid gray', margin: 0, padding: '5px' } }}>
                                    <TableCell>
                                        <Typography variant='h7'>
                                            Estimated Custom Charges 40
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='h7'>
                                            {getValueByCur(value.params.curCustomValue40, selectedCur)}
                                        </Typography>
                                    </TableCell>
                                </TableRow>}
                                <TableRow sx={{ '& > *': { borderTop: '1px solid gray', borderBottom: '1px solid gray', margin: 0, padding: '5px' } }}>
                                    <TableCell>
                                        <Typography variant='h7' fontWeight='bold'>
                                            Total Buying Charges 40 [{getLableByCur(selectedCur)}]
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='h7'>
                                            {(getValueByCur(value.params?.originalPrice40, selectedCur))}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{ '& > *': { borderTop: '1px solid gray', borderBottom: '1px solid gray', margin: 0, padding: '5px' } }}>
                                    <TableCell>
                                        <Typography variant='h7' fontWeight='bold'>
                                            Margin 40 [{getLableByCur(selectedCur)}]
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='h7'>
                                            {(getValueByCur(value.params?.margin40, selectedCur))}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{ '& > *': { borderTop: '1px solid gray', borderBottom: '1px solid gray', margin: 0, padding: '5px' } }}>
                                    <TableCell>
                                        <Typography variant='h7' fontWeight='bold'>
                                            {/*Total selling Charges 40 [USD]*/}

                                            <Grid container item lg={12} alignItems='center'>
                                                <Grid container item lg={4}>Total selling Charges 40</Grid>
                                                <Grid container item lg={2} style={{ minWidth: "5vw" }}><SelectField
                                                    required
                                                    value={selectedCur}
                                                    lg={12}
                                                    onChange={event => setSelectedCur(event.target.value)}
                                                    label=''
                                                    options={currencies}
                                                /></Grid>

                                            </Grid>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='h7' fontWeight='bold'>
                                            {getValueByCur(value.params?.curPrice40, selectedCur)}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                }

                {value.shipmentType != 3 && <Grid container item justifyContent='flex-end' lg={12} style={{ marginTop: 40, marginLeft: 10, marginRight: 10, borderRadius: 5 }}>
                    <Button disabled={!getTotalCostChargeable() || !value.from || !value.to || !value.cutomerCrmId} id="getCostQuoat" variant='contained' sx={{ mt: 0, mr: 3, background: 'green' }} onClick={() => {

                        /*for (var i = 0; i < costShipmentDetails?.length;i++) {
                            if (onHandleOpenModalDimention(costShipmentDetails[i])) {
                                return;
                            }
                        }*/


                        getCostUpdated(function () {
                            getQuote(value.shipmentType)
                            setLcIndex(0);
                            setFcIndex(0);
                            setFrcIndex(0);
                            setCcIndex(0);
                            const elem = document.getElementById('getCostQuoat1');
                            elem?.click();
                        }) /**/
                        //getQuote(value.shipmentType)
                    }} className="cost_btn_qs">
                        Cost
                    </Button>

                </Grid>}
                {value.shipmentType == 3 && <Grid container item justifyContent='flex-end' lg={12} style={{ marginTop: 40, marginLeft: 10, marginRight: 10, borderRadius: 5 }}>
                    <Button disabled={(!value.flc20 && !value.flc40) || !value.from || !value.to || !value.cutomerCrmId} id="getCostQuoat" variant='contained' sx={{ mt: 0, mr: 3, background: 'green' }} onClick={() => {
                        /* getQuote(value.shipmentType);
                         if (value.id)
                             getQuote(value.shipmentType)*/

                        getCostUpdated(function () {
                            getQuote(value.shipmentType)
                            const elem = document.getElementById('getCostQuoat1');
                            elem?.click();
                        })
                    }} className="cost_btn_qs">
                        Cost
                    </Button>
                </Grid>}
                {value.shipmentType != 3 && <Grid container item justifyContent='flex-start' lg={12} style={{ marginTop: -80, marginLeft: 10, marginRight: 10, borderRadius: 5 }}>
                    <ShipmentDetails onHandleOpenModalDimention={onHandleOpenModalDimention} id={value.id} valueData={value} setValueData={setValue} setCostShipmentDetails={setCostShipmentDetails} shipmentDetails={value.shipmentDetails} shipmentType={value.shipmentType} />
                </Grid>}



                {value.productType == 1 && <Grid container item justifyContent='flex-start' lg={12}>
                    <Grid container item justifyContent='flex-start' lg={12} style={{ marginTop: 0, marginLeft: 10, marginRight: 10, borderRadius: 5 }}>
                        {productLocalChargesItem1 && <Charges isFourty={value.flc40 ? true : false} isTwenty={value.flc20 ? true : false} calculation={getCalculations} heading={'Local Charges'} setChargesItem={setLocalChargeItemInList} addChargesRec={addLocalChargesRec} removeCharge={removeLocalCharge} original={productLocalChargesItemOriginal} productChargesItem={productLocalChargesItem1} shipmentType={value.shipmentType} />}
                    </Grid>

                    <Grid container item justifyContent='flex-start' lg={12} style={{ marginTop: 0, marginLeft: 10, marginRight: 10, borderRadius: 5 }}>
                        {productFreightChargesItem1 && <Charges isFourty={value.flc40 ? true : false} isTwenty={value.flc20 ? true : false} calculation={getFreightCalculations} heading={'Freight Charges'} setChargesItem={setFreightChargeItemInList} addChargesRec={addFreightChargesRec} removeCharge={removeFreightCharge} original={productFreightChargesItemOriginal} productChargesItem={productFreightChargesItem1} shipmentType={value.shipmentType} />}
                    </Grid>

                    <Grid container item justifyContent='flex-start' lg={12} style={{ marginTop: 0, marginLeft: 10, marginRight: 10, borderRadius: 5 }}>
                        {productForeignChargesItem && <Charges isFourty={value.flc40 ? true : false} isTwenty={value.flc20 ? true : false} calculation={getCalculations} heading={'Foreign Charges'} setChargesItem={setForeignChargeItem} addChargesRec={addForeignChargesRec} removeCharge={removeForeignCharge} original={productForeignChargesItemOriginal} productChargesItem={productForeignChargesItem} shipmentType={value.shipmentType} />}
                    </Grid>
                    <Grid container item justifyContent='flex-start' lg={12} style={{ marginTop: 0, marginLeft: 10, marginRight: 10, borderRadius: 5 }}>
                        {value.productType != 1 && productCustomChargesItem1 && <Charges isFourty={value.flc40 ? true : false} isTwenty={value.flc20 ? true : false} calculation={getCalculations} heading={'Custom Charges'} setChargesItem={setCustomChargeItemInList} addChargesRec={addCustomeChargesRec} removeCharge={removeCustomCharge} original={productCustomChargesItemOriginal} productChargesItem={productCustomChargesItem1} shipmentType={value.shipmentType} />}
                    </Grid>
                </Grid>
                }
                {value.productType != 1 && <Grid container item justifyContent='flex-start' lg={12}>
                    <Grid container item justifyContent='flex-start' lg={12} style={{ marginTop: 0, marginLeft: 10, marginRight: 10, borderRadius: 5 }}>
                        {productForeignChargesItem && <Charges isFourty={value.flc40 ? true : false} isTwenty={value.flc20 ? true : false} calculation={getCalculations} heading={'Foreign Charges'} setChargesItem={setForeignChargeItem} addChargesRec={addForeignChargesRec} removeCharge={removeForeignCharge} original={productForeignChargesItemOriginal} productChargesItem={productForeignChargesItem} shipmentType={value.shipmentType} />}
                    </Grid>
                    <Grid container item justifyContent='flex-start' lg={12} style={{ marginTop: 0, marginLeft: 10, marginRight: 10, borderRadius: 5 }}>
                        {productFreightChargesItem1 && <Charges isFourty={value.flc40 ? true : false} isTwenty={value.flc20 ? true : false} calculation={getFreightCalculations} heading={'Freight Charges'} setChargesItem={setFreightChargeItemInList} addChargesRec={addFreightChargesRec} removeCharge={removeFreightCharge} original={productFreightChargesItemOriginal} productChargesItem={productFreightChargesItem1} shipmentType={value.shipmentType} />}
                    </Grid>
                    <Grid container item justifyContent='flex-start' lg={12} style={{ marginTop: 0, marginLeft: 10, marginRight: 10, borderRadius: 5 }}>
                        {productLocalChargesItem1 && <Charges isFourty={value.flc40 ? true : false} isTwenty={value.flc20 ? true : false} calculation={getCalculations} heading={'Local Charges'} setChargesItem={setLocalChargeItemInList} addChargesRec={addLocalChargesRec} removeCharge={removeLocalCharge} original={productLocalChargesItemOriginal} productChargesItem={productLocalChargesItem1} shipmentType={value.shipmentType} />}
                    </Grid>

                    <Grid container item justifyContent='flex-start' lg={12} style={{ marginTop: 0, marginLeft: 10, marginRight: 10, borderRadius: 5 }}>
                        {value.productType != 1 && productCustomChargesItem1 && <Charges isFourty={value.flc40 ? true : false} isTwenty={value.flc20 ? true : false} calculation={getCalculations} heading={'Custom Charges'} setChargesItem={setCustomChargeItemInList} addChargesRec={addCustomeChargesRec} removeCharge={removeCustomCharge} original={productCustomChargesItemOriginal} productChargesItem={productCustomChargesItem1} shipmentType={value.shipmentType} />}
                    </Grid>
                </Grid>
                }

                <Grid container item justifyContent='flex-end' lg={12}>
                    {<Button variant='contained' sx={{ mt: 1, mr: 2, mb: 2 }} onClick={() => {
                        recalclulateOriginalValues();
                        if (!checkForInvalidOriginalCosts()) {
                            showOriginalCostError();
                            return;
                        }
                        /*var m = 0;
                        var m2 = 0;
                        var m4 = 0;

                        if (value.shipmentType == 3) {
                            m2 = value.flc20 ? Number(getValueByCur((getTotal20()?.toFixed(2) - ((props.view === 'VIEW' && !bRate) ? props?.data?.params?.originalPrice20 : getOriginalPrice20())), selectedCur)).toFixed(2) : 0
                            m4 = value.flc40 ? getValueByCur(Number(getTotal40()?.toFixed(2) - ((props.view === 'VIEW' && !bRate) ? props?.data?.params?.originalPrice40 : getOriginalPrice40())).toFixed(2), selectedCur) : 0
                        } else {
                            m = getMargin()
                        }*/
                        onHandleOpenModalProfit()
                        //if (m > 50 || m2 > 50 || m4 > 50) {
                        //    onHandleOpenModalProfit()
                        //else {
                        //    addQuoatation(true)
                        //}
                        
                    }} >
                        Save
                    </Button>
                    }
                    <Button variant='contained' sx={{ mt: 1, mr: 4, mb: 2 }} color='secondary' onClick={props.onHandleCancel}>
                        Cancel
                    </Button>
                </Grid>
            </Grid>
            <Button id="getCostQuoat1" variant='text' onClick={() => {
                //getCostUpdated(function () {
                getQuote(value.shipmentType)
                // }) 
            }}>

            </Button>
            {snackbar && (
                <Snackbar open={!!snackbar} message={snackbar.message} type={snackbar.type} onClose={onSnackbarHandleClose} />
            )}
        </Paper>
    );
};

export default AddQS;
