import { useState, useEffect, useCallback, useRef } from 'react';
import { useDraggable } from "react-use-draggable-scroll";
import { v4 as uuidv4 } from 'uuid';
import { SelectField, TextField } from '../../style-guide';
import { BrowserRouter as Router, Link, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { Box, Button, RadioGroup, Grid, Table, TableBody, TableContainer, Paper, TablePagination, Typography, TableCell, TableHead, TableRow } from '@mui/material';
import QSHeader from './QSHeader';
import QSRow from './QSRow';
import AddQS from './AddQS';
import { useSelector, useDispatch } from 'react-redux';
import { onPageChange, onSizeChange } from '../../reducers/admin-reducers/AdminSlicer';
import { snackbarToggle } from '../../reducers/snackbarSlicer';
import { onQsChange } from '../../reducers/QS/qsSlice';
import { setCommonFilterData } from '../../reducers/parameter/parameterSlice';
import { Dialog, Snackbar } from '../../style-guide';
import { parcelStatusList, parcelSortBy, SHIPPING_STATUS_LIST, paginationArr, URL } from '../../constants/global-constants';

import { getQSById, copyQs, deleteQS, getAdminList, updateQuoteStatus, getQSPaginationFilter, exportCSVFile, getKgFactorsforSave, quoatationReport, getCRMList, updateQS1 } from '../../reducers/requestHandler';

export const QSTable = () => {
    //const ref = useRef();
    const navigate = useNavigate();
   // const { events } = useDraggable(ref);
    const dispatch = useDispatch();
    const { paginationData, adminList } = useSelector(state => state.admin);
    const { selectedAdmin } = useSelector(state => state.QS);
    const { qsList } = useSelector(state => state.QS);
    const { listOfFLCTypes40, listOfFLCTypes20, commonFilterData, listOfExchangeRates } = useSelector(state => state.parameter);
    const { crmList } = useSelector(state => state.crm);
    const { countryList, listOfAirPort, listOfPort, qsTypeList, qsStatusList, listOfIncoterms, listOfRejectReasions, listOfServiceType } = useSelector(state => state.parameter);
   var t = []
    const { currentPage, admin } = useSelector(state => state.session);
    const { snackbar } = useSelector(state => state.snackbar);
    const [value, setValue] = useState(0);
    var tSelected = {}
    const [selectedQs, setSelectedQs1] = useState({});
    function setSelectedQs(data) {
        // customChargesItem, foreignChargesItem, freightChargesItem, localChargesItem
        for (var i = 0; i < data.customChargesItem.length; i++) {
            data.customChargesItem[i].index = i;
        }
        for (var i = 0; i < data.foreignChargesItem.length; i++) {
            data.foreignChargesItem[i].index = i;
        }
        for (var i = 0; i < data.freightChargesItem.length; i++) {
            data.freightChargesItem[i].index = i;
        }
        for (var i = 0; i < data.localChargesItem.length; i++) {
            data.localChargesItem[i].index = i;
        }
        setSelectedQs1(data);
    }
    const [qsList1, setQsList] = useState([]);

    const st = new Date(new Date().getTime());
    const [viewOrEdit, setViewOrEdit] = useState("VIEW");
    const [isLoaded, setIsLoaded] = useState(false);
    const onSnackbarHandleClose = () => dispatch(snackbarToggle(false));
    const [openOrderModal, setOpenOrderModal] = useState(false);
    const [bAdd, setBadd] = useState(false);
    const onHandleOpenModal = () => {
        setOpenOrderModal(true);
    };
    const onHandleCancelModal = () => setOpenOrderModal(false);

    const [openOrderModalDelete, setOpenOrderModalDelete] = useState(false);

    const onHandleOpenModalDelete = () => {
        setOpenOrderModalDelete(true);
    };
    const onHandleCancelModalDelete = () => setOpenOrderModalDelete(false);

    const [openOrderModalfourAction, setOpenOrderModalfourAction] = useState(false);

    const onHandleOpenModalfourAction = () => {
        setOpenOrderModalfourAction(true);
    };
    const onHandleCancelModalfourAction = () => setOpenOrderModalfourAction(false);



    const [openOrderModalCopy, setOpenOrderModalCopy] = useState(false);

    const onHandleOpenModalCopy = () => {
        setOpenOrderModalCopy(true);
    };
    const onHandleCancelModalCopy = () => setOpenOrderModalCopy(false);

    const getTotalCostSum = function (data) {
        var tot = 0;
        for (var i = 0; i < data.shipmentDetails?.length; i++) {
            tot = tot + data.shipmentDetails[i].weight * data.shipmentDetails[i].pieces;
        }
        return Number(Number(value?.manualWeight ? value?.manualWeight : tot).toFixed(2))
    }
    const getTotalCostVolume = function (data) {
        var tot = 0;
        for (var i = 0; i < data.shipmentDetails?.length; i++) {
            tot = tot + (data.shipmentDetails[i].pieces * data.shipmentDetails[i].length * data.shipmentDetails[i].width * data.shipmentDetails[i].height) / 1000000;
        }
        return Number(Number(value?.manualVolume ? value?.manualVolume : tot).toFixed(2));
    }
    const getTotalCostChargeable = function (data) {
        if (data.params.manualWeight)
            if (Number(data.params.manualWeight) != 0)
                return Number(data.params.manualWeight);
        var kfF = listOfKgFactor1.find(u => u.shipmentType === 'Air')?.kg
        if (!kfF) {
            kfF = 1000;
        }

        var totCharg = getTotalCostVolume(data) * kfF;
        if (totCharg < getTotalCostSum(data)) {
            totCharg = getTotalCostSum(data);
        }
        return Number(Number(totCharg).toFixed(2));
    }
    const [listOfKgFactor1, setListOfKgFactor1] = useState([]);
    const getTotalCostWM = function (data) {
        var t_w_m = 0;
        //if (value.shipmentType == 2) {
        //    t_w_m = 1;
        //}
        for (var i = 0; i < data.shipmentDetails?.length; i++) {
            //tot = tot + costShipmentDetails[i].weight * costShipmentDetails[i].pieces;
            var t_v_m3 = data.shipmentDetails[i].pieces * (data.shipmentDetails[i].length * data.shipmentDetails[i].width * data.shipmentDetails[i].height) / 1000000;
            var t_w_kg = data.shipmentDetails[i].weight * data.shipmentDetails[i].pieces;
            var kfF = Number(listOfKgFactor1.find(u => u.shipmentType === 'Ocean')?.kg)
            if (!kfF) {
                kfF = 1000;
            }
            if (t_w_kg / kfF > t_v_m3) {
                t_w_m += t_w_kg / kfF;
            } else {
                t_w_m += t_v_m3;
            }

        }
        if (data.shipmentType == 2) {
            if (data.params.manualVolume)
                if (Number(data.params.manualVolume) != 0)
                    t_w_m = Number(data.params.manualVolume);
            if (t_w_m) {
                t_w_m = t_w_m < 1 ? 1 : t_w_m;
            }
        }

        return Number(Number(t_w_m).toFixed(2));
    }
    const getCalculations = function (data,rate, min, unit, bFlc20) {
        if (!rate) {
            rate = 0;
        }
        if (data.shipmentType == 1) {
            if (unit == 1) {
                var c = getTotalCostChargeable(data) * rate;
                if (c < min) {
                    c = min;
                }
                return c;
            } else if (unit == 2 || unit == 4) {
                var c = 1 * rate;
                if (c < min) {
                    c = min;
                }
                return c;
            } else if (unit == 3) {
                var c = getTotalCostChargeable(data) * rate;
                if (c < min) {
                    c = min;
                }
                return c;
            } else if (unit == 5) {
                var c = getTotalCostSum(data) * rate;
                if (c < min) {
                    c = min;
                }
                return c;
            }
        }
        if (data.shipmentType == 2) {
            if (unit == 1) {
                var c = getTotalCostWM(data) * rate;
                if (c < min) {
                    c = min;
                }
                return c;
            } else if (unit == 2 || unit == 4) {
                var c = 1 * rate;
                if (c < min) {
                    c = min;
                }
                return c;
            } else if (unit == 3) {
                var c = getTotalCostWM(data) * rate;
                if (c < min) {
                    c = min;
                }
                return c;
            } else if (unit == 5) {
                var c = getTotalCostWM(data) * rate;
                if (c < min) {
                    c = min;
                }
                return c;
            }
        }
        if (data.shipmentType == 3) {

            if (unit == 2) {
                var c = 1 * rate;
                if (c < min) {
                    c = min;
                }
                return c;
            } else if (unit == 4) {
                //var q = value.flc20 ? value.flc20 : value.flc40;
                var q = 0;
                if (bFlc20) {
                    q = data.flc20;
                } else {
                    q = data.flc40;
                }
                if (!q) {
                    q = 0;
                }
                var c = q * rate;
                if (c < min) {
                    c = min;
                }
                return c;
            } else {
                return 0
            }
        }
    }
    function recalclulateOriginalValues (data) {
        var orgValue = 0;
        var orgValue20 = 0;
        var orgValue40 = 0;
        var orgCustomValue = 0;
        var orgCustomValue20 = 0;
        var orgCustomValue40 = 0;
        var orgLocalValue = 0;
        var orgLocalValue20 = 0;
        var orgLocalValue40 = 0;
        var orgForeignValue = 0;
        var orgForeignValue20 = 0;
        var orgForeignValue40 = 0;
        var orgFreightValue = 0;
        var orgFreightValue20 = 0;
        var orgFreightValue40 = 0;
        var curValue = 0;
        var curValue20 = 0;
        var curValue40 = 0;
        var curCustomValue = 0;
        var curCustomValue20 = 0;
        var curCustomValue40 = 0;
        var curLocalValue = 0;
        var curLocalValue20 = 0;
        var curLocalValue40 = 0;
        var curForeignValue = 0;
        var curForeignValue20 = 0;
        var curForeignValue40 = 0;
        var curFreightValue = 0;
        var curFreightValue20 = 0;
        var curFreightValue40 = 0;
        for (var i = 0; i < data.foreignChargesItem.length; i++) {
            //var obj = removeCircularKey(productForeignChargesItem[i]);
            var obj = data.foreignChargesItem[i];
            if (obj.selectedItem.name == "Select")
                continue;
            if (obj.units && obj.units.length) {
                if (!obj.selectedUnit || !obj.selectedUnit.id) {
                    obj.selectedUnit = obj.units[0]
                }
            }
            if (obj.currencies && obj.currencies.length) {
                if (!obj.selectedCurrency || !obj.selectedCurrency.id) {
                    obj.selectedCurrency = obj.currencies[0]
                }
            }
            var exch = listOfExchangeRates.find(u => u.id === obj.selectedCurrency.id)
            if (value.flc20) {
                curForeignValue20 += getCalculations(data, Number(obj.rate20),
                    Number(obj.min),
                    obj.selectedUnit.id, true) / exch.oneUsd;
                orgForeignValue20 += getCalculations(data, Number(obj.orgValue20 ?
                    obj.orgValue20 : obj.rate20),
                    Number(obj.min),
                    obj.selectedUnit.id, true) / exch.oneUsd;
            }
            if (value.flc40) {
                curForeignValue40 += getCalculations(data, Number(obj.rate40),
                    Number(obj.min),
                    obj.selectedUnit.id, true) / exch.oneUsd;
                orgForeignValue40 += getCalculations(data, Number(obj.orgValue40 ?
                    obj.orgValue40 : obj.rate40),
                    Number(obj.min),
                    obj.selectedUnit.id, true) / exch.oneUsd;
            }
            if (!value.flc40 && !value.flc20) {
                curForeignValue += getCalculations(data, Number(obj.rate),
                    Number(obj.min),
                    obj.selectedUnit.id) / exch.oneUsd;
                orgForeignValue += getCalculations(data, Number(obj.orgValue ?
                    obj.orgValue : obj.rate),
                    Number(obj.min),
                    obj.selectedUnit.id) / exch.oneUsd;
            }
        }

        for (var i = 0; i < data.freightChargesItem.length; i++) {
            //var obj = removeCircularKey(productFreightChargesItem1[i]);
            var obj = data.freightChargesItem[i];
            if (obj.selectedItem.name == "Select")
                continue;
            if (obj.units && obj.units.length) {
                if (!obj.selectedUnit || !obj.selectedUnit.id) {
                    obj.selectedUnit = obj.units[0]
                }
            }
            if (obj.currencies && obj.currencies.length) {
                if (!obj.selectedCurrency || !obj.selectedCurrency.id) {
                    obj.selectedCurrency = obj.currencies[0]
                }
            }
            var exch = listOfExchangeRates.find(u => u.id === obj.selectedCurrency.id)
            if (value.flc20) {
                curFreightValue20 += getCalculations(data, Number(obj.rate20),
                    Number(obj.min),
                    obj.selectedUnit.id, true) / exch.oneUsd;
                orgFreightValue20 += getCalculations(data, Number(obj.orgValue20 ?
                    obj.orgValue20 : obj.rate20),
                    Number(obj.min),
                    obj.selectedUnit.id, true) / exch.oneUsd;
            }
            if (value.flc40) {
                curFreightValue40 += getCalculations(data, Number(obj.rate40),
                    Number(obj.min),
                    obj.selectedUnit.id, true) / exch.oneUsd;
                orgFreightValue40 += getCalculations(data, Number(obj.orgValue40 ?
                    obj.orgValue40 : obj.rate40),
                    Number(obj.min),
                    obj.selectedUnit.id, true) / exch.oneUsd;
            }
            if (!value.flc40 && !value.flc20) {
                curFreightValue += getCalculations(data, Number(obj.rate),
                    Number(obj.min),
                    obj.selectedUnit.id) / exch.oneUsd;
                orgFreightValue += getCalculations(data, Number(obj.orgValue ?
                    obj.orgValue : obj.rate),
                    Number(obj.min),
                    obj.selectedUnit.id) / exch.oneUsd;
            }
        }

        for (var i = 0; i < data.localChargesItem.length; i++) {
            //var obj = removeCircularKey(productLocalChargesItem1[i]);
            var obj = data.localChargesItem[i];
            if (obj.selectedItem.name == "Select")
                continue;
            if (obj.units && obj.units.length) {
                if (!obj.selectedUnit || !obj.selectedUnit.id) {
                    obj.selectedUnit = obj.units[0]
                }
            }
            if (obj.currencies && obj.currencies.length) {
                if (!obj.selectedCurrency || !obj.selectedCurrency.id) {
                    obj.selectedCurrency = obj.currencies[0]
                }
            }
            var exch = listOfExchangeRates.find(u => u.id === obj.selectedCurrency.id)
            if (value.flc20) {
                curLocalValue20 += getCalculations(data, Number(obj.rate20),
                    Number(obj.min),
                    obj.selectedUnit.id, true) / exch.oneUsd;
                orgLocalValue20 += getCalculations(data, Number(obj.orgValue20 ?
                    obj.orgValue20 : obj.rate20),
                    Number(obj.min),
                    obj.selectedUnit.id, true) / exch.oneUsd;
            }
            if (value.flc40) {
                curLocalValue40 += getCalculations(data, Number(obj.rate40),
                    Number(obj.min),
                    obj.selectedUnit.id, true) / exch.oneUsd;
                orgLocalValue40 += getCalculations(data, Number(obj.orgValue40 ?
                    obj.orgValue40 : obj.rate40),
                    Number(obj.min),
                    obj.selectedUnit.id, true) / exch.oneUsd;
            }
            if (!value.flc40 && !value.flc20) {
                curLocalValue += getCalculations(data, Number(obj.rate),
                    Number(obj.min),
                    obj.selectedUnit.id) / exch.oneUsd;
                orgLocalValue += getCalculations(data, Number(obj.orgValue ?
                    obj.orgValue : obj.rate),
                    Number(obj.min),
                    obj.selectedUnit.id) / exch.oneUsd;
            }
        }

        for (var i = 0; i < data.customChargesItem.length; i++) {
            //var obj = removeCircularKey(productCustomChargesItem1[i]);
            var obj = data.customChargesItem[i];
            if (obj.selectedItem.name == "Select")
                continue;
            if (obj.units && obj.units.length) {
                if (!obj.selectedUnit || !obj.selectedUnit.id) {
                    obj.selectedUnit = obj.units[0]
                }
            }
            if (obj.currencies && obj.currencies.length) {
                if (!obj.selectedCurrency || !obj.selectedCurrency.id) {
                    obj.selectedCurrency = obj.currencies[0]
                }
            }
            var exch = listOfExchangeRates.find(u => u.id === obj.selectedCurrency.id)
            if (value.flc20) {
                curCustomValue20 += getCalculations(data, Number(obj.rate20),
                    Number(obj.min),
                    obj.selectedUnit.id, true) / exch.oneUsd;
                orgCustomValue20 += getCalculations(data, Number(obj.orgValue20 ?
                    obj.orgValue20 : obj.rate20),
                    Number(obj.min),
                    obj.selectedUnit.id, true) / exch.oneUsd;
            }
            if (value.flc40) {
                curCustomValue40 += getCalculations(data, Number(obj.rate40),
                    Number(obj.min),
                    obj.selectedUnit.id, true) / exch.oneUsd;
                orgCustomValue40 += getCalculations(data, Number(obj.orgValue40 ?
                    obj.orgValue40 : obj.rate40),
                    Number(obj.min),
                    obj.selectedUnit.id, true) / exch.oneUsd;
            }
            if (!value.flc40 && !value.flc20) {
                curCustomValue += getCalculations(data, Number(obj.rate),
                    Number(obj.min),
                    obj.selectedUnit.id) / exch.oneUsd;
                orgCustomValue += getCalculations(data, Number(obj.orgValue ?
                    obj.orgValue : obj.rate),
                    Number(obj.min),
                    obj.selectedUnit.id) / exch.oneUsd;
            }
        }
        var data1 = JSON.parse(JSON.stringify(data))
        var params1 = { ...value.params }
        data1.params.orgForeignValue = Number(orgForeignValue).toFixed(2);
        data1.params.orgFreightValue = Number(orgFreightValue).toFixed(2);
        data1.params.orgCustomValue = Number(orgCustomValue).toFixed(2);
        data1.params.orgLocalValue = Number(orgLocalValue).toFixed(2);
        data1.params.originalPrice = Number(orgForeignValue + orgFreightValue + orgCustomValue + orgLocalValue).toFixed(2);
        data1.params.orgForeignValue20 = Number(orgForeignValue20).toFixed(2);
        data1.params.orgFreightValue20 = Number(orgFreightValue20).toFixed(2);
        data1.params.orgCustomValue20 = Number(orgCustomValue20).toFixed(2);
        data1.params.orgLocalValue20 = Number(orgLocalValue20).toFixed(2);
        data1.params.originalPrice20 = Number(orgForeignValue20 + orgFreightValue20 + orgCustomValue20 + orgLocalValue20).toFixed(2);
        data1.params.orgForeignValue40 = Number(orgForeignValue40).toFixed(2);
        data1.params.orgFreightValue40 = Number(orgFreightValue40).toFixed(2);
        data1.params.orgCustomValue40 = Number(orgCustomValue40).toFixed(2);
        data1.params.orgLocalValue40 = Number(orgLocalValue40).toFixed(2);
        data1.params.originalPrice40 = Number(orgForeignValue40 + orgFreightValue40 + orgCustomValue40 + orgLocalValue40).toFixed(2);

        data1.params.curForeignValue = Number(curForeignValue).toFixed(2);
        data1.params.curFreightValue = Number(curFreightValue).toFixed(2);
        data1.params.curCustomValue = Number(curCustomValue).toFixed(2);
        data1.params.curLocalValue = Number(curLocalValue).toFixed(2);
        data1.params.curPrice = Number(curForeignValue + curFreightValue + curCustomValue + curLocalValue).toFixed(2);
        data1.params.curForeignValue20 = Number(curForeignValue20).toFixed(2);
        data1.params.curFreightValue20 = Number(curFreightValue20).toFixed(2);
        data1.params.curCustomValue20 = Number(curCustomValue20).toFixed(2);
        data1.params.curLocalValue20 = Number(curLocalValue20).toFixed(2);
        data1.params.curPrice20 = Number(curForeignValue20 + curFreightValue20 + curCustomValue20 + curLocalValue20).toFixed(2);
        data1.params.curForeignValue40 = Number(curForeignValue40).toFixed(2);
        data1.params.curFreightValue40 = Number(curFreightValue40).toFixed(2);
        data1.params.curCustomValue40 = Number(curCustomValue40).toFixed(2);
        data1.params.curLocalValue40 = Number(curLocalValue40).toFixed(2);
        data1.params.curPrice40 = Number(curForeignValue40 + curFreightValue40 + curCustomValue40 + curLocalValue40).toFixed(2);

        data1.params.margin = Number(data1.params.curPrice - data1.params.originalPrice).toFixed(2);
        data1.params.margin20 = Number(data1.params.curPrice20 - data1.params.originalPrice20).toFixed(2);
        data1.params.margin40 = Number(data1.params.curPrice40 - data1.params.originalPrice40).toFixed(2);

        return data1;
    };

    const Number1 = (val) => {
        return val ? Number(val) : 0;
    }

    const [reasion, setReasion] = useState(1)
    const [statusData, setStatusData] = useState({
        id: 0,
        newStatus: 0,
        statusDiscription: "",
    })
    const [filterData, setFilterData1] = useState(commonFilterData)
    const resetFilter = () => {
        var t = {
            id: 0,
            cutomerCrmId: "",
            countryId: "-1",
            from: "All",
            to: "All",
            flcType20: "",
            flctype40: "",
            total: "",
            margin: "",
            originalPrice: "",
            createdDate: 0,
            quoteType1: -1,
            quoteType: [],
            incoId1: -1,
            incoId: [],
            status1: -1,
            status: [],
            userId1: -1,
            userId: [],
            serviceType1: -1,
            serviceType: [],
            currency1: -1,
            currency: [],
        }
        setFilterData1(t)
    }
    const openQsPdf = () => {
        if (Object.keys(selectedQs).length === 0) {
            dispatch(snackbarToggle({ type: 'error', message: 'Please select Qs.' }));
            return
        }
        var data = {
            opcode: 'quoatationReport',
            sessionId: admin.data.session,
            qsId: selectedQs.id,
            fileName: qsTypeList.find(u => u.id === selectedQs.quoteType)?.lable + "_" + selectedQs.id
        }
        quoatationReport(data, URL.QS_PDF);
    }
    const checkAndOpen = () => {
        if (Object.keys(selectedQs).length === 0) {
            dispatch(snackbarToggle({ type: 'error', message: 'Please select Qs.' }));
            return
        }

        getQSById(dispatch, admin, selectedQs.id, function (resp) {
            var res = { ...resp };
            var changed = false;
            if (resp.customChargesItem && resp.customChargesItem.length > 0) {
                for (var i = 0; i < resp.customChargesItem.length; i++) {
                    if (!res.customChargesItem[i].rate && res.customChargesItem[i].selectedItem.rate) {
                        res.customChargesItem[i].rate = res.customChargesItem[i].selectedItem.rate;
                        changed = true;
                    }
                    if (!res.customChargesItem[i].rate20 && res.customChargesItem[i].selectedItem.rate20) {
                        res.customChargesItem[i].rate20 = res.customChargesItem[i].selectedItem.rate20;
                        changed = true;
                    }
                    if (!res.customChargesItem[i].rate40 && res.customChargesItem[i].selectedItem.rate40) {
                        res.customChargesItem[i].rate40 = res.customChargesItem[i].selectedItem.rate40;
                        changed = true;
                    }
                    if (!res.customChargesItem[i].min && res.customChargesItem[i].selectedItem.min) {
                        res.customChargesItem[i].min = res.customChargesItem[i].selectedItem.min;
                        changed = true;
                    }
                    if (!res.customChargesItem[i].remark && res.customChargesItem[i].selectedItem.remark) {
                        res.customChargesItem[i].remark = res.customChargesItem[i].selectedItem.remark;
                        changed = true;
                    }
                }
            }
            if (resp.foreignChargesItem && resp.foreignChargesItem.length > 0) {
                for (var i = 0; i < resp.foreignChargesItem.length; i++) {
                    if (!res.foreignChargesItem[i].rate && res.foreignChargesItem[i].selectedItem.rate) {
                        res.foreignChargesItem[i].rate = res.foreignChargesItem[i].selectedItem.rate;
                        changed = true;
                    }
                    if (!res.foreignChargesItem[i].rate20 && res.foreignChargesItem[i].selectedItem.rate20) {
                        res.foreignChargesItem[i].rate20 = res.foreignChargesItem[i].selectedItem.rate20;
                        changed = true;
                    }
                    if (!res.foreignChargesItem[i].rate40 && res.foreignChargesItem[i].selectedItem.rate40) {
                        res.foreignChargesItem[i].rate40 = res.foreignChargesItem[i].selectedItem.rate40;
                        changed = true;
                    }
                    if (!res.foreignChargesItem[i].min && res.foreignChargesItem[i].selectedItem.min) {
                        res.foreignChargesItem[i].min = res.foreignChargesItem[i].selectedItem.min;
                        changed = true;
                    }
                    if (!res.foreignChargesItem[i].remark && res.foreignChargesItem[i].selectedItem.remark) {
                        res.foreignChargesItem[i].remark = res.foreignChargesItem[i].selectedItem.remark;
                        changed = true;
                    }
                }
            }
            if (resp.freightChargesItem && resp.freightChargesItem.length > 0) {
                for (var i = 0; i < resp.freightChargesItem.length; i++) {
                    if (!res.freightChargesItem[i].rate && res.freightChargesItem[i].selectedItem.rate) {
                        res.freightChargesItem[i].rate = res.freightChargesItem[i].selectedItem.rate;
                        changed = true;
                    }
                    if (!res.freightChargesItem[i].rate20 && res.freightChargesItem[i].selectedItem.rate20) {
                        res.freightChargesItem[i].rate20 = res.freightChargesItem[i].selectedItem.rate20;
                        changed = true;
                    }
                    if (!res.freightChargesItem[i].rate40 && res.freightChargesItem[i].selectedItem.rate40) {
                        res.freightChargesItem[i].rate40 = res.freightChargesItem[i].selectedItem.rate40;
                        changed = true;
                    }
                    if (!res.freightChargesItem[i].min && res.freightChargesItem[i].selectedItem.min) {
                        res.freightChargesItem[i].min = res.freightChargesItem[i].selectedItem.min;
                        changed = true;
                    }
                    if (!res.freightChargesItem[i].remark && res.freightChargesItem[i].selectedItem.remark) {
                        res.freightChargesItem[i].remark = res.freightChargesItem[i].selectedItem.remark;
                        changed = true;
                    }
                }
            }
            if (resp.localChargesItem && resp.localChargesItem.length > 0) {
                for (var i = 0; i < resp.localChargesItem.length; i++) {
                    if (!res.localChargesItem[i].rate && res.localChargesItem[i].selectedItem.rate) {
                        res.localChargesItem[i].rate = res.localChargesItem[i].selectedItem.rate;
                        changed = true;
                    }
                    if (!res.localChargesItem[i].rate20 && res.localChargesItem[i].selectedItem.rate20) {
                        res.localChargesItem[i].rate20 = res.localChargesItem[i].selectedItem.rate20;
                        changed = true;
                    }
                    if (!res.localChargesItem[i].rate40 && res.localChargesItem[i].selectedItem.rate40) {
                        res.localChargesItem[i].rate40 = res.localChargesItem[i].selectedItem.rate40;
                        changed = true;
                    }
                    if (!res.localChargesItem[i].min && res.localChargesItem[i].selectedItem.min) {
                        res.localChargesItem[i].min = res.localChargesItem[i].selectedItem.min;
                        changed = true;
                    }
                    if (!res.localChargesItem[i].remark && res.localChargesItem[i].selectedItem.remark) {
                        res.localChargesItem[i].remark = res.localChargesItem[i].selectedItem.remark;
                        changed = true;
                    }
                }
            }
            if (changed) {
                var r = recalclulateOriginalValues(res);
                updateQS1(dispatch, snackbarToggle, r, admin, function () {
                    setSelectedQs(r);
                    setBadd(!bAdd);
                });
            }
            else {
                setSelectedQs(resp);
                setBadd(!bAdd);
            }
        });

       // setBadd(!bAdd);
        //onHandleSetBadd()
    };
    const checkAndDelete = () => {
        if (Object.keys(selectedQs).length === 0) {
            dispatch(snackbarToggle({ type: 'error', message: 'Please select Qs.' }));
            return
        }
        onHandleOpenModalDelete()
    };
    const checkAndCopy = () => {
        if (Object.keys(selectedQs).length === 0) {
            dispatch(snackbarToggle({ type: 'error', message: 'Please select Qs.' }));
            return
        }
        onHandleOpenModalCopy()
    };
    const onHandleSetBadd = () => {
        setBadd(!bAdd);
        navigate("/menu/qs")

    };
    const onPageHandleChange = (_, newPage) => {
        dispatch(onPageChange(newPage))
    };
    const onRowsPerPageHandleChange = event => {
        dispatch(onSizeChange(+event.target.value))
        dispatch(onPageChange(0))
    };
    const handleChange = (event) => {
        setValue(event.target.value);
        tSelected = qsList1.find(u => u.id === Number(event.target.value))
        setSelectedQs(tSelected)
        //setSelectedQs(JSON.parse(JSON.stringify(tSelected)))
        console.log(selectedQs)
        onHandleOpenModalfourAction()
    };
   
    var tempList = []
    const failedCallback = useCallback(
        message => dispatch(snackbarToggle({ type: 'error', message })),
        [dispatch],
    );
    const tCall = function (bValue, val) {


    }
    const setChecked = (bValue, val) => {
        val.checked = bValue
       // dispatch(onQsChange(val));
    }
    const getQsList1 = () => {
        setQsList([])
        var quoteType = [];
        filterData.quoteType.forEach(element => {
            if (element.bSelected) {
                quoteType.push(element.id);
            }
        });
        var status = [];
        filterData.status.forEach(element => {
            if (element.bSelected) {
                status.push(element.id);
            }
        });
        var incoId = [];
        filterData.incoId.forEach(element => {
            if (element.bSelected) {
                incoId.push(element.id);
            }
        });
        var currency = [];
        filterData.currency.forEach(element => {
            if (element.bSelected) {
                currency.push(element.id);
            }
        });
        var userId = [];
        filterData.userId.forEach(element => {
            if (element.bSelected) {
                userId.push(element.id);
            }
        });
        var serviceType = [];
        filterData.serviceType.forEach(element => {
            if (element.bSelected) {
                serviceType.push(element.id);
            }
        });
        if (filterData.quoteType == -1 || filterData.quoteType == 0) {
            quoteType = []
        }
        var cutomerCrmId = filterData.cutomerCrmId
        if (cutomerCrmId == 0 || cutomerCrmId == -1) {
            cutomerCrmId = ""
        }
        var countryId = filterData.countryId
        if (countryId == 0 || countryId == -1) {
            countryId = ""
        }
        var from = filterData.from
        if (from == 'All') {
            from = ""
        }
        var to = filterData.to
        if (to == 'All') {
            to = ""
        }
        var flcType20 = filterData.flcType20
        if (flcType20 == -1) {
            flcType20 = 0
        }
        var flctype40 = filterData.flctype40
        if (flctype40 == -1) {
            flctype40 = 0
        }
        var data = {
            numItems: paginationData.size,
            pageNum: paginationData.page + 1,
            id: filterData.id,
            cutomerCrmId: cutomerCrmId,
            countryId: countryId,
            from: from,
            to: to,
            flcType20: flcType20,
            flctype40: flctype40,
            total: filterData.total,
            createdDate: filterData.createdDate,
            margin: filterData.margin,
            originalPrice: filterData.originalPrice,
            quoteType: quoteType,
            incoId: incoId,
            status: status,
            userId: userId,
            serviceType: serviceType,
            currency: currency,
        }
        getQSPaginationFilter(dispatch, admin, data, setQsList);
    }
    const save = () => {
        //navigate("/menu/dashboard")
        setQsList([])

        var desc = statusData.statusDiscription;
        if (statusData.newStatus == 3) {
            desc = reasion //listOfRejectReasionsOpt.find(u => u.id === reasion)?.name + "@" + reasion
        }
        
       // setStatusData(prev => ({ ...prev, statusDiscription: des }))
        var data1 = {
            id: statusData.id,
            status: statusData.newStatus,
            statusDesc: desc
        }
        updateQuoteStatus(dispatch, snackbarToggle, data1, admin, function (d1, d2) { })
        updateQuoteStatus(dispatch, snackbarToggle, data1, admin, getQsList1)
        
        window.setTimeout(function () {
            getQsList1(dispatch, admin);
            onHandleCancelModal()
        }, 500)/* */
    }
    const reset = () => {
        setValue(0);
    }


    useEffect(() => {
        if (!isLoaded) {
            if (admin.data.session) {
                getCRMList(dispatch, admin);
                //getQsList1(dispatch, admin);
                setIsLoaded(true);
            }
        }
        dispatch(setCommonFilterData(filterData));
        getQsList1(dispatch, admin);
        getKgFactorsforSave(dispatch, admin, function (data) {
            setListOfKgFactor1(data.data);
        });
    }, [qsList,paginationData.page, paginationData.size, dispatch, failedCallback, filterData]);
    const [selectedData, setSelectedData] = useState({});
    const qsStatusListOpt = qsStatusList.map(sender => ({
        id: sender.id,
        name: sender.lable
    }));
    const listOfRejectReasionsOpt = listOfRejectReasions.map(sender => ({
        id: sender.id,
        name: sender.reasion
    }));
    var FLCTypes20pt1 = listOfFLCTypes20.map(pr => ({
        id: pr.id,
        name: pr.type
    }));
    const FLCTypes20pt = [{
        id: -1,
        name: 'All'
    }].concat(FLCTypes20pt1)
    const FLCTypes40pt1 = listOfFLCTypes40.map(pr => ({
        id: pr.id,
        name: pr.type
    }));
    const FLCTypes40pt = [{
        id: -1,
        name: 'All'
    }].concat(FLCTypes40pt1)
    function formatDate(newDate) {
        const d = newDate
        const year = d.getFullYear()
        const date = d.getDate()
        const monthName = d.getMonth() + 1
        const formatted = `${date}/${monthName}/${year}`
        return formatted.toString()
    }

    function getMargin(value) {
        if (value.shipmentType != 3) {
            return value.params?.margin
        } else {

            return <> <span>T20: {value.params?.margin20}</span><br /><span>T40: {value.params?.margin40}</span></>
        }

        return 0
    }
    const exportQsFilter = function () {
        var items = [];
        var headers = [];
        for (var i = 0; i < qsList1.length; i++) {
            var qs = qsList1[i];
            var t = {}
            t.Quote_Type = qsTypeList.find(u => u.id === qs.quoteType)?.lable;
            t.Customer = crmList.find(u => u.id === qs.cutomerCrmId)?.companyName;
            t.Total = 0;
            t.Total20 = 0;
            t.Total40 = 0;
            t.Margin = 0;
            t.Margin20 = 0;
            t.Margin40 = 0;
            if (qs.shipmentType == 3) {
                t.Total20 = qs.total20;
                t.Total40 = qs.total40;
                t.Margin20 = qs.params?.margin20 ? qs.params.margin20 : 0;
                t.Margin40 = qs.params?.margin40 ? qs.params.margin40 : 0;
            } else {
                t.Total = qs.total;
                t.Margin = qs.params?.margin ? qs.params.margin : 0;
            }
            t.Country = countryList.find(u => u.id === qs.countryId)?.name
            if (qs.shipmentType == 1) {
                t.From = listOfAirPort.find(u => u.id === qs.from)?.name
                t.To = listOfAirPort.find(u => u.id === qs.to)?.name
            } else {
                t.From = listOfPort.find(u => u.id === qs.from)?.name
                t.To = listOfPort.find(u => u.id === qs.to)?.name
            }
            t.Status = qsStatusList.find(u => u.id === qs.status)?.lable;
            t.Incoterm = listOfIncoterms.find(u => u.id === qs.incoId)?.incoterm
            t.Id = qs.id;
            t.User = adminList.find(u => u.id === qs.userId)?.adminName;
            t.Currency = "USD";
            t.ServiceType = listOfServiceType.find(u => u.id === qs.serviceType)?.serviceType;
            t.Date = formatDate(new Date(qs.createdDate), 'dd/mm/yyyy');
            headers = Object.keys(t)
            items.push(t);
        }
        exportCSVFile(headers, items, "QS_" + formatDate(new Date(qs.createdDate), 'dd/mm/yyyy'));
    }
    return (

        <Paper sx={{ width: '100%', height: "calc(100vh - 52px)", overflow: 'auto' }}>
            {bAdd && (
                <AddQS data={selectedQs} bOpen={false} view={viewOrEdit} onHandleCancel={() => onHandleSetBadd(false)} id={null} />
            )}


            <Dialog open={openOrderModalfourAction} title={"Actions"} onHandleCancel={onHandleCancelModalfourAction}>
                <Grid container item justifyContent='center' lg={12} spacing={2} style={{ width: "30vw", marginBottom: "20px", marginTop: "20px" }}>
                    <Grid container item spacing={2} sx={{ mt: -4 }}>

                        {<Button variant='contained' sx={{ mt: -2, ml: 10 }} onClick={() => {
                            openQsPdf()
                            onHandleCancelModalfourAction()
                        }}>
                            Open Pdf
                        </Button>
                        }
                        {<Button variant='contained' sx={{ mt: -2, ml: 2 }} onClick={() => {
                            checkAndOpen();
                            onHandleCancelModalfourAction()
                        }}>
                            {!bAdd ? 'Edit' : 'Back'}
                        </Button>
                        }
                        {<Button variant='contained' sx={{ mt: -2, ml: 2 }} onClick={() => {
                            checkAndDelete();
                            onHandleCancelModalfourAction()
                        }}>
                            Delete
                        </Button>
                        }
                        {<Button variant='contained' sx={{ mt: -2, ml: 2 }} onClick={() => {
                            checkAndCopy();
                            onHandleCancelModalfourAction()
                        }}>
                            Copy
                        </Button>
                        }
                    </Grid>
                </Grid>
                {<Grid container item justifyContent='center' lg={12}>

                    <Button variant='contained' sx={{ mt: 2 }} color='secondary' onClick={onHandleCancelModalfourAction}>
                        Cancel
                    </Button>
                </Grid>}
            </Dialog>



            <Dialog open={openOrderModalDelete} title={"Delete"} onHandleCancel={onHandleCancelModalDelete}>
                <Grid container item justifyContent='center' lg={12} spacing={2} style={{width:"30vw",marginBottom:"20px",marginTop:"20px"}}>
                    Are you sure you want to delete.
                </Grid>
                {<Grid container item justifyContent='center' lg={12}>
                    <Button variant='contained' sx={{ mt: 2, mr: 2 }} onClick={() => {
                        deleteQS(dispatch, snackbarToggle, { id: selectedQs.id }, admin, function () {
                            getQsList1(dispatch, admin);
                            onHandleCancelModalDelete()
                        })
                    }}>
                        Ok
                    </Button>
                    <Button variant='contained' sx={{ mt: 2 }} color='secondary' onClick={onHandleCancelModalDelete}>
                        Cancel
                    </Button>
                </Grid>}
            </Dialog>


            <Dialog open={openOrderModalCopy} title={"Copy"} onHandleCancel={onHandleCancelModalCopy}>
                <Grid container item justifyContent='center' lg={12} spacing={2} style={{ width: "30vw", marginBottom: "20px", marginTop: "20px" }}>
                    Are you sure you want to create a copy.
                </Grid>
                {<Grid container item justifyContent='center' lg={12}>
                    <Button variant='contained' sx={{ mt: 2, mr: 2 }} onClick={() => {
                        copyQs(dispatch, snackbarToggle, { id: selectedQs.id }, admin, function (data) {
                            getQsList1(dispatch, admin);
                            onHandleCancelModalCopy()
                            if (data?.data?.id) {
                                getQSById(dispatch, admin, data?.data?.id, function (r) {
                                    setSelectedQs(r);
                                    setBadd(!bAdd);
                                });
                            }
                            //ppp
                        })
                    }}>
                        Ok
                    </Button>
                    <Button variant='contained' sx={{ mt: 2 }} color='secondary' onClick={onHandleCancelModalCopy}>
                        Cancel
                    </Button>
                </Grid>}
            </Dialog>


            <Dialog style={{ minWidth: 500 }} open={openOrderModal} title={"Status"} onHandleCancel={onHandleCancelModal}>
                <Grid container item justifyContent='flex-start' lg={12} spacing={2}>
                    <SelectField
                        required
                        value={statusData.newStatus}
                        lg={12}
                        onChange={event => setStatusData(prev => ({ ...prev, newStatus: event.target.value }))}
                        label='Status'
                        options={qsStatusListOpt}
                    />
                   
                    {statusData.newStatus !=3&&<TextField
                        lg={12}
                        value={statusData.statusDiscription}
                        onChange={event => setStatusData(prev => ({ ...prev, statusDiscription: event.target.value }))}
                        label='Discription'
                    />
                    }
                    {statusData.newStatus == 3 && <SelectField
                        required
                        value={reasion}
                        lg={12}
                        onChange={event => {
                            setReasion(event.target.value)
                            
                        }}
                        label='Reasion'
                        options={listOfRejectReasionsOpt}
                    />
                    }
                </Grid>
                {<Grid container item justifyContent='flex-end' lg={22}>
                    <Button variant='contained' sx={{ mt: 2, mr: 2 }} onClick={save}>
                        Save
                    </Button>
                    <Button variant='contained' sx={{ mt: 2 }} color='secondary' onClick={onHandleCancelModal}>
                        Cancel
                    </Button>
                </Grid>}
            </Dialog>
            {!bAdd && <Grid container item justifyContent='space-between' lg={12} spacing={2} sx={{marginTop:0.5}}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-start', spacing: 2, marginLeft: 5, marginTop: 2 }}>
                    <Button onClick={resetFilter} variant='outlined' className="qs_filter_btn">
                        Clear Filter
                    </Button>
                    <Button onClick={exportQsFilter} variant='outlined' sx={{ marginLeft: 2 }} className="qs_filter_btn">
                        Export Csv
                    </Button>
                </Box>
                <Grid container item justifyContent='end' lg={5} spacing={2} sx={{ mr: 3 }}>
                    
                    <SelectField
                        value={filterData.flcType20}
                        lg={5}
                        onChange={event => setFilterData1(prev => ({ ...prev, flcType20: event.target.value }))}
                        label='Flc Type 20'
                        options={FLCTypes20pt}
                    />
                    <SelectField
                        value={filterData.flctype40}
                        lg={5}
                        onChange={event => setFilterData1(prev => ({ ...prev, flctype40: event.target.value }))}
                        label='Flc Type 40'
                        options={FLCTypes40pt}
                    />
                </Grid>
            </Grid>}
            {!bAdd &&
                
                <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={value}
                    onChange={handleChange}
                >
                    <TableContainer sx={{ height: "calc(100vh - 169px)",marginTop:1 }}>
                        <Table>
                            <QSHeader reset={getQsList1} setFilterData={setFilterData1} filterData={filterData} />
                            <TableBody>


                                {
                                    qsList1.map(qs => (
                                        <QSRow openQsPdf={openQsPdf} checkAndCopy={checkAndCopy} checkAndDelete={checkAndDelete} checkAndOpen={checkAndOpen} openOnLable={() => {

                                            getQSById(dispatch, admin, qs.id, function (resp) {
                                                var res = { ...resp };
                                                var changed = false;
                                                if (resp.customChargesItem && resp.customChargesItem.length > 0) {
                                                    for (var i = 0; i < resp.customChargesItem.length; i++) {
                                                        /*if (res.customChargesItem[i].selectedItem.name.trim() != "Trucking" &&
                                                            res.customChargesItem[i].selectedItem.name.trim() != "IATA Fee") {
                                                            if (res.customChargesItem[i].selectedItem.remark) {
                                                                res.customChargesItem[i].selectedItem.remark = "";
                                                                changed = true;
                                                            }
                                                            if (res.customChargesItem[i].remark) {
                                                                res.customChargesItem[i].remark = "";
                                                                changed = true;
                                                            }
                                                        }*/
                                                        if (!res.customChargesItem[i].rate && res.customChargesItem[i].selectedItem.rate) {
                                                            res.customChargesItem[i].rate = res.customChargesItem[i].selectedItem.rate;
                                                            changed = true;
                                                        }
                                                        if (!res.customChargesItem[i].rate20 && res.customChargesItem[i].selectedItem.rate20) {
                                                            res.customChargesItem[i].rate20 = res.customChargesItem[i].selectedItem.rate20;
                                                            changed = true;
                                                        }
                                                        if (!res.customChargesItem[i].rate40 && res.customChargesItem[i].selectedItem.rate40) {
                                                            res.customChargesItem[i].rate40 = res.customChargesItem[i].selectedItem.rate40;
                                                            changed = true;
                                                        }
                                                        if (!res.customChargesItem[i].min && res.customChargesItem[i].selectedItem.min) {
                                                            res.customChargesItem[i].min = res.customChargesItem[i].selectedItem.min;
                                                            changed = true;
                                                        }
                                                        if (!res.customChargesItem[i].remark && res.customChargesItem[i].selectedItem.remark) {
                                                            res.customChargesItem[i].remark = res.customChargesItem[i].selectedItem.remark;
                                                            changed = true;
                                                        }
                                                    }
                                                }
                                                if (resp.foreignChargesItem && resp.foreignChargesItem.length > 0) {
                                                    for (var i = 0; i < resp.foreignChargesItem.length; i++) {
                                                        /*if (res.foreignChargesItem[i].selectedItem.name.trim() != "Trucking" &&
                                                            res.foreignChargesItem[i].selectedItem.name.trim() != "IATA Fee") {
                                                            if (res.foreignChargesItem[i].selectedItem.remark) {
                                                                res.foreignChargesItem[i].selectedItem.remark = "";
                                                                changed = true;
                                                            }
                                                            if (res.foreignChargesItem[i].remark) {
                                                                res.foreignChargesItem[i].remark = "";
                                                                changed = true;
                                                            }
                                                        }*/
                                                        if (!res.foreignChargesItem[i].rate && res.foreignChargesItem[i].selectedItem.rate) {
                                                            res.foreignChargesItem[i].rate = res.foreignChargesItem[i].selectedItem.rate;
                                                            changed = true;
                                                        }
                                                        if (!res.foreignChargesItem[i].rate20 && res.foreignChargesItem[i].selectedItem.rate20) {
                                                            res.foreignChargesItem[i].rate20 = res.foreignChargesItem[i].selectedItem.rate20;
                                                            changed = true;
                                                        }
                                                        if (!res.foreignChargesItem[i].rate40 && res.foreignChargesItem[i].selectedItem.rate40) {
                                                            res.foreignChargesItem[i].rate40 = res.foreignChargesItem[i].selectedItem.rate40;
                                                            changed = true;
                                                        }
                                                        if (!res.foreignChargesItem[i].min && res.foreignChargesItem[i].selectedItem.min) {
                                                            res.foreignChargesItem[i].min = res.foreignChargesItem[i].selectedItem.min;
                                                            changed = true;
                                                        }
                                                        if (!res.foreignChargesItem[i].remark && res.foreignChargesItem[i].selectedItem.remark) {
                                                            res.foreignChargesItem[i].remark = res.foreignChargesItem[i].selectedItem.remark;
                                                            changed = true;
                                                        }
                                                    }
                                                }
                                                if (resp.freightChargesItem && resp.freightChargesItem.length > 0) {
                                                    for (var i = 0; i < resp.freightChargesItem.length; i++) {
                                                        /*if (res.freightChargesItem[i].selectedItem.name.trim() != "Trucking" &&
                                                            res.freightChargesItem[i].selectedItem.name.trim() != "IATA Fee") {
                                                            if (res.freightChargesItem[i].selectedItem.remark) {
                                                                res.freightChargesItem[i].selectedItem.remark = "";
                                                                changed = true;
                                                            }
                                                            if (res.freightChargesItem[i].remark) {
                                                                res.freightChargesItem[i].remark = "";
                                                                changed = true;
                                                            }
                                                        }*/
                                                        if (!res.freightChargesItem[i].rate && res.freightChargesItem[i].selectedItem.rate) {
                                                            res.freightChargesItem[i].rate = res.freightChargesItem[i].selectedItem.rate;
                                                            changed = true;
                                                        }
                                                        if (!res.freightChargesItem[i].rate20 && res.freightChargesItem[i].selectedItem.rate20) {
                                                            res.freightChargesItem[i].rate20 = res.freightChargesItem[i].selectedItem.rate20;
                                                            changed = true;
                                                        }
                                                        if (!res.freightChargesItem[i].rate40 && res.freightChargesItem[i].selectedItem.rate40) {
                                                            res.freightChargesItem[i].rate40 = res.freightChargesItem[i].selectedItem.rate40;
                                                            changed = true;
                                                        }
                                                        if (!res.freightChargesItem[i].min && res.freightChargesItem[i].selectedItem.min) {
                                                            res.freightChargesItem[i].min = res.freightChargesItem[i].selectedItem.min;
                                                            changed = true;
                                                        }
                                                        if (!res.freightChargesItem[i].remark && res.freightChargesItem[i].selectedItem.remark) {
                                                            res.freightChargesItem[i].remark = res.freightChargesItem[i].selectedItem.remark;
                                                            changed = true;
                                                        }
                                                    }
                                                }
                                                if (resp.localChargesItem && resp.localChargesItem.length > 0) {
                                                    for (var i = 0; i < resp.localChargesItem.length; i++) {
                                                        /*if (res.localChargesItem[i].selectedItem.name.trim() != "Trucking" &&
                                                            res.localChargesItem[i].selectedItem.name.trim() != "IATA Fee") {
                                                            if (res.localChargesItem[i].selectedItem.remark) {
                                                                res.localChargesItem[i].selectedItem.remark = "";
                                                                changed = true;
                                                            }
                                                            if (res.localChargesItem[i].remark) {
                                                                res.localChargesItem[i].remark = "";
                                                                changed = true;
                                                            }
                                                        }*/
                                                        if (!res.localChargesItem[i].rate && res.localChargesItem[i].selectedItem.rate) {
                                                            res.localChargesItem[i].rate = res.localChargesItem[i].selectedItem.rate;
                                                            changed = true;
                                                        }
                                                        if (!res.localChargesItem[i].rate20 && res.localChargesItem[i].selectedItem.rate20) {
                                                            res.localChargesItem[i].rate20 = res.localChargesItem[i].selectedItem.rate20;
                                                            changed = true;
                                                        }
                                                        if (!res.localChargesItem[i].rate40 && res.localChargesItem[i].selectedItem.rate40) {
                                                            res.localChargesItem[i].rate40 = res.localChargesItem[i].selectedItem.rate40;
                                                            changed = true;
                                                        }
                                                        if (!res.localChargesItem[i].min && res.localChargesItem[i].selectedItem.min) {
                                                            res.localChargesItem[i].min = res.localChargesItem[i].selectedItem.min;
                                                            changed = true;
                                                        }
                                                        if (!res.localChargesItem[i].remark && res.localChargesItem[i].selectedItem.remark) {
                                                            res.localChargesItem[i].remark = res.localChargesItem[i].selectedItem.remark;
                                                            changed = true;
                                                        }
                                                    }
                                                }
                                                if (changed) {
                                                    var r = recalclulateOriginalValues(res);
                                                    updateQS1(dispatch, snackbarToggle, r, admin, function () {
                                                        setSelectedQs(r);
                                                        setBadd(!bAdd);
                                                    });
                                                } else {
                                                    setSelectedQs(resp);
                                                    setBadd(!bAdd);
                                                }
                                            });
                                            
                                        }} openDialog={function () {
                                            //setSelectedData(qs);
                                            setStatusData(prev => ({ ...prev, id: qs.id }));
                                            setStatusData(prev => ({ ...prev, newStatus: qs.status }));
                                            setStatusData(prev => ({ ...prev, statusDiscription: qs.statusDesc }));
                                            if (qs.status == 3) {
                                                setReasion(qs.statusDesc)
                                            }
                                            onHandleOpenModal()
                                        }} key={qs?.id || uuidv4()} data={qs} qsList={qsList1} setChecked={setChecked} />
                                    ))
                                }

                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={paginationArr}
                        component='div'
                        count={paginationData.parcelCount}
                        rowsPerPage={paginationData.size}
                        page={paginationData.page}
                        onPageChange={onPageHandleChange}
                        onRowsPerPageChange={onRowsPerPageHandleChange}
                    />
                    <Grid container item spacing={2} sx={{ mt: -4 }}>

                        {<Button variant='contained' sx={{ mt: -2, ml: 10 }} onClick={openQsPdf}>
                            Open Pdf
                        </Button>
                        }
                        {<Button variant='contained' sx={{ mt: -2, ml: 2 }} onClick={checkAndOpen}>
                            {!bAdd ? 'Edit' : 'Back'}
                        </Button>
                        }
                        {<Button variant='contained' sx={{ mt: -2, ml: 2 }} onClick={checkAndDelete}>
                           Delete
                        </Button>
                        }
                        {<Button variant='contained' sx={{ mt: -2, ml: 2 }} onClick={checkAndCopy}>
                           Copy
                        </Button>
                        }
                </Grid>
                </RadioGroup>
            }
            
            {snackbar && (
                <Snackbar
                    open={!!snackbar}
                    message={snackbar.message}
                    type={snackbar.type}
                    onClose={onSnackbarHandleClose}
                />
            )}
        </Paper>


    );
};
export default QSTable;
